import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {config, API_URL} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate } from '../component/globalFunction'
import Select from 'react-select'

import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'

const ResultAttendance=()=>{


    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
       
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true); 
    const [errors, setErrors] = useState({});
    const [exams, setExams]=useState([])
    const [attendance, setAttendance]=useState({
        exam:[],
        startDate:'',
        endDate:'',
        nextTermBegin:'',
        nextTermBeginBorder:'',
        nextTermBeginDay:''
    })

    const [allAttendance, setAllAttendance]=useState([])


    const fetchExams =()=>{
       
        let url = API_URL+'/exam/result/examtype';
        axios.get(url,  config).then(result=>{
            setExams(result.data.examType)
        
        
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }
  
    const fetchAttendance =()=>{
        let url = API_URL+'/exam/attendance/index';
        axios.get(url,  config).then(result=>{
            setAllAttendance(result.data.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }

    

 const handleChange = event =>{
        const {name, value} = event.target
        setAttendance({...attendance, [name] : value });
       setErrors({...errors, [name]:''})
    }

    const handleSelect = (option, action)=>{
        setAttendance({...attendance, [action.name]: option });
       setErrors({...errors, [action.name]:''})
    }



    const handleDelete =(code)=>{
		let close =   document.getElementById('btnWarningDialog-'+code)
		close.click();
		setNotice({...notice,  isLoading: true}) 

	let url = API_URL+'/exam/attendance/destroy/'+code;
		  axios.get(url, config)
		  .then(response =>{
		   if(response.data.status ===200){
			  Alerts('Saved!', 'default', response.data.message)
				  } else{
			  Alerts('Error!', 'danger', JSON.stringify(response.data))
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
            fetchAttendance()
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
}





const handleSubmit = event =>{
    event.preventDefault();   
var errorMessage = [];

    if (allAttendance.filter(item=>item.ecode ===attendance.exam.value).length!==0){
        var exam ='Session and term attendance already created';
        errorMessage.push(exam)
        setErrors({...errors, exam:exam})
    }
    if(attendance.exam.length===0){
        var exam ='Please select exam type';
        errorMessage.push(exam)
        setErrors({...errors, exam:exam})
    } 

    if(errorMessage.length === 0 ){
       submit()
    }
    
}



function submit(){
        setNotice({...notice, isLoading: true})    
        
            const fd = new FormData(); 
           
           fd.append('exam', attendance.exam.value);
           fd.append('startDate', attendance.startDate);
           fd.append('endDate', attendance.endDate);
           fd.append('nextTermBeginBorder', attendance.nextTermBeginBorder);
           fd.append('nextTermBeginDay', attendance.nextTermBeginDay);
           fd.append('code', Math.random().toString(36).substring(2,9));
           let url = API_URL+'/exam/attendance/create';
                  axios.post(url, fd, config)
            .then(response =>{
                if (response.data.status ===200){
                    Alerts('Saved!', 'success', response.data.message)
                } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
            }
                    
            })
            .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
.finally(()=>{
    setNotice({...notice,    isLoading: false})
        fetchAttendance()
        setAttendance({
            exam:[],
            startDate:'',
                endDate:'',
                nextTermBeginBorder:'',
                nextTermBeginDay:''
        }) 
    })
        }






   
 
    const tableHeader = [
        
        {dataField: 'exam', text: 'Exam', editable: false}, 

        {dataField: 'startDate', text: 'Start Date', editor: {
            type: Type.DATE}, formatter: (cell)=>longDate(cell)},

        {dataField: 'endDate', text: 'End Date', editor: {
            type: Type.DATE}, formatter: (cell)=>longDate(cell)},

            {dataField: 'nextTermBegin', text: 'Next Term Begin (Boarders)', editor: {
                type: Type.DATE}, formatter: (cell)=>longDate(cell)},

                {dataField: 'nextTermBeginDay', text: 'Next Term Begin (Day)', editor: {
                    type: Type.DATE}, formatter: (cell)=>longDate(cell)},

       /*  {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div> 
            
        <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="fa fa-trash"></i></button>
         
        <WarningModal message='Once deleted, you will no be able to recover this record. Are you realy sure?' handleAction={()=>handleDelete(row.ID)} mID={row.ID} />  </div>, editable: false} */
        
     ];

    
const TableRecord=(props)=>{

    const handleUpdate=(column, newValue, code)=>{       
        const fd = new FormData(); 
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('code', code);
        let url = API_URL+'/exam/attendance/change_field'
        axios.post(url, fd, config)
            //.then(result => console.log(result.data))
            .then()
            .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        } 


            const { SearchBar } = Search;
            
            const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
            );
            const options = {
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
            { text: 'All', value: props.data.length }]
            
            };
            return  <ToolkitProvider search columnToggle 
                        keyField='ID' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                
                                <div className="form-group">
                                    <SearchBar
                                    { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                     
                                     
          
                        <BootstrapTable
                                    { ...props.baseProps }
                                                striped
                                            hover
                                            condensed
                                            noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                        pagination={paginationFactory(options) }
                                        cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                                beforeSaveCell: (oldValue, newValue, row, column) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.code);
                                
                                return true;
                                }
                                
                            }
                                        }) }
                                    />
                                </div>
                                )
                            }
        </ToolkitProvider>
        }


        useEffect(()=>{              
           fetchAttendance()
           fetchExams()
        },[]);

        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Result Attendance Setup">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Result</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  ">
                            <div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Assign Attendance Date</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">

            <form method='post' onSubmit={handleSubmit}> 
		<fieldset>
				<div className="row">
				<section className="col-md-6">
                <div className="form-group">
         <label> Exam Type<span style={{color:'red'}}>*</span></label>
         <Select  options={exams.map((list, id)=>{
             return {key:id, value:list.examCode, label:list.exam}
         })}               
 onChange={handleSelect} className={errors.exam ? 'form-control form-control-danger' : ''} name="exam" value={attendance.exam} id='exam'  />

 <span style={{color:'red'}}>{errors.exam && errors.exam}</span>
            </div>
            </section>


            <div className="col-md-6">
           <div className="form-group">
         <label> Start Date</label><span style={{color:'red'}}>*</span>
                     <input type="date" required value={attendance.startDate}  placeholder='Start Date' name='startDate' onChange={handleChange}  className={errors.startDate ? 'form-control form-control-danger' : 'form-control'} />
                     <span style={{color:'red'}}>{errors.startDate && errors.startDate}</span>
            </div>
     
     </div>

     <div className="col-md-4">
           <div className="form-group">
         <label> End Date</label><span style={{color:'red'}}>*</span>
                     <input type="date" required value={attendance.endDate}  placeholder='End Date' name='endDate' onChange={handleChange}  className={errors.endDate ? 'form-control form-control-danger' : 'form-control'} />
                     <span style={{color:'red'}}>{errors.endDate && errors.endDate}</span>
            </div>
     
     </div>

     <div className="col-md-4">
           <div className="form-group">
         <label> Next Term Begin (Boarders)</label><span style={{color:'red'}}>*</span>
                     <input type="date" required value={attendance.nextTermBeginBorder}  placeholder='Next Term Begin Date' name='nextTermBeginBorder' onChange={handleChange}  className={errors.nextTermBeginBorder ? 'form-control form-control-danger' : 'form-control'} />
                     <span style={{color:'red'}}>{errors.nextTermBeginBorder && errors.nextTermBeginBorder}</span>
            </div>
            </div>
            <div className="col-md-4">
            <div className="form-group">
         <label> Next Term Begin (Day Students)</label><span style={{color:'red'}}>*</span>
                     <input type="date" required value={attendance.nextTermBeginDay}  placeholder='Next Term Begin Date' name='nextTermBeginDay' onChange={handleChange}  className={errors.nextTermBeginDay ? 'form-control form-control-danger' : 'form-control'} />
                     <span style={{color:'red'}}>{errors.nextTermBeginDay && errors.nextTermBeginDay}</span>
            </div></div>
     
     
            </div>
        </fieldset>	
                                
	<footer className="pull-right">
	
	<button type="submit" className="btn btn-inverse " >Save Attendance Date</button>
	
								</footer>		

                                </form>
							</div>
							</div>
							</div>



                            </div>



                            <div className="row">
         <div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>All Result Attendace</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">

        <div className="card-block">


<div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={allAttendance}  columns={tableHeader} />}
	
	</div></div>

</div></div>

</div>


                </div>
          {/* The Page Body close here */}
          
            </div>
            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ResultAttendance) 