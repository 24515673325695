import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {API_URL, config, ServerUrl, Token, allowDelete, allowEdit,} from '../component/include'
import Select from 'react-select'

import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Error403 from '../settings/error403'
import { useDispatch, useSelector } from 'react-redux'
import { formatGroupLabel, getClassName, longDate} from '../component/globalFunction'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { sort } from '../component/globalFunction'
import {Type} from 'react-bootstrap-table2-editor';

 const ExamStandards =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);

    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    
const [data, setData] =  useState([]);

const [standardList, setStandardList]=useState([]);
const initials = {
    category:'',
    rateKey:'',
    categoryCode:[],
    assesmentName:'',
    assesmentGrade:'',
    classtype:[],
    assesmentCode:[],

    assesment:'',
}
const [master, setMaster] = useState(initials)


const [category, setCategory]=useState([])
const [assesment, setAssesment]=useState([])
const [assesmentGrade, setAssesmentGrade]=useState([])


const [errors, setErrors] = useState({});
const [activeStep, setActiveStep] = useState(1);

const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        
    }
    const steps = [{title: 'Create Category'}, {title: 'Create Assessment'}, {title:'Create Rating'}, {title: 'Assign Assessment To Class'} ]


    const handleOnClickNext = useCallback(() => {
        handleUpdateSettings(false)
        setActiveStep(activeStep + 1);
       
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
       
    }, [activeStep])

    const  handleSelect=(option, action)=>{
      setMaster({...master, [action.name] : option });
       setErrors({...errors, [action.name]:''})


       if(action.name ==='classtype' && option!==null){
           var dt =data.filter(item=>item.sectionID===option.value)
        var ans = dt.length>0?JSON.parse(dt[0].standardsDetails):[]
        setStandardList(ans)
        
       }  

    }
    
  


    const handleChange = event =>{
        const {name, value} = event.target
       setMaster({...master, [name] : value });
       setErrors({...errors, [name]:''})
    }
    
   
    
    
    const fetchAssementGrade =()=>{
       
        let url = API_URL+'/assessment/settings/index';
        axios.get(url,  config).then(result=>{
            if(result.data.length!==0){

                try{
                let categor = result.data.data[0].category
                let assessmen = result.data.data[0].assessment
                let assesmentGrad = result.data.data[0].assessmentGrade

    setCategory(JSON.parse(categor))
    setAssesment(JSON.parse(assessmen).sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1))
    setAssesmentGrade(JSON.parse(assesmentGrad).sort((a, b) =>(a.rateKey > b.rateKey) ? 1 : -1))

    setData(result.data.assessment)

        }catch(e){
                            
        }
                }
        
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }
        
    const getCategory=(code)=>{
        const result = category && category.filter(list=>list.code===code);
      const answer = result.map((c)=>c.categoryName);
      return  answer
    }

  

    function submitCategory(event){ 
        event.preventDefault();
        if(!master.category){
            setErrors({...errors, category:'Category field is Required'})
        }else{
            var code = 'g'+Math.random().toString(36).substr(2,9)
            setCategory(prev=>{
                 return [...prev, {code:code, categoryName:master.category, order:category.length+1}].sort(sort)
            })
            setMaster({...master, category:''})
        }
    }


  const handleSubmitAssignment=(event)=>{
    event.preventDefault();
    var errormessage = [];

    if(master.classtype.length===0){
        var classtype ='Please select class';
        setErrors({...errors, classtype:classtype})
        errormessage.push(classtype);

    }

    if(master.assesmentCode.length===0){

        var assesmentCode ='Please select assessment';
        setErrors({...errors, assesmentCode:assesmentCode})
        errormessage.push(assesmentCode);
    }


    if (errormessage.length<=0) {



var allClass = master.classtype
var classList =[]

for (var i = 0, l = allClass.length; i < l; i++) {
    classList.push({classID:allClass[i].classID, sectionID:allClass[i].value });
    }


        var options = master.assesmentCode;
        var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
    value.push({assessmentID:options[i].value});
    }
        const allStandard = standardList.concat(value)
        const newStandard =	allStandard.map(e=>e['assessmentID'])
                        .map((e,i,final)=>final.indexOf(e)===i&&i)
                        .filter(e=>allStandard[e])
                        .map(e=>allStandard[e])

             setNotice({...notice,  isLoading: true}) 
            const fd = new FormData(); 
            fd.append('jwt', Token);

            
            fd.append('classList', JSON.stringify(classList, null, 2)) 
            fd.append('standardsDetails', JSON.stringify(newStandard)) 
            
            fd.append('classList', JSON.stringify(classList, null, 2)) 
            fd.append('standardsDetails', JSON.stringify(newStandard, null, 2)) 
             let url = ServerUrl+'/save_controller/tbl_class_standards';
            axios.post(url, fd, config)
            .then(response =>{
                if (response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                } else if (response.data.type ==='info'){
                    Alerts('Update!', 'info', response.data.message)
                } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
            }  
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            })
            .finally(()=>{
              setNotice({...notice, isLoading: false}) 
            setMaster({...master, classtype:[], assesmentCode:[]})
            fetchAssementGrade()
            })   
    }
  } 

  const handleDeleteStandards =(ID)=>{
    let close =   document.getElementById('btnWarningDialog-'+ID)
    close.click();
    

    setNotice({...notice,  isLoading: true}) 

    const fd = new FormData();
    fd.append('ID', 'ID')
    fd.append('data', ID)
    fd.append('jwt', Token);
let url = ServerUrl+'/delete_controller/tbl_class_standards';
      axios.post(url, fd, config)
      .then(response =>{
       if(response.data.type ==='success'){
          Alerts('Saved!', 'default', response.data.message)
              } else{
          Alerts('Error!', 'danger', JSON.stringify(response.data))
              }   
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
      }).finally(()=>{
        fetchAssementGrade()
          setNotice({...notice, 
              isLoading: false
          })
      }) 
}
    
    
    
     


function submitAssesment(event){ 
    event.preventDefault();
    if(!master.assesmentName){
        setErrors({...errors, assesmentName:'Standards field is Required'})
    }else if(master.categoryCode.length===0){
        setErrors({...errors, categoryCode:'Standards category is Required'})
        
    }else{
        var code = 'a'+Math.random().toString(36).substr(2,9)
        setAssesment(prev=>{
             return [...prev, {code:code, assesmentName:master.assesmentName, categoryCode:master.categoryCode.value}]
        })
        setMaster({...master, assesmentName:'', categoryCode:[]})
    }
}

function submitAssesmentGrade(event){ 
    event.preventDefault();
    if(!master.assesmentGrade){
        setErrors({...errors, assesmentGrade:'This field is required'})
    }else if(!master.rateKey){
        setErrors({...errors, rateKey:'This field is required'})
    }
    
    else{
        var code = 'as'+Math.random().toString(36).substr(2,9)
        setAssesmentGrade(prev=>{
             return [...prev, {code:code, assesmentGrade:master.assesmentGrade, rateKey:master.rateKey}]
        })
        setMaster({...master, assesmentGrade:'', rateKey:''})
    }
}


const  handleUpdateSettings=(data)=>{  
    
    if(category.length!==0){
    const fd = new FormData(); 
    fd.append('category', JSON.stringify(category));
    fd.append('assessment', JSON.stringify(assesment));
    fd.append('assessmentGrade', JSON.stringify(assesmentGrade));
    setNotice({...notice, 
      isLoading: true}) 

      let url = API_URL+'/assessment/settings/update/1';
      axios.post(url, fd, config)
      .then(response =>{
          if(data===true){
        if (response.data.status ===200){
            Alerts('Saved!', 'info', response.data.message)
        } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
    }
}  
    })
    .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
    .finally(()=>{
        setNotice({...notice, 
            isLoading: false})
    })
}
}
  
const handleDelete = (code, table)=>{ 
    if(table==='category'){
        setCategory(category.filter(item=>item.code!==code).sort(sort))
    }else if(table==='assesment'){
        setAssesment(assesment.filter(item=>item.code!==code))
    }else{
        setAssesmentGrade(assesmentGrade.filter(item=>item.code!==code))
    }
}

    const BasicTable=(props)=>{
        const handleUpdate=(column, newValue, code)=>{   
            
            var table = props.table
    
            if(table==='category'){
                var allData = category.filter(item=>item.code!==code)
                let edit = category.filter(item=>item.code===code)
                    edit[0][column.dataField] = newValue
                setCategory(edit.concat(allData).sort(sort))
    
            }else if(table==='assesment'){
                var allData = assesment.filter(item=>item.code!==code)
                let edit = assesment.filter(item=>item.code===code)
                    edit[0][column.dataField] = newValue
                setAssesment(edit.concat(allData).sort(sort))
            }else{
                var allData = assesmentGrade.filter(item=>item.code!==code)
                let edit = assesmentGrade.filter(item=>item.code===code)
                    edit[0][column.dataField] = newValue
                setAssesmentGrade(edit.concat(allData).sort(sort))
            }
         } 
    
    
         const { SearchBar } = Search;
             
              const customTotal = (from, to, size) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search  className="table-responsive"
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                                 <SearchBar
                                 { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.code);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    
     


        const tableCategoryHeader = [
            /* {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'category')}><i className="fa fa-trash"></i></button>, editable: false}, */
            {dataField: 'categoryName', text: 'Category', editable: false, sort: true, validator: (newValue, row, column) => {
                if (!newValue) {
                  return {
                    valid: false,
                    message: 'Category field is required'
                  };
                }
                return true;
              } },
              {dataField: 'order', text: 'order',  sort: true, validator: (newValue, row, column) => {
                if (!Number(newValue)) {
                  return {
                    valid: false,
                    message: 'Order field required a valid number'
                  };
                }
                return true;
              } }      
         ];



     const tableAssesmentHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'assesment')}><i className="fa fa-trash"></i></button>, editable: false},
            {dataField: 'assesmentName', text: 'Standards Name',  sort: true, validator: (newValue, row, column) => {
                if (!newValue) {
                  return {
                    valid: false,
                    message: 'This field is required'
                  };
                }
                return true;
              } },
              {dataField: 'categoryCode', text: 'Category',  sort: true,  editable: false,  formatter: (cell)=>getCategory(cell)},   
     ];


     const tableAssesmentGradeHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'assessment')}><i className="fa fa-trash"></i></button>, editable: false},
            {dataField: 'rateKey', text: 'Key',  sort: true, editor: {
                type: Type.SELECT, options:[
                            {value:'A', label:'A'},
                            {value:'B', label:'B'},
                            {value:'C', label:'C'},
                            {value:'D', label:'D'},
                            {value:'E', label:'E'},
                ] }},
              {dataField: 'assesmentGrade', text: 'Description',  sort: true, validator: (newValue, row, column) => {
                if (!newValue) {
                  return {
                    valid: false,
                    message: 'Grade field is required'
                  };
                }
                return true;
              } } 
     ];
   

    
     const getStandardName = (ID, field) =>{        
        const result = assesment&&assesment.filter(list=>list.code === ID);
        return   String(result.map(c=>c[field]));
        }

     const duplicateSubject =(classID, sectionID, assessmentDetails)=>{
    
        const standard = JSON.parse(assessmentDetails)

       let list = []

         for(var i=0; i<standard.length; i++){
             list.push({
                 key:i+1,
                 label:getCategory(getStandardName(standard[i].assessmentID, 'categoryCode')) + ' => '+  getStandardName(standard[i].assessmentID, 'assesmentName'),
                 value:standard[i].assessmentID
             })
         }
     


         const section = [{
                     classID:classID,
                     isSection : "0",
                     label: getClassName(classID, sectionID, schoolClass),
                     value:sectionID
         }]
         setMaster({...master, classtype: section, assesmentCode:list});  
         
        }

    
     const tableHeader = [
        
        {dataField: 'sectionID', text: 'Class', editable: false, formatter:(cell, row)=>getClassName(row.classID, row.sectionID, schoolClass)}, 
        {dataField: 'id', text: 'Action', formatter: (cell, row)=><div> 
            
        {allowDelete==='Yes'?<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.sectionID}`} title="Delete"><i className="fa fa-trash"></i></button>:[]} &nbsp;
         
        {allowEdit==='Yes'? <button type="button" className="btn btn-warning btn-sm" onClick={()=>duplicateSubject(row.classID, row.sectionID, row.assessmentDetails)} title="Duplicate Class Subject"><i className="fa fa-edit"></i> Modify</button>:[]}

        <WarningModal message='Once deleted, you will no be able to recover this record. Are you realy sure?' handleAction={()=>handleDeleteStandards(row.sectionID)} mID={row.sectionID} />  </div>, editable: false, hidden:allowDelete==='No'&& allowEdit==='No'?true:false}
        
     ];

    

     const StandardsTable=(props)=>{
        

   
        const expandRow = {
            renderer: row => (
               
                <div>
                    
                   <p><b>Standard:</b> </p>
                  <div className="table-responsive">
            <table className="table ">
                  <thead style={{textAlign:'center'}}>
                      <tr >
                          <th  >Category</th>
                          <th >Name</th>
                      </tr>
                  </thead>
              <tbody >
             
                     {(JSON.parse(row.assessmentDetails)).map((list, idx)=>{
                   return (
                    
              <tr key={idx}>
                  <td>{getCategory(getStandardName(list.assessmentID, 'categoryCode'))} </td>
                  <td>{getStandardName(list.assessmentID, 'assesmentName')}</td>
                  
              </tr>
        )} )  }      
              </tbody>
              </table> 
              </div> 
            </div>),
            showExpandColumn: true,
            expandByColumnOnly: true,
            expandColumnPosition: 'left',
            onlyOneExpanding: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <button type='button' className='btn-inverse' title="Close All"><i className=" fa fa-minus-square"></i></button>
                  }
                  return <button type='button' className='btn-primary' title="View All"><i className=" fa fa-plus-square"></i></button>
                },
                expandColumnRenderer: ({ expanded }) => {
                  if (expanded) {
                    return <button type='button' className='btn-inverse' title="Close"><i className=" fa fa-minus-square"></i></button>
                  }
                  return <button type='button' className='btn-primary'  title="View"><i className=" fa fa-plus-square"></i></button>
                }
            
          } 
      
      
          const { SearchBar } = Search;
         
          const customTotal = (from, to, size) => (
             <span >&nbsp;Showing { from } to { to } of { size } items</span>
           );
        const options = {
            showTotal: false,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500},
        { text: 'All', value: props.data.length }]
           
          };
      
        return <ToolkitProvider search className="table-responsive"
                    keyField='ID' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            <div >
                                <SearchBar
                                { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
              
                                <BootstrapTable
                                { ...props.baseProps }
                                        hover
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    expandRow={expandRow } 
                                    
                                />
                            </div>
                            )
                        }
      </ToolkitProvider>
      }

     useEffect(()=>{

        fetchAssementGrade();
      
     },[]);

        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }

        <PageHeader title="Assessment Settings">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Assessment</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Assessment Settings</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-inverse btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '50px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="grade">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

       <div className="form-group">
           <div className="input-group">
                       <input type="text" placeholder='Enter Category' name='category' onChange={handleChange} value={master.category} className={errors.category ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                      <button className="btn btn-inverse" type="button" onClick={submitCategory}>Add</button>
              </div>
            <span style={{color:'red'}}>{errors.category && errors.category}</span>
           </div>		
      </section>
          </div>
                               </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={category}  table={'category'} columns={tableCategoryHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>
	  :  activeStep === 2 ? 
<div className="row setup-content" id="extras">
		    
            <div className="col-md-12">
     
            <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="row">
             <div className="col-md-6">    
            <label >Standards Name</label>
                <div className="input-group">
                     <input type="text" placeholder='Enter Standards Name' name='assesmentName' onChange={handleChange} value={master.assesmentName} className={errors.assesmentName ? 'form-control form-control-danger' : 'form-control'} />
                     
                   </div>
                   <span style={{color:'red'}}>{errors.assesmentName && errors.assesmentName}</span>
               
                   </div>     
                   <div className="col-md-6"> 
                    <label >Category</label>
                    <Select 
                             options={
                                category&&category.map((lst, idx)=> {
                                   return {key:idx, value: lst.code, label: lst.categoryName}
                                 })} 
             onChange={handleSelect}   name="categoryCode" value={master.categoryCode} className={errors.categoryCode ? 'form-control form-control-danger' : ''} styles={selectStyles}  /> 
     
     
           
                   </div>
                   </div>
     
                   <div className="row">
                   <div className="col-md-6"> 
                   <button className="btn btn-inverse pull-right"  type="button" onClick={submitAssesment}>Add</button>
                   </div></div>
                </div>
                                    
            </div>	
                  <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="row">
                                
            <section className="col-md-12 table-responsive">
            {
        <BasicTable data={assesment}  table={'assesment'} columns={tableAssesmentHeader}  />} 
            </section>
                    </div>
                    </div>
            
            </div>	</div>
            
            </div>	
                 
            </div>
         
     </div>

      :  activeStep === 3 ? 
      
      <div className="row setup-content" id="extrasMark">
		    
<div className="col-md-12">

<div className="card z-depth-0">

<div className="card-block">
<div className="card z-depth-0">

<div className="card-block">
<div className="row">
                    
<section className="col-md-4">

<div className="form-group">
    
    <select name="rateKey" value={master.rateKey}  className={errors.rateKey ? 'form-control form-control-danger' : 'form-control'} onChange={handleChange}   >
    
    <option  value='' selected disabled >Select Key</option>

    <option  value='A'>A</option>
    <option  value='B'>B</option>
    <option  value='C'>C</option>
    <option  value='D'>D</option>
    <option  value='E'>E</option>
    </select>

    </div>
    </section>

    <section className="col-md-8">
    <div className="form-group">
    <div className="input-group">

                <input type="text" placeholder='Enter Assesment Description' name='assesmentGrade' onChange={handleChange} value={master.assesmentGrade} className={errors.assesmentGrade ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
               <button className="btn btn-inverse" type="button" onClick={submitAssesmentGrade}>Add</button>
       </div>

        <span style={{color:'red'}}>{errors.assesmentGrade && errors.assesmentGrade}</span>
    </div>		
</section>
   </div>
                        </div>
                        
</div>	
      <div className="card z-depth-0">

<div className="card-block">
<div className="row">
                    
<section className="col-md-12 table-responsive">
{notice.isDataFetching ? <TableLoader />:
   <BasicTable data={assesmentGrade}  table={'assesmentGrade'} columns={tableAssesmentGradeHeader}  />} 
   </section>
        </div>
        </div>

</div>	</div>

</div>	
     
</div>

</div>:
      
      
      
      <div className="row setup-content" id="gradeMark">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">
  <div className="card-block">
  
<div  className="row">
<section className="col-md-5"><label >Student Class </label></section>
     <section className="col-md-5"><label >Standards </label></section>   
     </div>



     <div  className="row">
      <section className="col-md-5">

      <div className="form-group">
      <Select  isMulti options={
                            schoolClass&&schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  name="classtype" value={master.classtype} className={errors.classtype ? 'form-control form-control-danger' : ''} styles={selectStyles} /> 

			</div>

            </section> 

            <section className="col-md-5">
      <div className="form-group">
                        <Select isMulti options={
                            assesment&&assesment.map((lst, idx)=> {
                               return {key:idx, value: lst.code, label: lst.assesmentName, categoryCode:lst.categoryCode}
                             })} 
                             getOptionLabel={option => getCategory(option.categoryCode) + ' => '+  option.label} 
onChange={handleSelect} name="assesmentCode" value={master.assesmentCode} className={errors.assesmentCode ? 'form-control form-control-danger' : ''} styles={selectStyles}  /> 
			</div>
</section>  

  
        <section className="col-md-2"> 
     <button type="button" onClick={handleSubmitAssignment} className="btn btn-primary waves-effect"  id="saveRange"  >Save</button>
     </section>

     </div>      
</div>
                              
      </div>	
            <div className="card z-depth-0">

            <div className="card-block">


<div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <StandardsTable data={data}  columns={tableHeader}  schoolClass={schoolClass} />}
	
	</div></div>
      
      </div>	</div>
      
      </div>	
           
      </div>
   
</div>

}

     <button onClick={activeStep === steps.length ? ()=>handleUpdateSettings(true) : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Finish </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ExamStandards) 