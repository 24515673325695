import React, {useState, useEffect} from 'react'
import { config, ImagesUrl, ServerUrl, staffCode, staffName, Token } from './include';
import { Alerts } from './notify';
import axios from 'axios'
import { shortText, timeSince } from './globalFunction';


import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const Header = ()=>{

let location = useLocation()
    const dispatch = useDispatch()
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false,
        userCode:staffName
	})

    const [isFull, setIsFull] = useState(false)
const [users, setUsers] = useState([])
const [sessionYear, setSessionYear] = useState([])
const [noticeboard, setNoticeboard]=useState([])
const [ticket, setTicket]=useState([])
const [currentSession, setCurrentSession] = useState('')
/* Get the element you want displayed in fullscreen */ 
var elem = document.documentElement;

const IsFullMode =()=>{
     const windowWidth = window.innerWidth * window.devicePixelRatio;
    const windowHeight = window.innerHeight * window.devicePixelRatio;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    if (((windowWidth/screenWidth)>=0.95) && ((windowHeight/screenHeight)>=0.95)) {
        closeFullscreen()
        return true;
    }
    else {
        openFullscreen()
        return false
    } 
}

/* Function to open fullscreen mode */
function openFullscreen() {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem = window.top.document.body; //To break out of frame in IE
    elem.msRequestFullscreen();
  }
  setIsFull(true);
}

/* Function to close fullscreen mode */
function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    window.top.document.msExitFullscreen();
  }
  setIsFull(false);
}

//#404E67
const fetchNoticeBoard =()=>{
    var today = new Date().toISOString().slice(0,10);
    var sql ="Select  code, imageUrl, createdBy, description, dateCreate from tbl_noticeboard where status = 'Active' and expiry >= '"+today+"'"
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
    
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.length!==0){
            setNoticeboard(result.data)
        }
    })

}

const fetchTicket =()=>{
  
    var sql ="Select  code, senderType, ticketID, senderName, updated_at, senderImage, priority,  status,  subject, created_at from tbl_tickets where  messageType ='main' and (isNew = 'Yes' or status ='reply' ) "
      
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
    
    axios.post(url, fd, config).then(result=>{ 
    
        if(result.data.length!==0){
        setTicket(result.data)
        }
    })

}


const  handleFetchData =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetch_controller/tbl_school_session';
    axios.post(url, fd, config)
    .then(result => {
       
        if(result.data.length!==0){
        const current = result.data.filter(item=>item.status==='Active')
        if(Array.isArray(current)){
        setCurrentSession(current[0].startYear)
        setSessionYear(result.data)
        }
       
    }
    })
    }

function UpdateSession(code){
   const fd = new FormData(); 
   fd.append('code', code);
   fd.append('jwt', Token);
    let url = ServerUrl+'/update_controller/tbl_update_school_session';
   axios.post(url, fd, config)
   .then(response =>{
       if (response.data.type ==='info'){
           window.location.reload()
       } else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
   }  
           
   })
}




const fetchContent =()=>{

    if(staffCode && staffCode!==undefined){
        setNotice({...notice, userCode:staffCode})
        var sql ="Select passport, staffName, gender, userType from tbl_staff where code  ='"+staffCode+"'";
     
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller'
    
    axios.post(url, fd, config).then(result=>{ 
        
        if(result.data.length!==0 && Array.isArray(result.data)){
            dispatch({
                type:'SET_RECORDS',
                name:'user',
                data:result.data[0]
            })

            setUsers(result.data[0])
        }else{
            window.location.href='/logout'
        }
        
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
       window.location.href='/logout'
    })
    
    }else{
        window.location.href='/logout'
    }
}

const lockScreen =()=>{
    if (localStorage.getItem("lcksr") === null) {
        localStorage.setItem('lcksr', 'islk');
      }
      document.getElementById('openlockscreenModal').click()

}

useEffect(()=>{
    fetchContent()
fetchTicket()
handleFetchData()
fetchNoticeBoard()
},[]);


    return <>        
<nav className="navbar header-navbar pcoded-header no-print" header-theme="theme1" style={{backgroundColor:'#404E67', color:'#ffffff'}}>
    <div className="navbar-wrapper no-print">

        <div className="navbar-logo ">
            <a className="mobile-menu" id="mobile-collapse" href="#!">
                <i className="feather icon-menu"></i></a>
            <a href="/dashboard">
                <img className="img-fluid" style={{width:'180px'}} src={ImagesUrl+'/linelogo.png'} alt="Theme-Logo" />
            </a>
            <a href="#!" className="mobile-options">
                <i className="feather icon-more-horizontal"></i>
            </a>
        </div>

        <div className="navbar-container container-fluid ">
            <ul className="nav-left" >
                <li > <a href="#!" ><i className="feather icon-search" style={{color:'#ffffff'}}></i></a>
                </li>

                <li>
                <a href="#!"  onClick={!isFull?()=>openFullscreen():()=>IsFullMode()} >
                        <i className={`feather ${!isFull?'icon-maximize':'icon-minimize'} full-screen`}  style={{color:'#ffffff'}}></i>
                    </a> 
                </li>
                
                <li className="header-notification">
                    <div className="dropdown-primary dropdown">
                        <div className="dropdown-toggle" data-toggle="dropdown">
                        <a href="#!" style={{color:'#ffffff'}}><i className="feather icon-briefcase"></i> Selected Session Is {currentSession}</a>
                        </div>

                        <ul className="show-notification profile-notification dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                          {sessionYear&&sessionYear.map((data, id)=>
                           data.status==='Active'? <li key={id} ><h6 className="label label-success">{data.startYear} Selected</h6></li>:<li key={id} onClick={()=>UpdateSession(data.ID)}><div className="media"><div className="media-body"><h5 className="notification-user">{data.startYear}</h5> </div></div></li>
                            
                            )}

                            

                            
                        </ul>
                    </div>
                </li>

            </ul>

            <ul className="nav-right no-print">
                
                {ticket.length>0?<li className="header-notification">
                    <div className="dropdown-primary dropdown">
                        <div className="dropdown-toggle" data-toggle="dropdown">
                            <i className="feather icon-bell"></i>
                            <span className="badge bg-c-pink">{ticket.length}</span>
                        </div>
                        <ul className="show-notification notification-view dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                            <li>
                                <h6>Recent Ticket</h6>
                                <label className="label label-danger">New</label>
                            </li>

                            {ticket && ticket.slice(0,4).map((data, id)=>
                            <li key={id} onClick={()=>window.open('/communication/ticket/reply/'+data.ticketID, '_self')}>
                                <div className="media">
                                <img className="d-flex align-self-center img-radius" src={ImagesUrl+"/"+data.senderImage} alt="sender " onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.jpg"}} />
                                    <div className="media-body">
                                    <h5 className="notification-user">{data.senderName}</h5>
                            <p className="notification-msg">Subject: <b>{ shortText(data.subject, 20)} -{data.priority}</b></p>
                                        <span className="notification-time">{ timeSince(new Date(data.lastModified))}</span>
                                    </div>
                                </div>
                            </li>
                            )}

                        
                        </ul>
                    </div>
                </li>:''}
                 <li className="header-notification">
                    <div className="dropdown-primary dropdown">
                        <div className="displayChatbox dropdown-toggle" data-toggle="dropdown">
                            <i className="feather icon-message-square"></i>
                            <span className="badge bg-c-green">{noticeboard.length}</span>
                        </div>
                    </div>
                </li>

                
                <li className="user-profile header-notification">
                    <div className="dropdown-primary dropdown">
                        <div className="dropdown-toggle" data-toggle="dropdown">
                            <img src={ImagesUrl+"/staff/"+users.passport} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/staff/"+users.gender+".png"}} className="img-radius" alt="User-Profile" />
                            <span>{users.staffName}</span>

                            <i className="feather icon-chevron-down"></i>
                        </div>
                        <ul className="show-notification profile-notification dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                            
                            
                            <li> <Link to="/setup/school_details"><i className="feather icon-settings"></i> Settings </Link></li>
                          
                            <li>
                        <Link to="/staff/my_password"> <i className="feather icon-lock"></i> Change Password</Link>
                        </li>

                            <li> <Link to={"/staff/profile/"+notice.userCode}>
                                    <i className="feather icon-user"></i> Profile

                                </Link>
                            </li>

                            <li>
                            <Link to="/communication/tickets"> <i className="feather icon-mail"></i>Tickets</Link>
                              
                            </li>

                            <li>
                    
                    <a href="#!" onClick={lockScreen}>
                        <i className="feather icon-lock"></i> Lock Screen
                    </a>
                </li>
                            <li>
                                <a href={"/logout/?refferer="+location.pathname}>
                                    <i className="feather icon-log-out"></i> Logout
                                </a>
                            </li>
                        </ul>

                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
       {/* <!-- Sidebar chat start --> */}
       <div id="sidebar" className="users p-chat-user showChat no-print">
            <div className="had-container">
                <div className="card card_main p-fixed users-main">
                    <div className="user-box">
                        <div className="chat-inner-header">
                            <div className="back_chatBox">
                                <div className="right-icon-control">
                                    <h5>Notice Board</h5>
                                </div>
                            </div>
                        </div>
                        <div className="main-friend-list">
                        {noticeboard && noticeboard.map((row, id)=>
                            <div key={id} className="media userlist-box" data-id="1" data-status="online" data-username={row.code} data-toggle="tooltip" data-placement="left" title={'Created By: '+row.createdBy}>
                                <a className="media-left" href="/communication/noticeboard">
                                    <img className="media-object img-radius img-radius" src={ImagesUrl+'/noticeboard/'+row.imageUrl} alt={row.code} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.jpg"}} />
                                    <div className="live-status bg-success"></div>
                                </a>
                                <div className="media-body">
                                    <div className="f-13 chat-header" onClick={()=>window.open('/communication/noticeboard', '_self')} dangerouslySetInnerHTML={{__html:row.description.slice(0, 30)+'...'}}  ></div> 
                                </div>
                            </div>  )}
                       
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

    </>
}
export default React.memo(Header)