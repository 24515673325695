import React, {useState} from 'react'

import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
const Navbar = (props)=>{
    
    let location =  useLocation()
    const menu  = useSelector(state => state.rootReducer.sidebar);
   
const [isDisplaySubmenu, setIsDisplaySubmenu] = useState({ [props.submenu]: true });
const [isDisplay, setIsDisplay] = useState({ "0": true });



   const menuList = [
    {
      "menuName": "Dashboard",
      "description": "Display website usage statistics",
      "menuIcon": "fa fa-fw fa-dashboard",
      "status": true,
      "menuUrl": "/staffportal/dashboard",
      "order": 1,
      "ID": "aw4jkqzjw",
      "submenu": [],
      "isActive": "Yes"
    },
    {
      "menuName": "Student",
      "description": "",
      "menuIcon": "fa  fa-graduation-cap",
      "status": true,
      "isActive": "Yes",
      "menuUrl": "",
      "order": "2",
      "ID": "xak21z3mb",
      "submenu": [
        {
          "subName": "View Student",
          "subUrl": "/staffportal/students/view_students",
          "status": true,
          "isActive": "Yes",
          "subDescription": "",
          "ID": "c9nae6qpq",
          "subsubmenu": []
        },
        {
          "subName": "Student Family",
          "subUrl": "/staffportal/students/define_family",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "1vq3qiyot",
          "subsubmenu": []
        },
        {
          "subName": "Student Review",
          "subUrl": "/staffportal/students/review",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "ee7kg238w",
          "subsubmenu": []
        },
        {
          "subName": "Student Birthdays",
          "subUrl": "/staffportal/students/birthdays",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "fj97efjmx",
          "subsubmenu": []
        }
      ]
    },
    {
      "menuName": "Academics",
      "description": "Examination Management",
      "menuIcon": "fa fa-file-o",
      "status": true,
      "menuUrl": "",
      "order": "3",
      "ID": "cw13tmtoo",
      "submenu": [
        {
          "subName": "Exam Schedule",
          "subUrl": "/staffportal/exam/exam_schedule",
          "status": true,
          "subDescription": "Exam Schedule",
          "ID": "iys4jhl9d",
          "subsubmenu": [],
          "isActive": "Yes"
        },
        {
          "subName": "Mock Result Entry",
          "subUrl": "/staffportal/exam/mock_result_entry",
          "subDescription": "",
          "status": true,
          "ID": "y2yvcz3me",
          "subsubmenu": [],
          "isActive": "Yes"
        },
        {
          "subName": "Terminal Result Entry",
          "subUrl": "/staffportal/exam/result_entry",
          "subDescription": "",
          "status": true,
          "ID": "18k3jph3w",
          "subsubmenu": [],
          "isActive": "Yes"
        },
        {
          "subName": "Assessment Entry",
          "subUrl": "/staffportal/exam/assesment_entry",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "eip7df01c",
          "subsubmenu": []
        },
        {
          "subName": "Comment Entry",
          "subUrl": "/staffportal/exam/result_comment",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "j17l15yq2",
          "subsubmenu": []
        },
        {
          "subName": "Reports",
          "subUrl": "",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "op949f98f",
          "subsubmenu": [
            {
              "subsubName": "Subject Marks",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/exam/subject_marks",
              "subsubDescription": "",
              "ID": "urq5w2c3j"
            },
            {
              "subsubName": "Class Marks",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/exam/class_marks",
              "subsubDescription": "",
              "ID": "6j2r7mxmg"
            },
            {
              "subsubName": "Mid Term Result",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/exam/mid_term_result",
              "subsubDescription": "",
              "ID": "sc2gwteq0"
            },
            {
              "subsubName": "Terminal Result",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/exam/view_result",
              "subsubDescription": "",
              "ID": "gj43eqbcf"
            },
            {
              "subsubName": "Student Transcript",
              "status": true,
              "isActive": "No",
              "subsubUrl": "/staffportal/exam/student_transcript",
              "subsubDescription": "",
              "ID": "03vpf3tc5"
            },
            {
              "subsubName": "Class Cumulative",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/exam/class_session_cummulative",
              "subsubDescription": "",
              "ID": "9ialdtso1"
            },
            {
              "subsubName": "Subject Marks Summary",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/exam/subject_mark_summary",
              "subsubDescription": "Subject Marks Summary",
              "ID": "9fbwl7f61"
            }
          ]
        }
      ],
      "isActive": "Yes"
    },
    {
      "menuName": "Attendance",
      "description": "Staff & Student attendance",
      "menuIcon": "fa  fa-fw fa-calendar",
      "status": true,
      "menuUrl": "",
      "order": "4",
      "ID": "6uabdn9wm",
      "submenu": [
        {
          "subName": "Display Staff Attendance",
          "subUrl": "/staffportal/attendance/display_staff_attendance",
          "subDescription": "",
          "status": true,
          "ID": "wp0in022b",
          "subsubmenu": [],
          "isActive": "Yes"
        },
        {
          "subName": "Student Attendance",
          "subUrl": "/staffportal/attendance/student_attendance",
          "subDescription": "",
          "status": true,
          "ID": "i2p5y7thw",
          "subsubmenu": [],
          "isActive": "Yes"
        },
        {
          "subName": "Display Student Attendance",
          "subUrl": "/staffportal/attendance/display_student_attendance",
          "subDescription": "Pick and Drop Student",
          "isActive": "Yes",
          "status": true,
          "ID": "jm83o9xeu",
          "subsubmenu": []
        },
        {
          "subName": "Student Late Arrival & Early Departure",
          "subUrl": "/staffportal/attendance/student_arrival_departure",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "xi16fedgu",
          "subsubmenu": []
        },
        {
          "subName": "Student Pick & Drop",
          "subUrl": "/staffportal/attendance/student_pick_drop",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "iv8jmbo3u",
          "subsubmenu": []
        },
        {
          "subName": "Report",
          "subUrl": "",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "9xe9yudks",
          "subsubmenu": [
            {
              "subsubName": "Staff Summary",
              "status": true,
              "subsubUrl": "/staffportal/attendance/staff_attendance_summary",
              "isActive": "Yes",
              "subsubDescription": "",
              "ID": "w6mxqnssk"
            },
            {
              "subsubName": "Staff Working Hours",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/attendance/staff_working_hours",
              "subsubDescription": "",
              "ID": "d6hyzlpvq"
            },
            {
              "subsubName": "Student Summary",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/attendance/student_attendance_summary",
              "subsubDescription": "",
              "ID": "ex8xvjt5c"
            }
          ]
        }
      ],
      "isActive": "Yes"
    },
    {
      "menuName": "Payroll",
      "description": "",
      "menuIcon": "fa  fa-fw fa-briefcase",
      "status": true,
      "isActive": "Yes",
      "menuUrl": "",
      "order": "5",
      "ID": "9yv7gcvlp",
      "submenu": [
        {
          "subName": "Salary Structure",
          "subUrl": "/staffportal/payroll/salary_structure",
          "status": true,
          "isActive": "Yes",
          "subDescription": "",
          "ID": "vk18xmfwe",
          "subsubmenu": []
        },
        {
          "subName": "Apply Leave",
          "subUrl": "/staffportal/payroll/apply_leave",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "evd2srssl",
          "subsubmenu": []
        },
        {
          "subName": "Report",
          "subUrl": "",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "j13q8fn8m",
          "subsubmenu": [
            {
              "subsubName": "Staff Salary",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/payroll/staff_salary",
              "subsubDescription": "",
              "ID": "mce85s63o"
            },
            {
              "subsubName": "Print Pay Slip",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/payroll/payslip",
              "subsubDescription": "",
              "ID": "ii2dk36i7"
            }
          ]
        }
      ]
    },
    {
      "menuName": "Library",
      "description": "Library books",
      "menuIcon": "fa  fa-fw fa-book",
      "status": true,
      "menuUrl": "",
      "order": "6",
      "ID": "nhn0mxac0",
      "submenu": [
        {
          "subName": "Library Due",
          "subUrl": "/staffportal/library/books_report",
          "status": true,
          "subDescription": "",
          "ID": "z6hxyx0kk",
          "subsubmenu": [],
          "isActive": "Yes"
        },
        {
          "subName": "E-Library",
          "subUrl": "/staffportal/library/eLibrary",
          "subDescription": "",
          "status": true,
          "ID": "dar95nzqk",
          "subsubmenu": [],
          "isActive": "Yes"
        },
        {
          "subName": "Book Availability",
          "subUrl": "/staffportal/library/books",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "zpj8av2tv",
          "subsubmenu": []
        }
      ],
      "isActive": "Yes"
    },
    {
      "menuName": "Timetable",
      "description": "Class Timetable",
      "menuIcon": "fa  fa-fw fa-table",
      "status": true,
      "menuUrl": "",
      "order": "7",
      "ID": "vlhb61kv1",
      "submenu": [
        {
          "subName": "Automatic Timetable Setup",
          "subUrl": "/staffportal/timetable/setup",
          "subDescription": "",
          "status": true,
          "ID": "uaakk7drx",
          "subsubmenu": [],
          "isActive": "Yes"
        },
        {
          "subName": "Timetable Day Wise",
          "subUrl": "/staffportal/timetable/setup_class_timetable",
          "subDescription": "",
          "status": true,
          "ID": "f4gaykchs",
          "subsubmenu": [],
          "isActive": "Yes"
        },
        {
          "subName": "Class Lesson Plan",
          "subUrl": "/staffportal/timetable/class_lesson_plan",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "ezcnt0fem",
          "subsubmenu": []
        },
        {
          "subName": "Staff Schedule",
          "subUrl": "/staffportal/timetable/staff_schedule",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "xad8353v8",
          "subsubmenu": []
        },
        {
          "subName": "Report",
          "subUrl": "",
          "subDescription": "",
          "isActive": "Yes",
          "status": true,
          "ID": "izhme3kin",
          "subsubmenu": [
            {
              "subsubName": "Holiday List",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/timetable/holidays_list",
              "subsubDescription": "",
              "ID": "jvxlms4ha"
            },
            {
              "subsubName": "Lesson Plan",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/timetable/lesson_plan",
              "subsubDescription": "",
              "ID": "jpghd159y"
            },
            {
              "subsubName": "View Timetable",
              "status": true,
              "isActive": "Yes",
              "subsubUrl": "/staffportal/timetable/view_timetable",
              "subsubDescription": "",
              "ID": "g514eeg89"
            }
          ]
        }
      ],
      "isActive": "Yes"
    },
    {
      "menuName": "Conference",
      "description": "Send an issue",
      "menuIcon": "fa  fa-fw fa-users",
      "status": true,
      "isActive": "Yes",
      "menuUrl": "/staffportal/conference",
      "order": "8",
      "ID": "c11ou7hx2",
      "submenu": []
    },
    {
      "menuName": "Noticeboard",
      "description": "School Noticeboard",
      "menuIcon": "fa  fa-fw fa-bar-chart",
      "status": true,
      "isActive": "Yes",
      "menuUrl": "/staffportal/staff/noticeboard",
      "order": "9",
      "ID": "dvc99i73v",
      "submenu": []
    },
    {
      "menuName": "My Signature",
      "description": "",
      "menuIcon": "fa  fa-fw fa-image",
      "status": true,
      "isActive": "Yes",
      "menuUrl": "/staffportal/staff/signature",
      "order": 10,
      "ID": "2j8q5n29l",
      "submenu": []
    }
  ]

const handleToggleDisplaySubmenu=(main, sub)=>{
   // handleToggleDisplay(main)
   if(sub!=='0'){
   
    setIsDisplay({[main]: true});
    setIsDisplaySubmenu({[sub]: !isDisplaySubmenu[sub]});
}else{
    setIsDisplay({[main]: !isDisplay[main]})
}
}


let activeHasmenuOpen ='pcoded-hasmenu pcoded-trigger active'


    return (  
        <nav className="pcoded-navbar no-print"  >
        <div className="pcoded-inner-navbar main-menu " >
            <div className="pcoded-navigatio-lavel" menu-title-theme="theme5" ></div>
            
             <ul className="pcoded-item pcoded-left-item no-print">
              
             {menu.length!==0?menu.map((data, index)=>data.isActive==='Yes'?
        <li  key={index} dropdown-icon={data.submenu.length!==0?"style2":''} subitem-icon={data.submenu.length!==0?"style2":''} 
        
        className={data.submenu.length ===0 && location.pathname===data.menuUrl?'active':
           
            isDisplay[data.ID] && props.menu===data.menuName && data.submenu.length!==0?activeHasmenuOpen:
            props.menu===data.menuName && data.submenu.length!==0?'pcoded-hasmenu active':
            isDisplay[data.ID] && data.submenu.length !==0 ?'pcoded-hasmenu pcoded-trigger':
        
        data.submenu.length !==0?'pcoded-hasmenu':  ''}
        
        >
                  
{data.submenu.length!==0? <a href='#!' title={data.menuName} onClick={()=>handleToggleDisplaySubmenu(data.ID, '0')}>
<span className="pcoded-micon"><i className={data.menuIcon}></i></span>
                        <span className="pcoded-mtext ">{data.menuName}</span>

</a>:

<Link to={data.menuUrl} > 
   <span className="pcoded-micon"><i className={data.menuIcon}></i></span>
                        <span className="pcoded-mtext ">{data.menuName}</span>
                 
                    </Link>
                    
                    }
                    {data.submenu.length!==0?
                    <ul className="pcoded-submenu">
                  {data.submenu.map((smenu, sid)=>smenu.isActive==='Yes'?
          <li key ={sid} 

    className={smenu.subsubmenu.length ===0 && location.pathname===smenu.subUrl?'active':
    
        isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0 && props.submenu===smenu.subName?activeHasmenuOpen: 
        
        isDisplay[data.ID] && isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0 && props.submenu===smenu.subName ?'pcoded-hasmenu pcoded-trigger active': 
        

        isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0?'pcoded-hasmenu pcoded-trigger':
         smenu.subsubmenu.length !==0?'pcoded-hasmenu':
                        ''}  
                        dropdown-icon={smenu.subsubmenu.length!==0?"style3":''} subitem-icon={smenu.subsubmenu.length!==0?"style3":''}
                        
                        >
                

{smenu.subsubmenu.length!==0?    <a href='#!'
                        onClick={()=>handleToggleDisplaySubmenu(data.ID, smenu.ID)}>

<span className="pcoded-micon "><i className="feather icon-menu"></i></span>
                        <span className="pcoded-mtext " >{smenu.subName}</span>

                        </a>  :    
<Link to={smenu.subUrl} >   
                        <span className="pcoded-micon"><i className="feather icon-menu"></i></span>
                        <span className="pcoded-mtext" >{smenu.subName}</span>
               
                    </Link>}
                    {smenu.subsubmenu.length!==0?
                    <ul className="pcoded-submenu">

                        {smenu.subsubmenu.map((subsub, k)=>subsub.isActive==='Yes'?
                                <li   className={location.pathname===subsub.subsubUrl?'active':'' } key={k}  >
                                  
<Link to={subsub.subsubUrl} > 
                                        <span className="pcoded-mtext">{subsub.subsubName}</span>
                               
                                    </Link>
                                </li>:'')}

                            </ul>:''}
                        </li>:'')}
                       
                    </ul>:''}
                </li>:''):<div className='loadit'></div>}

                <li className="">
                    <a href="/logout">
                    <span className="pcoded-micon"><i className="feather icon-power"></i></span>
                    <span className="pcoded-mtext">Log Out</span>
                    </a>
                </li>
            </ul> 
 
        </div>
    </nav> );
}
export default React.memo(Navbar)