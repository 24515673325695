import React, {useState, useEffect, useCallback} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {API_URL, config, staffCode} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, longDate, sort, getResultHead} from '../component/globalFunction'
import { useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'
 const ResultEntry =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    const [csvFile, setCsvFile]=useState({
        studentCsv:'',
        fileName:'Please select .csv files (allowed file size 1Mb)'
    })


    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        subject:[],
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'}
    })


    const steps = [{title: 'Search Exam'}, {title: 'Enter Score'}]
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
   
    const [examtype, setExamtype]=useState([])
    const [subject, setSubject]= useState([]) 
    const [studentList, setStudentList]=useState([])
    const [errors, setErrors] = useState({});
    const [grade, setGrade] = useState([])
    const [grademark, setGrademark]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [remark, setRemark]=useState([])
const [activeStep, setActiveStep] = useState(1);
const handleOnClickStepper = (step) => {
        setActiveStep(step); 
    }

    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep])



    const fetchExamType =()=>{
       
        let url = API_URL+'/exam/result/examtype';
        axios.get(url,  config).then(result=>{
        
            let examtype = result.data.examType.filter(item=>item.term !=="MOCK EXAM")
            setExamtype(examtype)
        
            let settings = result.data.grademark;
            var grad = settings[0].grade
        
        var gradeRemar = settings[0].gradeRemark
        var gradeMar = settings[0].gradeMark
        setRemark(JSON.parse(gradeRemar).sort((a, b) =>(a.remarkName > b.remarkName) ? 1 : -1))
        setGrademark(JSON.parse(gradeMar).sort((a, b) =>(a.classtype > b.classtype) ? 1 : -1))
        setGrade(JSON.parse(grad).sort(sort))
        
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }
        

        const fetchSubject =(type, section)=>{
            setNotice({...notice, isLoading: true}) 
        let url = API_URL+'/subject/exam/'+type+'/'+section;
        axios.get(url, config).then(result=>{
            if(Array.isArray(result.data.data)){
                setSubject(result.data.data)
            }else{
                setSubject([]) 
            }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
        })
        }












        const fetchStudentList =()=>{
    
            let errors = {};   
           
            if(result.studentClass.length===0){
                errors.studentClass ='Please select class';
            }   
            if(result.examtype.length===0){
                errors.examtype ='Please select exam type';
            } 
            if(result.subject.length===0){
                errors.subject ='Please select subject';
            } 
             
            
            setErrors(errors)
        
            if(Object.keys(errors).length === 0){
        
                setNotice({...notice, isLoading: true}) 
        
        const fd = new FormData();
        
        fd.append("examtype", result.examtype.value);
        fd.append('subject', result.subject.value)
        fd.append("orderBy", result.orderBy.value);
        fd.append('studentClass', result.studentClass.value)
        
        
        let url = API_URL+'/exam/result/student';
        axios.post(url, fd, config).then(result=>{
            if(result.data.data.length!== 0 && Array.isArray(result.data.data)){
              
                //staffCode
             const stf = result.data.data[0].teacher;
        
         if(stf.includes(staffCode)){
            setStudentList(result.data.data)
            setActiveStep(2)
        }else{
            setStudentList([])
            Alerts('Error!', 'danger', 'Only subject teacher can record or modify exam scores ');
        } 
        
            }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        })
            }
        }





const handleSelect = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
   if(action.name==='studentClass'){
    fetchSubject(result.examtype.value, option.value)
   }else if(action.name==='examtype'){
   fetchSubject(option.value, result.studentClass.value)
   }
   setStudentList([])
  
}





const handleChangeScore =(event, index)=>{
    const student =JSON.parse(JSON.stringify(studentList))
    const {name, value} = event.target
    student[index][name] = value;
    setStudentList(student); 
       } 


       const handleChangeReception =(event, index)=>{
        let {name, value} = event.target
        value = value.replace(/[^0-9]/g, '');
        const student =JSON.parse(JSON.stringify(studentList))
        const currentResult = student.filter((_, id)=>id===index)
    
    
        var firstCA = parseFloat(currentResult[0].firstCA)
        var secondCA = parseFloat(currentResult[0].secondCA)
        var thirdA = parseFloat(currentResult[0].thirdA)
        var thirdB = parseFloat(currentResult[0].thirdB)
        var exam = parseFloat(currentResult[0].exam)
        var total = parseFloat(currentResult[0].total)
    
    
    
        switch(name) {
            case 'firstCA':
                if(value<=10){
              total =  Number(value) + secondCA + thirdA + exam + thirdB
                }else{
                value =0}
              break;
              case 'secondCA':
                if(value<=10){
              total =  Number(value) + firstCA + thirdA + exam + thirdB
                }else{
                value =0}
              break;
                case 'thirdA':
                if(value<=20){
                total =  Number(value) + firstCA + secondCA + exam + thirdB
            }else{
                value =0}
                  break;
                  case 'thirdB':
                    if(value<=20){
                    total =  Number(value) + firstCA + secondCA + exam + thirdA
                }else{
                    value =0}
                      break;
                      case 'exam':
                        if(value<=40){
                        total =  Number(value) + firstCA + secondCA + thirdA + thirdB
                    }else{
                        value =0}
                          break;
                     default:
          }
    
    
            student[index][name] = value;
            student[index].total = total
            student[index].grade = getGrade(total, 'grade')
            student[index].remark = getGrade(total, 'remark')
    
          setStudentList(student); 
        
           } 

       
const handleChangeNursery =(event, index)=>{
    let {name, value} = event.target
    value = value.replace(/[^0-9]/g, '');
    const student =JSON.parse(JSON.stringify(studentList))
    const currentResult = student.filter((_, id)=>id===index)


    var firstCA = parseFloat(currentResult[0].firstCA)
    var secondCA = parseFloat(currentResult[0].secondCA)
    var thirdA = parseFloat(currentResult[0].thirdA)
    var exam = parseFloat(currentResult[0].exam)
    var total = parseFloat(currentResult[0].total)



    switch(name) {
        case 'firstCA':
            if(value<=10){
          total =  Number(value) + secondCA + thirdA + exam
            }else{
            value =0}
          break;
          case 'secondCA':
            if(value<=10){
          total =  Number(value) + firstCA + thirdA + exam
            }else{
            value =0}
          break;
            case 'thirdA':
            if(value<=20){
            total =  Number(value) + firstCA + secondCA + exam
        }else{
            value =0}
              break;
                  case 'exam':
                    if(value<=60){
                    total =  Number(value) + firstCA + secondCA + thirdA
                }else{
                    value =0}
                      break;
                 default:
      }


        student[index][name] = value;
        student[index].total = total
        student[index].grade = getGrade(total, 'grade')
        student[index].remark = getGrade(total, 'remark')

      setStudentList(student); 
    
       } 


const handleChangeGrade =(event, index, num)=>{
    let {name, value} = event.target
    value = value.replace(/[^0-9]/g, '');
    const student =JSON.parse(JSON.stringify(studentList))
    const currentResult = student.filter((_, id)=>id===index)


    var firstA = parseFloat(currentResult[0].firstA)
    var firstB = parseFloat(currentResult[0].firstB)
    var firstC = parseFloat(currentResult[0].firstC)
    var firstD = parseFloat(currentResult[0].firstD)
    var secondCA = parseFloat(currentResult[0].secondCA)
    var thirdA = parseFloat(currentResult[0].thirdA)
    var thirdB = parseFloat(currentResult[0].thirdB)
    var exam = parseFloat(currentResult[0].exam)
    var total = parseFloat(currentResult[0].total)



    switch(name) {
        case 'firstA':
            if(value<=num){
          total =  Number(value) + firstB + firstC +firstD + secondCA + thirdA + thirdB + exam
            }else{
            value =0}
          break;
          case 'firstB':
            if(value<=num){
          total =  Number(value) + firstA + firstC +firstD + secondCA + thirdA + thirdB + exam
        }else{
            value =0}
            break;
            case 'firstC':
            if(value<=num){
          total =  Number(value) + firstA + firstB +firstD + secondCA + thirdA + thirdB + exam
        }else{
            value =0}
            break;
            case 'firstD':
            if(value<=num){
          total =  Number(value) + firstA + firstC +firstB + secondCA + thirdA + thirdB + exam
        }else{
            value =0}
            break;
            case 'secondCA':
            if(value<=num){
          total =  Number(value) + firstA + firstC +firstD + firstB + thirdA + thirdB + exam
        }else{
            value =0}
            break;
            case 'thirdA':
            if(value<=num){
            total =  Number(value) + firstA + firstC +firstD + firstB + secondCA + thirdB + exam
        }else{
            value =0}
              break;
              case 'thirdB':
                if(value<=num){
                total =  Number(value) + firstA + firstC +firstD + firstB + secondCA + thirdA + exam
            }else{
                value =0}
                  break;
                  case 'exam':
                    if(value<=num){
                    total =  Number(value) + firstA + firstC +firstD + firstB + secondCA + thirdA + thirdB
                }else{
                    value =0}
                      break;
                 default:
      }


        student[index][name] = value;
        student[index].total = total
        student[index].grade = getGrade(total, 'grade')
        student[index].remark = getGrade(total, 'remark')

      setStudentList(student); 
    
       } 

  
const getGradeScore =(code)=>{
const ans = grade.filter(item=>item.code===code)
return ans[0].gradeName
}

const getRemark =(code)=>{
    const ans = remark.filter(item=>item.code===code)
    return ans[0].remarkName
    }

const roundNum =(num)=> (Math.round(parseFloat(num)*100)/100)
const getGrade =(score, field)=>{
   
    const ans = grademark.filter(itm=>itm.classtype===result.studentClass.classtype&& roundNum(score) >= roundNum(itm.minimum) && roundNum(score) <= roundNum(itm.maximum));
    const remark = ans.length!==0? getRemark(ans[0].gradeMark):''
    const grade = ans.length!==0? getGradeScore(ans[0].gradeCode):''
   

return field ==='grade'?String(grade):String(remark)
}

 
const handleSubmit = event =>{
    event.preventDefault();    
    
    let errors = {};   
    if(studentList.length===0){
        errors.studentClass ='There are no student in the selected class';
    } 
    if(result.studentClass.length===0){
        errors.studentClass ='Please select class';
    }   
    if(result.examtype.length===0){
        errors.examtype ='Please select exam type';
    } 
    if(result.subject.length===0){
        errors.subject ='Please select subject';
    } 
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

       submit()
    }


}

   

    

useEffect(()=>{
    //update every 4 minutes
    const interval =   setInterval(()=>{
        
        if(studentList.length!==0){
            const fd = new FormData(); 
       fd.append('studentList', JSON.stringify(studentList, null, 2));
       let url = API_URL+'/exam/result/update';         
            axios.post(url, fd, config)
            .then(response =>{
                if(response.data.status ===200){
                    Alerts('Updated!', 'default', 'You information has been saved')
                        } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                })
                .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                  })
    
                }
    }, 2 * 60 * 1000);
    return () => {
        if(interval){
          clearInterval(interval)
        }
      }
   },[]) 


   function submit(){
    
    setNotice({...notice, 
        isLoading: true})  


        const fd = new FormData(); 
       fd.append('studentList', JSON.stringify(studentList, null, 2));
        let url = API_URL+'/exam/result/update'; 
              
            axios.post(url, fd, config)
            .then(response =>{
                if(response.data.status ===200){
                    Alerts('Updated!', 'default', response.data.message)
                        } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data.message))
                        }   
                })
                .catch((error)=>{
                  //  Alerts('Error!', 'danger', error.message)
                  }).finally(()=>{
                    
                      setNotice({...notice, 
                          isLoading: false })
                      
                      setActiveStep(1)
                  })   
      }

  

const handleReset=()=>{
    setResult({ 
        studentClass:[],
        examtype:[],
        subject:[],
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'}
        })

        setStudentList([])
}







    useEffect(()=>{
        fetchExamType()
    },[]);
  

        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 

        <PageHeader title="Result Entry">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Result Entry</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Exam Result Entry</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '1px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="examType">
		  
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

      
   <div className="card-block">
      
  <div className="row">
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Exam Type <span style={{color:'red'}}>*</span></label><a href="/exam/result_setup" className="pull-right" >Add more exam</a>
         <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

            </div>
     
     </section>
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label><a href="/setup/school_classes" className="pull-right" >Add more classes</a>
        <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
     
     </section>
     </div>
     <div className="row">
     <section className="col-md-6">
           <div className="form-group">
         <label> Subject<span style={{color:'red'}}>*</span></label><a href="/setup/class_subject" className="pull-right" >Add subject</a>
         <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
                             getOptionLabel={option =>`${option.label} - ${option.abr}`}
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={result.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	</div>
     
     </section>
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Order</label>
         <Select  options={[
             {value:'order by s.studentName ASC', label:'order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelect}  name="orderBy" value={result.orderBy}  /> 

            </div>
     
     </section>
     
     </div>

     <div  className="row"> 
     
     <section className="col-md-12"> 


     <footer className="pull-right">
	<button type="button" onClick={handleReset} className="btn btn-inverse "><i className="fa fa-refresh"></i> Reset</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={fetchStudentList} className="btn btn-primary "><i className="fa fa-graduation-cap"></i> Display Student</button>
    </footer>						
     </section>

     </div> 
    </div>
                               
       </div>	     
       
       	</div>
       
       </div>	
			
       </div>
    
</div> :
<div className="row setup-content" id="extras">

           {getResultHead(result.studentClass.classtype)==='Primary'?
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">

   <div className="row">
   <div className='col-md-12 '>
           <div className='pull-right'>
               
           <b>{result.subject.length!==0&&result.studentClass.length!==0&&result.studentClass.label.toUpperCase()+' -'+result.subject.label.toUpperCase()}</b>

           </div></div>
   <div className="table-responsive">
        <table className="table table-bordered">
            <thead>
                <tr>
                <th rowSpan={2}>SN</th>
                    <th rowSpan={2}>Student Details</th>
                    <th colSpan={4}>1<sup>st</sup> C.A Rubric Worksheets (20%)</th>
                    <th rowSpan={2} style={{maxWidth:'120px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2<sup>nd</sup> C.A First Formal Test (20%)</th>
                    <th  colSpan={2}>3<sup>rd</sup> C.A (20%)</th>
                    <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Termly Exam (40%)</th>
                    <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Total (100%)</th>
                    <th rowSpan={2}>Grade</th>
                    <th rowSpan={2}>Remark</th> 
                            
                    <th rowSpan={2}>Absent</th>

                </tr>
                <tr>
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>1<sup>st</sup> 5mrks</th>
                    <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2<sup>nd</sup> 5mrks</th>
                    <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>3<sup>rd</sup> 5mrks</th>
                    <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>4<sup>th</sup> 5mrks</th>

                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Subject Project (Individual 10%)</th>
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Subject Project (Group 10%)</th>
                 

                </tr>
            </thead>
            <tbody>{studentList.length!==0?studentList.map((st, idx)=> 
                <tr key={idx}>
<td>{idx+1}</td>
                    <td style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.admissionNumber} - {st.studentName}</b> {st.termination==='Yes'?<div><br/><span className="pcoded-badge label label-danger">Terminated on {longDate(st.termination_date)} {st.termination_remark}</span></div>:''} 
                    
                   {getResultHead(result.studentClass.classtype)==='Pre-Nursery'||getResultHead(result.studentClass.classtype)==='Creche'? 
                    
                   <textarea name='comment' placeholder='Subject Keynote' value={st.comment} className="form-control" onChange={(e)=>handleChangeScore(e, idx)} rows={3}>{st.comment}
                   
                   </textarea>   :''} 
                    
                    </td>

                    
                    <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 5)} className="form-control" id={idx} name='firstA' value={st.firstA}  onChange={(e)=>handleChangeGrade(e, idx, 5)} /></td>

                    <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 5)} className="form-control" id={idx} name='firstB' value={st.firstB}  onChange={(e)=>handleChangeGrade(e, idx, 5)} /></td>

                    <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 5)} className="form-control" id={idx} name='firstC' value={st.firstC}  onChange={(e)=>handleChangeGrade(e, idx, 5)} /></td>

                    <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 5)} className="form-control" id={idx} name='firstD' value={st.firstD}  onChange={(e)=>handleChangeGrade(e, idx, 5)} /></td>


                    <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 20)} className="form-control" id={idx} name='secondCA' value={st.secondCA}  onChange={(e)=>handleChangeGrade(e, idx, 20)} /></td>

                    <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 10)} className="form-control" id={idx} name='thirdA' value={st.thirdA}  onChange={(e)=>handleChangeGrade(e, idx,10)} /></td>

                    <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 10)} className="form-control" id={idx} name='thirdB' value={st.thirdB}  onChange={(e)=>handleChangeGrade(e, idx, 10)} /></td>

                    <td><input type="text" onBlur={(e)=>handleChangeGrade(e, idx, 40)} onFocus={(e)=>e.target.select()} className="form-control" pattern='[0-9]' min={0} step={1} id={idx} name='exam' value={st.exam}  onChange={(e)=>handleChangeGrade(e, idx, 40)} /></td>

                    <td>{st.total}</td>
                    <td><b>{st.grade}</b></td>
                    <td style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.remark}</b></td>
                    
                    
                    <td>

                    <select name="absent" value={st.absent} style={{width:'70px'}} className="form-control" onChange={(e)=>handleChangeScore(e, idx)}   >
                    <option  value='No'>No</option>
                    <option  value='Yes'>Yes</option>
					</select>

                    </td>

                </tr>):[]}
            </tbody> 
        </table>
 </div>	 </div>   
<div style={{color:'red'}}>{errors.subject && errors.subject}</div>
<div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
<div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
       </div>			
       </div>       
       </div>:getResultHead(result.studentClass.classtype)==='Nursery'?

       
<div className="col-md-12">

<div className="card z-depth-0">

<div className="card-block">

<div className="row">
<div className='col-md-12 '>
    <div className='pull-right'>
        
    <b>{result.subject.length!==0&&result.studentClass.length!==0&&result.studentClass.label.toUpperCase()+' -'+result.subject.label.toUpperCase()}</b>

    </div></div>
<div className="table-responsive">
 <table className="table table-bordered">
     <thead>
         <tr>
         <th rowSpan={2}>SN</th>
             <th rowSpan={2}>Student Details</th>
             <th colSpan={3}>(PERCENTAGE SCORE)</th>

             
             
             <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Termly Exam (60%)</th>
             <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Total (100%)</th>
             <th rowSpan={2}>Grade</th>
             <th rowSpan={2}>Keynote</th> 
           {/*   <th rowSpan={2}>Absent</th> */}

         </tr>
         <tr>
             <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>1<sup>st</sup> CA 10%</th>
             <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2<sup>nd</sup> CA 10%</th>
             <th rowSpan={2} style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}> CLASS PRESENTATION (20%)</th>

         </tr>
     </thead>
     <tbody>{studentList.length!==0?studentList.map((st, idx)=> 
         <tr key={idx}>
<td>{idx+1}</td>
             <td style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.admissionNumber} - {st.studentName}</b> {st.termination==='Yes'?<div><br/><span className="pcoded-badge label label-danger">Terminated on {longDate(st.termination_date)} {st.termination_remark}</span></div>:''} 
             
            
             
             </td>
             
             
             <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeNursery(e, idx)} className="form-control" id={idx} name='firstCA' value={st.firstCA}  onChange={(e)=>handleChangeNursery(e, idx)} /></td>

             <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeNursery(e, idx)} className="form-control" id={idx} name='secondCA' value={st.secondCA}  onChange={(e)=>handleChangeNursery(e, idx)} /></td>

             <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeNursery(e, idx)} className="form-control" id={idx} name='thirdA' value={st.thirdA}  onChange={(e)=>handleChangeNursery(e, idx)} /></td>

             <td><input type="text" onBlur={(e)=>handleChangeNursery(e, idx)} onFocus={(e)=>e.target.select()} className="form-control" pattern='[0-9]' min={0} step={1} id={idx} name='exam' value={st.exam}  onChange={(e)=>handleChangeNursery(e, idx)} /></td>

             <td>{st.total}</td>
             <td><b>{st.grade}</b></td>
            
    <td>
          <textarea name='comment' placeholder='Keynote' value={st.comment} className="form-control" onChange={(e)=>handleChangeScore(e, idx)} rows={3}>{st.comment}
          
          </textarea> 
</td>
             
{/* <td>

<select name="absent" value={st.absent} style={{width:'70px'}} className="form-control" onChange={(e)=>handleChangeScore(e, idx)}   >
<option  value='No'>No</option>
<option  value='Yes'>Yes</option>
</select>

</td> */}


         </tr>):[]}
     </tbody> 
 </table>
</div>	 </div>   
<div style={{color:'red'}}>{errors.subject && errors.subject}</div>
<div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
<div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
</div>			
</div>       
</div>
:getResultHead(result.studentClass.classtype)==='Reception'?
<div className="col-md-12">

<div className="card z-depth-0">

<div className="card-block">

<div className="row">
<div className='col-md-12 '>
    <div className='pull-right'>
        
    <b>{result.subject.length!==0&&result.studentClass.length!==0&&result.studentClass.label.toUpperCase()+' -'+result.subject.label.toUpperCase()}</b>

    </div></div>
<div className="table-responsive">
 <table className="table table-bordered">
     <thead>

     <tr>
         <th rowSpan={2}>SN</th>
             <th rowSpan={2}>Student Details</th>
             <th colSpan={4}>(PERCENTAGE SCORE)</th>
             <th rowSpan={2} style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Exam (40%)</th>
             <th rowSpan={2} style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Total (100%)</th>
             <th rowSpan={2}>Grade</th>
             <th rowSpan={2}>Keynote</th> 
             {/* <th rowSpan={2}>Absent</th> */}
         </tr>
         <tr>
         <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Project 20%</th>
             <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>1<sup>st</sup> CA 10%</th>
             <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2<sup>nd</sup> CA 10%</th>
             <th rowSpan={2} style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}> CLASS PRESENTATION (20%)</th>

         </tr>
     </thead>
     <tbody>{studentList.length!==0?studentList.map((st, idx)=> 
         <tr key={idx}>
<td>{idx+1}</td>
             <td style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.admissionNumber} - {st.studentName}</b> {st.termination==='Yes'?<div><br/><span className="pcoded-badge label label-danger">Terminated on {longDate(st.termination_date)} {st.termination_remark}</span></div>:''} 
             
             </td>
             
             <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeReception(e, idx)} className="form-control" id={idx} name='thirdA' value={st.thirdA}  onChange={(e)=>handleChangeReception(e, idx)} /></td>
             
             <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeReception(e, idx)} className="form-control" id={idx} name='firstCA' value={st.firstCA}  onChange={(e)=>handleChangeReception(e, idx)} /></td>

             <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeReception(e, idx)} className="form-control" id={idx} name='secondCA' value={st.secondCA}  onChange={(e)=>handleChangeReception(e, idx)} /></td>

             <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeReception(e, idx)} className="form-control" id={idx} name='thirdB' value={st.thirdB}  onChange={(e)=>handleChangeReception(e, idx)} /></td>

             <td><input type="text" onBlur={(e)=>handleChangeReception(e, idx)} onFocus={(e)=>e.target.select()} className="form-control" pattern='[0-9]' min={0} step={1} id={idx} name='exam' value={st.exam}  onChange={(e)=>handleChangeReception(e, idx)} /></td>
             <td>{st.total}</td>
             <td><b>{st.grade}</b></td>
    <td>
          <textarea name='comment' placeholder='Keynote' value={st.comment} className="form-control" onChange={(e)=>handleChangeScore(e, idx)} rows={3}>{st.comment}
          
          </textarea> 
</td>
             
{/* <td>

<select name="absent" value={st.absent} style={{width:'70px'}} className="form-control" onChange={(e)=>handleChangeScore(e, idx)}   >
<option  value='No'>No</option>
<option  value='Yes'>Yes</option>
</select>

</td> */}


         </tr>):[]}
     </tbody> 
 </table>
</div>	 </div>   
<div style={{color:'red'}}>{errors.subject && errors.subject}</div>
<div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
<div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
</div>			
</div>       
</div>:
    getResultHead(result.studentClass.classtype)==='Secondary' ?
    <div className="col-md-12">
    <div className="card z-depth-0">

<div className="card-block">

<div className="row">
<div className='col-md-12 '>
        <div className='pull-right'>
            
        <b>{result.subject.length!==0&&result.studentClass.length!==0&&result.studentClass.label.toUpperCase()+' -'+result.subject.label.toUpperCase()}</b>

        </div></div>
<div className="table-responsive">
     <table className="table table-bordered">
         <thead>
             <tr>
             <th rowSpan={2}>SN</th>
             
           
                 <th rowSpan={2}>Student Details</th>

                 <th colSpan={2}>1<sup>st</sup> C.A Class Work (20%)</th>


                 <th rowSpan={2} style={{maxWidth:'120px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2<sup>nd</sup> C.A (20%)</th>

                 <th  rowSpan={2}> Project (20%)</th>
                 <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Termly Exam (40%)</th>
                 <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Total (100%)</th>
                 <th rowSpan={2}>Grade</th>
                 <th rowSpan={2}>Remark</th> 
                         
                 <th rowSpan={2}>Absent</th>

             </tr>
             <tr>
                 <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>1<sup>st</sup> 10 mrks</th>

                 <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2<sup>nd</sup> 10 mrks</th>

              

             </tr>
         </thead>
         <tbody>{studentList.length!==0?studentList.map((st, idx)=> 
             <tr key={idx}>
<td>{idx+1}</td>
                 <td style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.admissionNumber} - {st.studentName}</b> {st.termination==='Yes'?<div><br/><span className="pcoded-badge label label-danger">Terminated on {longDate(st.termination_date)} {st.termination_remark}</span></div>:''} 
                 
                {getResultHead(result.studentClass.classtype)==='Pre-Nursery'||getResultHead(result.studentClass.classtype)==='Creche' ||getResultHead(result.studentClass.classtype)==='Secondary'? 
                 
                <textarea name='comment' placeholder='Subject Keynote' value={st.comment} className="form-control" onChange={(e)=>handleChangeScore(e, idx)} rows={3}>{st.comment}
                
                </textarea>   :''} 
                 
                 </td>

                 
                 <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 10)} className="form-control" id={idx} name='firstA' value={st.firstA}  onChange={(e)=>handleChangeGrade(e, idx, 10)} /></td>

                 <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 10)} className="form-control" id={idx} name='firstB' value={st.firstB}  onChange={(e)=>handleChangeGrade(e, idx, 10)} /></td>

                 <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 20)} className="form-control" id={idx} name='firstC' value={st.firstC}  onChange={(e)=>handleChangeGrade(e, idx, 20)} /></td>

                 <td><input type="text" pattern='[0-9]' onFocus={(e)=>e.target.select()} min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 20)} className="form-control" id={idx} name='firstD' value={st.firstD}  onChange={(e)=>handleChangeGrade(e, idx, 20)} /></td>





                 <td><input type="text" onBlur={(e)=>handleChangeGrade(e, idx, 40)} onFocus={(e)=>e.target.select()} className="form-control" pattern='[0-9]' min={0} step={1} id={idx} name='exam' value={st.exam}  onChange={(e)=>handleChangeGrade(e, idx, 40)} /></td>

                 <td>{st.total}</td>
                 <td><b>{st.grade}</b></td>
                 <td style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.remark}</b></td>
                 
                 
                 <td>

                 <select name="absent" value={st.absent} style={{width:'70px'}} className="form-control" onChange={(e)=>handleChangeScore(e, idx)}   >
                 <option  value='No'>No</option>
                 <option  value='Yes'>Yes</option>
                 </select>

                 </td>

             </tr>):[]}
         </tbody> 
     </table>
</div>	 </div>   
<div style={{color:'red'}}>{errors.subject && errors.subject}</div>
<div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
<div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
    </div>			
    </div>       
    </div>:

<div className="col-md-12">

<div className="card z-depth-0">

<div className="card-block">

<div className="row">
<div className='col-md-12 '>
    <div className='pull-right'>
        
    <b>{result.subject.length!==0&&result.studentClass.length!==0&&result.studentClass.label.toUpperCase()+' -'+result.subject.label.toUpperCase()}</b>

    </div></div>
<div className="table-responsive">
 <table className="table table-bordered">
     <thead>

     <tr>
         <th rowSpan={2}>SN</th>
             <th >Student Details</th>
             <th>Keynote</th> 
             <th rowSpan={2}>Absent</th> 
         </tr>
     </thead>
     <tbody>{studentList.length!==0?studentList.map((st, idx)=> 
         <tr key={idx}>
<td>{idx+1}</td>
             <td style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.admissionNumber} - {st.studentName}</b> {st.termination==='Yes'?<div><br/><span className="pcoded-badge label label-danger">Terminated on {longDate(st.termination_date)} {st.termination_remark}</span></div>:''} 
             
             </td>
             
    <td>
          <textarea name='comment' placeholder='Keynote' value={st.comment} className="form-control" onChange={(e)=>handleChangeScore(e, idx)} rows={3}>{st.comment}
          
          </textarea> 
</td>
             
 <td style={{maxWidth:'50px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>

<select name="absent" value={st.absent} style={{width:'70px'}} className="form-control" onChange={(e)=>handleChangeScore(e, idx)}   >
<option  value='No'>No</option>
<option  value='Yes'>Yes</option>
</select>

</td> 


         </tr>):[]}
     </tbody> 
 </table>
</div>	 </div>   
<div style={{color:'red'}}>{errors.subject && errors.subject}</div>
<div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
<div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
</div>			
</div>       
</div>}

</div>}


     <button onClick={activeStep === steps.length ? handleSubmit: handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Update Result </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }

                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>

{/* 

           {studentList.length>0?     <div className="row  ">
								<div className="col-sm-6">
	<div className="card z-depth-0">
		<div className="card-block">
		<fieldset>
			<div className="row">
									
			<section className="col-md-12 ">
			<div className="well well-sm alert alert-success border-success background-success ">
					<h4 className="alert-heading ">Download Student File</h4>
					
<p><span >The first line in downloaded csv file should remain as it is. Please do not change the order of columns.</span><br/>
<span >The correct column order is (<b>
    Result Code, Admission Number, Student Name, CA, Exams, Absent </b>)</span> <span >&amp; you must follow this.</span></p>
					<hr className="simple" /><div className="text-align-left">
						
                    <form method='POST' action={ServerUrl+'/csvSample_controller/result'} target='_blank'>
        <input type='hidden' name='subjectCode' value ={result.subject.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='className' value ={result.examtype.label +' '+ result.studentClass.label+' - '+result.subject.label} />
                <input type='hidden' name='orderBy' value ={result.orderBy.value} />
                
                <input type='hidden' name='jwt' value ={Token} />
               
        <button type="submit" className="display-none"  id="exportExcel"> </button>
       
     <button type="button" onClick={DownloadStudentList} className="btn btn-sm btn-inverse"><i className="fa fa-download"></i> Download File</button>
        </form>
        
					</div>
				</div>					
				</section>
										</div>
										
				</fieldset>
				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-6">
	<div className="card z-depth-0">
	
		<div className="card-block">


		<div className="alert alert-success border-success">
						<fieldset className="well well-sm">
                        <h4 className="alert-heading ">Upload file with .csv extension</h4>
							<section>
                            <div className="form-group">
							
                    <input id="studentCsv"  onChange={handleFile('studentCsv')} className="form-control" name="studentCsv" type="file" accept=".csv" /> 
						<div className="note" style={{color:'red'}}>
			{csvFile.fileName}
		</div>	
										</div>          
                                        
							</section>
						</fieldset></div>
        <footer>
            <button type="button" id="submit" onClick={handleSubmitFile} className="btn  btn-primary pull-right"><i className="fa fa-save"></i> Upload Student Result</button>
        </footer>

	</div>

</div>

</div></div>:''}
 */}

                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ResultEntry) 