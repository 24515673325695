import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token,  config, API_URL} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getAge, getResultHead, getPosition, sort, getCAPoint, getBritishDate, getNumWorkDays} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'
import { useSelector } from 'react-redux'


const PrintClassResult =()=> {
    const [errors, setErrors] = useState({});
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [examtype, setExamtype]=useState([])
    const [remark, setRemark]=useState([])
    const [classTeacher, setClassTeacher] = useState([]);
    const [settings, setSettings]=useState([])
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
const [resultSession, setResultSession]=useState([])
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [assesmentGrade, setAssesmentGrade]=useState([])
const staff  = useSelector(state => state.rootReducer.staff);
 const [student, setStudent] = useState([]);
 const [studentList, setStudentList] = useState([]);
const [sqlQuery, setSqlQuery] = useState([]);  
const [subCategory, setSubCategory]=useState([])

const [thirdAssesment, setThirdAssesment]=useState([])
const [mainCategory, setMainCategory]=useState([])
const [musicTemplate, setMusicTemplate]=useState([])
const [disabledResult, setDisabledResult]=useState([])
const [studentAssessment, setStudentAssessment]=useState([])

const [categoryGroup, setCategoryGroup]=useState([])
const [onlyCategoryThird, setOnlyCategoryThird]=useState([])
const [studentAssessmentThird, setStudentAssessmentThird]=useState([])
const [studentAssessmentMusic, setStudentAssessmentMusic]=useState([])
const [assessment, setAssessment]=useState([])
const [grade, setGrade] = useState([])

const [grademark, setGrademark]=useState([])
const [grademarks, setGrademarks]=useState([])
    const [scores, setScores]=useState([])
    const [onlyCategory, setOnlyCategory]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [remarkResults, setRemarkResults] = useState([])


    const [allStudents, setAllStudents] = useState([]);
    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        student:[],
        resultHead:'Secondary',
        orderBy:'',
        session:[]
    })

    const [category, setCategory]=useState([])
    const [attendance, setAttendance]=useState([])
    const [atResult, setAtResult]=useState({
        attendanceStart:'',
        attendanceEnd:'',
        nextTermBegin:'',
        nextTermBeginDay:'',
        totalOpen:0
    })

   // var rate = [...Array(5).keys()].map(i =>  i+1);

    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(result));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(values.studentClass.length===0){
            errors.studentClass ='Please select class';
        }   
        if(values.examtype.length===0){
            errors.examtype ='Please select subject';
        } 

        /* if(values.studentClass.classtype!=='Primary'){
            if(values.session.length===0){
                errors.session ='Please select session ';
            }
        } */
        if(values.session.length===0){
            errors.session ='Please select session ';
        }
        if(values.orderBy.length===0){
            errors.orderBy ='Please select order ';
        }

        return errors;
        }
        

        const fetchMusicTemplate =()=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetch_controller/tbl_music_template';
            axios.post(url, fd, config)
            .then(result =>setMusicTemplate(result.data))
        }

        
        const fetchAssessment =()=>{
       
            let url = API_URL+'/exam/result/session/all';
            axios.get(url,  config).then(result=>{
    
                if(result.data.length!==0){
    
                    try{
    
                    
                    let settings = result.data.assessment;
                    let standard = JSON.parse(settings[0].assessment)
                    let cat = JSON.parse(settings[0].category)
                    let config = JSON.parse(result.data.settings[0].settings)
                    setCategory(cat.sort(sort))
                    setAssessment(standard.sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1))

                    setSettings(config[0])
    
                    var assesmentGrad = settings[0].assesmentGrade
    
                    setAssesmentGrade(JSON.parse(assesmentGrad).sort((a, b) =>(a.rateKey > b.rateKey) ? 1 : -1))
                    
                    
    
                }catch(e){
                        
                }
                }
                
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
            }

            
  

                 
    const fetchReMark =()=>{
       
        let url = API_URL+'/exam/result/examtype';
        axios.get(url,  config).then(result=>{
            if(result.data.length!==0){

                let settings = result.data.grademark;
                let grad = settings[0].grade
                let gradeRemar = settings[0].gradeRemark

                let gradeMar = settings[0].gradeMark
               

                setExamtype(result.data.examType)
                setRemark(JSON.parse(gradeRemar).sort((a, b) =>(a.remarkName > b.remarkName) ? 1 : -1))
                setGrademark(JSON.parse(gradeMar).sort((a, b) =>(a.classtype > b.classtype) ? 1 : -1))
                setGrade(JSON.parse(grad).sort(sort))

                }
        
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }
    

    const fetchMainCategory =()=>{
        var sql ="SELECT mainCategory, category, assessment, assesmentGrade from tbl_cummulative_standard_settings where ID="+1;
const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0){
        try{
    var cat = JSON.parse(result.data[0].category)
    var assessmen = result.data[0].assessment
    var assesmentGrad = result.data[0].assesmentGrade
    var mainCategory = JSON.parse(result.data[0].mainCategory) 


    const getCatName =(code, field)=>{
        const res =  mainCategory&&mainCategory.filter(item=>item.code===code)
        const answer = res.length!==0?res[0][field]:''
        return String(answer)
    }

    var response = []
    for (var i = 0; i < cat.length; i++){
        response.push(
            {
                code:cat[i].code,
                categoryName:cat[i].categoryName,
                mainCategoryCode:cat[i].mainCategory,
                mainCategory:getCatName(cat[i].mainCategory, 'categoryName'),
               group:getCatName(cat[i].mainCategory, 'group'),

            })
    }


   setMainCategory(response)
    //setSubCategory(JSON.parse(cat))
    setThirdAssesment(JSON.parse(assessmen).sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1))
    //setAssesmentGrade(JSON.parse(assesmentGrad).sort((a, b) =>(a.rateKey > b.rateKey) ? 1 : -1))

        }catch(e){

        }
    }
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}

    





const handleSelectStudent =(option, action)=>{     
    setResult({...result, [action.name]:option});
}

    const handleSelect =(option, action)=>{     
        setAllStudents([])
        setScores([])
        setStudentAssessment([])
        setStudentAssessmentMusic([])
        setOnlyCategory([])
        setErrors({...errors, [action.name]:''})
        if(action.name==='studentClass'){

            setResult({...result, [action.name]:option, examtype:[], student:[], orderBy:[], session:[]});
           
            fetchStudentList(option.value, result.examtype.value)
            fetchClassSubject(option.value, result.examtype.value)
            fetchClassTeaher(option.classID, option.value)
            fetchGradeMark(option.classtype)
        }else if(action.name==='examtype'){
            setResult({...result, [action.name]:option, orderBy:[]});
            fetchStudentList(result.studentClass.value, option.value)
            fetchClassSubject(result.studentClass.value, option.value)
            fetchExamAttendance(option.value)
        }
    }



    const fetchGradeMark =(classType)=>{
        if(grademark.length!==0){
            var grade = grademark&&grademark.filter(item=>item.classtype===classType)

           
            setGrademark(grade.sort((a, b) =>(parseFloat(b.maximum) > parseFloat(a.maximum)) ? 1 : -1))

        }else{
            setGrademark([])
        }             
    }

    const handleSelectType =(option, action)=>{  
        
         
        setResult({...result, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        fetchStatistics()  
        getAttendance()
     

    }


    const fetchStatistics =()=>{
        setNotice({...notice, isLoading: true}) 
         const fd = new FormData();
     fd.append("exam", result.examtype.value);
     fd.append("sectionID", result.studentClass.value);
    fd.append("sql", sqlQuery);
    let url = API_URL+'/exam/result/statistics';
    axios.post(url, fd, config).then(result=>{
         if(result.data.data.length!==0){
    setStudent(result.data.data)
    setRemarkResults(result.data.remarks)
        }else{
            setStudent([])
            setRemarkResults([]) 
        }  
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    }) 
    }

    const fetchAllResultSession =()=>{

        var sql ="SELECT r.code, r.firstTerm, r.secondTerm, r.thirdTerm, r.sessionCode, s.startYear from tbl_result_session r, tbl_school_session s where r.sessionCode =s.id " ;

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            setResultSession(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }

   

    
    
    const fetchStudentList =async(section, exam)=>{
        setNotice({...notice, isLoading: true}) 
    let url = API_URL+'/student/disabled/'+section+'/'+exam;
            await   axios.get(url,  config).then(result=>{

        if(result.data.data.length!==0){

            setStudentList(result.data.data)
            let studentList = result.data.studentList
            let std = studentList.length!==0?studentList[0].studentList:[]
            const options = std.length!==0? JSON.parse(std):[]


            if(options.length!==0){
                const rs = options.map((item)=>item.studentCode)
                setDisabledResult(rs)
            }else{
                
        setDisabledResult([])
            }

            }else{
                setStudentList([]) 
            } 
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
       
    })
    }

    



        const fetchClassSubject =(sectionID, exam)=>{

            let url = API_URL+'/exam/subject_list/'+sectionID+'/'+exam;
            axios.get(url, config).then(response=>{
                if(response.data.data.length!==0){
                var subj = response.data.data;
    

                //use this to get avearege

                
                let sqlString = subj.map(data=>"sum(case when e.subjectCode = '"+data.code+"'  then e.total else 0 end) AS '"+data.subjectName+"'")
            
                
             let final =   'SELECT  rank() over (order by  CAST(sum(e.total) AS decimal(10,2)) desc) position, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.code, s.passport, s.gender, s.house, s.dateOfBirth, s.admissionNumber, s.registerNumber, s.studentName, sum(case when e.total >0 and e.absent="No" then e.total else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, e.maxMark, e.absent, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode '
           
            setSqlQuery(final)
               
            }else{
                setSqlQuery([])
            }
            })

        }

    
     

       
        const getAssessment = (code, field) =>{        
       
            const reply = assessment&&assessment.filter(list=>list.code===code);
            const answer = reply.length!==0?reply[0][field]:'';
            return  String(answer)
            }

            const getAssessmentMusic = (code, field) =>{        
       
                const reply = musicTemplate&&musicTemplate.filter(list=>list.code===code);
                const answer = reply.length!==0?reply[0][field]:'';
                return  String(answer)
                }

            const getCategory=(code)=>{
                const result = category && category.filter(list=>list.code===code);
              const answer = result.map((c)=>c.categoryName);
              return  String(answer)
            }
 

            const getAccessmentGrade=(table, studentCode, examCode, assessmentCode, term)=>{
                const result = table.filter(list=>
                    list.studentCode===studentCode && 
                    list.examCode === examCode &&
                    list.assessmentCode === assessmentCode);
              const answer = result.length!==0?result[0][term]:'';
              return  answer
            }


const handleSelectSession =(option, action)=>{     
    setResult({...result, [action.name]:option});
    setErrors({...errors, [action.name]:''})
    fetchAllAssessment(option.firstTerm, option.secondTerm, option.thirdTerm, result.studentClass.value)
    fetchMusicAssessment(option.firstTerm, option.secondTerm, option.thirdTerm, result.studentClass.value)
}

const fetchAllAssessment =(firstTerm, secondTerm, thirdTerm, section)=>{
     setNotice({...notice, isLoading: true}) 


     var sql ="SELECT   r.assessmentCode, r.grade, (case when r.examCode ='"+firstTerm+"' then r.grade else 0 end) as first, (case when r.examCode ='"+secondTerm+"' then r.grade else 0 end) as second, (case when r.examCode ='"+thirdTerm+"' then r.grade else 0 end) as third, studentCode, examCode from tbl_assessment_result r where sectionID ='"+section+"'" ;

     

  const fd = new FormData();
 fd.append("sql", sql);
     fd.append('jwt', Token);
 let url = ServerUrl+'/fetchBySql_controller';
 axios.post(url, fd, config).then(response=>{
      if(response.data.length!==0){

       let option = response.data
       
        const onlyStudent =	option.map(e=>e['studentCode'])
            .map((e,i,final)=>final.indexOf(e)===i&&i)
            .filter(e=>option[e])
            .map(e=>option[e]) 

            const onlyAssessment =	option.map(e=>e['assessmentCode'])
            .map((e,i,final)=>final.indexOf(e)===i&&i)
            .filter(e=>option[e])
            .map(e=>option[e]) 

                                let list = [];
                
             for (var k = 0; k< onlyStudent.length;  k++) {

    for (var i = 0; i< onlyAssessment.length;  i++) {
        list.push(
            {
                studentCode:onlyStudent[k].studentCode,
                first:getAccessmentGrade(option, onlyStudent[k].studentCode, firstTerm, onlyAssessment[i].assessmentCode, 'first'),
                
                code:onlyAssessment[i].assessmentCode,
                second:getAccessmentGrade(option, onlyStudent[k].studentCode, secondTerm, onlyAssessment[i].assessmentCode, 'second'),
                third:getAccessmentGrade(option, onlyStudent[k].studentCode, thirdTerm, onlyAssessment[i].assessmentCode, 'third'),
                grade:getAccessmentGrade(option, onlyStudent[k].studentCode, result.examtype.value, onlyAssessment[i].assessmentCode, 'grade'),
                assessmentName:getAssessment(onlyAssessment[i].assessmentCode, 'assesmentName'),
            type:getCategory(getAssessment(onlyAssessment[i].assessmentCode, 'categoryCode')),
            });
            }
        }

            setStudentAssessment(list)
            const onlyCat =	list.map(e=>e['type'])
            .map((e,i,final)=>final.indexOf(e)===i&&i)
            .filter(e=>list[e])
            .map(e=>list[e]) 

            
            setOnlyCategory(onlyCat)
     }else{
        setStudentAssessment([])
     }
 })
 .catch((error)=>{
 Alerts('Error!', 'danger', error.message)
 }).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
 }




 const fetchMusicAssessment =(firstTerm, secondTerm, thirdTerm, section)=>{
    setNotice({...notice, isLoading: true}) 

    
    var sql ="SELECT   r.assessmentCode, r.grade, (case when r.examCode ='"+firstTerm+"' then r.grade else 0 end) as first, (case when r.examCode ='"+secondTerm+"' then r.grade else 0 end) as second, (case when r.examCode ='"+thirdTerm+"' then r.grade else 0 end) as third, studentCode, examCode from tbl_music_result r where sectionID ='"+section+"'" ;


 const fd = new FormData();
fd.append("sql", sql);
    fd.append('jwt', Token);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    
     if(response.data.length!==0){

        let option = response.data
       
        const onlyStudent =	option.map(e=>e['studentCode'])
            .map((e,i,final)=>final.indexOf(e)===i&&i)
            .filter(e=>option[e])
            .map(e=>option[e]) 

            const onlyAssessment =	option.map(e=>e['assessmentCode'])
            .map((e,i,final)=>final.indexOf(e)===i&&i)
            .filter(e=>option[e])
            .map(e=>option[e]) 

                                let list = [];


        for (var k = 0; k< onlyStudent.length;  k++) {

            for (var i = 0; i< onlyAssessment.length;  i++) {
                list.push(
                    {

                        studentCode:onlyStudent[k].studentCode,
                                        first:getAccessmentGrade(option, onlyStudent[k].studentCode, firstTerm, onlyAssessment[i].assessmentCode, 'first'),
                                        code:onlyAssessment[i].assessmentCode,
                                        second:getAccessmentGrade(option, onlyStudent[k].studentCode, secondTerm, onlyAssessment[i].assessmentCode, 'second'),
                                        third:getAccessmentGrade(option, onlyStudent[k].studentCode, thirdTerm, onlyAssessment[i].assessmentCode, 'third'),

                                        assessmentName:getAssessment(onlyAssessment[i].assessmentCode, 'assesmentName'),
                                        above_expectation:getAssessmentMusic(onlyAssessment[i].assessmentCode, 'above_expectation'),
                                        secure:getAssessmentMusic(onlyAssessment[i].assessmentCode, 'secure'),
                                        developing:getAssessmentMusic(onlyAssessment[i].assessmentCode, 'developing'),
                                        beginning:getAssessmentMusic(onlyAssessment[i].assessmentCode, 'beginning')

                    });
                    }
                }
   setStudentAssessmentMusic(list)
    }else{
       setStudentAssessmentMusic([])
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
   setNotice({...notice, isLoading: false})
   
})
}


const getCategoryCum=(code, field)=>{
    const reply = mainCategory&&mainCategory.filter(list=>list.code===code);
  const answer = reply.length!==0?reply[0][field]:'';
  return  String(answer)
}


const getAssessmentCum = (code, field) =>{        
       
    const reply = thirdAssesment&&thirdAssesment.filter(list=>list.code===code);
    const answer = reply.length!==0?reply[0][field]:'';
    return  String(answer)
    }

const fetchStudentAssessmentCummulative =()=>{
    // setNotice({...notice, isLoading: true}) 
     var sql ="SELECT  assessmentCode, grade, studentCode from tbl_cummulative_assessment_result where examCode ='"+result.examtype.value+"' and sectionID ='"+result.studentClass.value+"'" ;

  const fd = new FormData();
 fd.append("sql", sql);
     fd.append('jwt', Token);
 let url = ServerUrl+'/fetchBySql_controller';
 axios.post(url, fd, config).then(response=>{
      if(response.data.length!==0){

         var assess = response.data;
var list = [];
for (var i = 0; i< assess.length;  i++) {
 list.push(
     {  
        
        code:assess[i].assessmentCode,
         assessmentName:getAssessmentCum(assess[i].assessmentCode, 'assesmentName'),
         subCategory:getCategoryCum(getAssessmentCum(assess[i].assessmentCode, 'categoryCode'), "categoryName"),
         mainGroup:getCategoryCum(getAssessmentCum(assess[i].assessmentCode, 'categoryCode'), "group"),
         mainCategory:getCategoryCum(getAssessmentCum(assess[i].assessmentCode, 'categoryCode'), "mainCategory"),
         mainCategoryCode:getCategoryCum(getAssessmentCum(assess[i].assessmentCode, 'categoryCode'), "mainCategoryCode"),
         grade:assess[i].grade,
         studentCode:assess[i].studentCode
     });
     }


     const onlyCat =	list.map(e=>e['mainCategory'])
                 .map((e,i,final)=>final.indexOf(e)===i&&i)
                 .filter(e=>list[e])
                 .map(e=>list[e])

                 const subCat =	list.map(e=>e['subCategory'])
                 .map((e,i,final)=>final.indexOf(e)===i&&i)
                 .filter(e=>list[e])
                 .map(e=>list[e])

                 const group =	list.map(e=>e['mainGroup'])
                 .map((e,i,final)=>final.indexOf(e)===i&&i)
                 .filter(e=>list[e])
                 .map(e=>list[e])


   setOnlyCategoryThird(onlyCat)
   setSubCategory(subCat)
   setCategoryGroup(group)

 setStudentAssessmentThird(list)
     }else{
         setOnlyCategoryThird([])
 setStudentAssessmentThird([])
     }
 })
 .catch((error)=>{
 Alerts('Error!', 'danger', error.message)
 })
 }



        const fetchStudentAssessment =()=>{
           // setNotice({...notice, isLoading: true}) 
            var sql ="SELECT  assessmentCode, grade, studentCode from tbl_assessment_result where examCode ='"+result.examtype.value+"' and sectionID ='"+result.studentClass.value+"'" ;


         const fd = new FormData();
        fd.append("sql", sql);
            fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(response=>{
             if(response.data.length!==0){

                var assess = response.data;
    var list = [];
    for (var i = 0; i< assess.length;  i++) {
        list.push(
            {  code:assess[i].assessmentCode,
                assessmentName:getAssessment(assess[i].assessmentCode, 'assesmentName'),
                type:getCategory(getAssessment(assess[i].assessmentCode, 'categoryCode')),
                grade:assess[i].grade,
                studentCode:assess[i].studentCode
            });
            }

            const onlyCat =	list.map(e=>e['type'])
                        .map((e,i,final)=>final.indexOf(e)===i&&i)
                        .filter(e=>list[e])
                        .map(e=>list[e])

            setOnlyCategory(onlyCat)

        setStudentAssessment(list)
        
            }else{
                setOnlyCategory([])
        setStudentAssessment([])
            }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
        }
        

        const fetchResult =()=>{
            setNotice({...notice, isLoading: true}) 
            if(result.studentClass.classtype==='Primary'){
                fetchStudentAssessment() 
            }

            if(result.studentClass.classtype==='Nursery' && result.examtype.term==='THIRD TERM'){
               
                fetchStudentAssessmentCummulative()
            }
            var sql ="SELECT  s.subjectName, s.abbreviation, e.studentCode,  e.firstCA, e.absent, e.uniqueCode, e.position, e.secondCA, e.exam, e.total,  e.firstA, e.firstB, e.firstC, e.firstD, e.thirdA, e.thirdB, e.grade, e.remark, e.comment, e.teacher, e.totalAverage from tbl_exam_result e, tbl_subject s where e.subjectCode = s.code and e.examCode ='"+result.examtype.value+"' and e.sectionID ='"+result.studentClass.value+"' order by e.displayOrder ASC " ;

         const fd = new FormData();
        fd.append("sql", sql);
            fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(response=>{
             if(response.data.length!==0){
                
        setScores(response.data)

        if(result.student.length!==0 && studentList.length!==0){
            setAllStudents(studentList.filter(item=>item.code===result.student.value))
        }else{
            setAllStudents(studentList)
        }
        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
            }else{
                setScores([])
                Alerts('Info!', 'info', 'There are no schedule found for the selected exam')
            }  
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        }) 
        }

       
        
     



        const getGradeScore =(code)=>{
            const result = grade&&grade.filter(item=>item.code===code)
            return result.length!==0?result[0].gradeName:''
            }
        


            const fetchExamAttendance =(value)=>{
                let url = API_URL+'/exam/attendance/show/'+value
                axios.get(url, config).then(response=>{
                    
                    if(Array.isArray(response.data.data)&& response.data.data.length!==0){
                        setAtResult({...atResult, attendanceStart:response.data.data[0].startDate, 
                            attendanceEnd:response.data.data[0].endDate,
                        nextTermBegin:response.data.data[0].nextTermBegin,
                        nextTermBeginDay: response.data.data[0].nextTermBeginDay })
                       
                    }else{
                        setAtResult({...atResult, attendanceStart:'', attendanceEnd:'', nextTermBegin:'',  nextTermBeginDay:''  })
                    } 
                })
                .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
        
            })
        }

        const fetchAllHolidays =(holiday)=>{
            var total = parseFloat(getNumWorkDays(atResult.attendanceStart, atResult.attendanceEnd));
            if(Array.isArray(holiday)&& holiday.length!==0){
                setAtResult({...atResult, totalOpen:(total - Number(holiday[0].total))*2 })
                }else{

                    setAtResult({...atResult, totalOpen:total*2 })
                }
        }



               


        const getAttendance =()=>{
            let url = API_URL+'/student/attendance/statistics/'+atResult.attendanceStart+'/'+atResult.attendanceEnd
            axios.get(url, config).then(response=>{
                if(Array.isArray(response.data.data)&& response.data.data.length!==0){
                    fetchAllHolidays(response.data.holiday)
                    setAttendance(response.data.data)
                }else{
                    setAttendance([])
                } 
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }

          



    const getRemark =(code)=>{
        const ans = remark&&remark.filter(item=>item.code===code)
        return ans[0].remarkName
        }



         



            const fetchClassTeaher =(classID, sectionID)=>{
                var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
                setClassTeacher(newStaff)
            }


        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
           
                fetchResult()
            }
            },[errors])



     const handleStaffName=(cell)=>{
        if(cell!=='[]' && cell!=''){
        return JSON.parse(cell).map((itm, idx)=>{
            return <span  key={idx}> {' '+getStaffField(itm, 'staffName')}</span>
        } );
    }else{
        return ''
    } 
    }

    const handleStaffSignature=(cell)=>{
        if(cell!=='[]' && cell!=''){
        return JSON.parse(cell).map((itm, idx)=>{
            return <img id="viewPassport2" key={idx} className="img-100" style={{ maxHeight:'50px' }}  src={`${ImagesUrl}/staff/signature/${getStaffField(itm, 'signature')}`}   alt='' />
        } );
    }else{
        return ''
    } 
    }


       const getStaffField = (code, field) =>{   
    
        const result = staff&&staff.filter(list=>list.code===code);
        const answer = result.map((c)=>c[field] ); 
        return  String(answer);
       }


    
const roundNum =(num)=> (Math.round(parseFloat(num)*100)/100)


const getGrade =(score, field)=>{
   
const ans = grademark.filter(itm=>itm.classtype===result.studentClass.classtype&& roundNum(score) >= roundNum(itm.minimum) && roundNum(score) <= roundNum(itm.maximum));
    const remark = ans.length!==0? getRemark(ans[0].gradeMark):''
    const grade = ans.length!==0? getGradeScore(ans[0].gradeCode):''
   

return field ==='grade'?String(grade):String(remark)
}



const getScores =(code)=>{
    let scr =  [...scores];
    const rs = scr.filter(item=>item.studentCode===code)
    return rs.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1);

}

const getStudent =(code)=>{
    let scr =  [...student];
    const rs = scr.filter(item=>item.code===code)
    return rs[0];

}


const getAt =(code)=>{
    let scr =  [...attendance];
   const rs = scr.filter(item=>item.code===code)

   if(rs.length!==0){
       return rs[0]
   }else{
    return {A:0, P:0}
   }
   

}


const getData =(code)=>{
     let scr =  [...remarkResults];
    const rs = scr.filter(item=>item.studentCode===code)
    return rs.length!==0?rs[0]:{code:'', teacherRemark:'', headofSchool:'', teacherName:'', headRemark:'', teacherName:'', headName:'', resultHead:'', studentCode:''};

} 


const getStudentAssessment =(code)=>{
    let scr =  [...studentAssessment];
    const rs = scr.filter(item=>item.studentCode===code)
    
    return rs;

}
const getStudentAssessmentThird =(code)=>{
    let scr =  [...studentAssessmentThird];
    const rs = scr.filter(item=>item.studentCode===code)
    
    return rs;

}

const getStudentAssessmentMusic =(code)=>{
    let scr =  [...studentAssessmentMusic];
    const rs = scr.filter(item=>item.studentCode===code)
    return rs;

}


const colors = [  '#9b1919', '#302d93', '#00b051', '#f28148', '#fff900', '#8065a2', '#4cacc6', '#fff900', '#4cacc6' ]

const bodyColor = [ '#fdf1f1', '#e8e8f8', '#f3fff8', '#feeee7', '#fffedd', '#e5dfec', '#4cacc6', '#fffedd', '#daeef3' ]





useEffect(()=>{
    fetchReMark()
    fetchAssessment()
    fetchMusicTemplate()
    fetchAllResultSession() 
    fetchMainCategory()
},[]);

 

        return (
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="View Result">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Class Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Class Result</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">
       


                    <section className="col-md-6">
                    <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
         <Select  options={
                         schoolClass&&schoolClass.map((list, idx)=> {
                            return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                            }) }
                          })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
            </div>
     
     </section>   

     <section className="col-md-6">
     <div className="form-group">
          <label> Exam Type <span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam, term: list.term }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

 
             </div>
     
     </section>

     
     

     <section className="col-md-6">
           <div className="form-group">
         <label> Order By <span style={{color:'red'}}>*</span></label>
         <Select  options={[
             {value:'order by s.studentName ASC', label:'Order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelectType} className={errors.orderBy ? 'form-control form-control-danger' : ''} name="orderBy" value={result.orderBy}  /> 
<span style={{color:'red'}}>{errors.orderBy && errors.orderBy}</span>

            </div>
     
     </section>

     <section className="col-md-6">
                <div className="form-group">
         <label> Session <span style={{color:'red'}}>*</span></label>
         <Select  options={resultSession&&resultSession.map((list, id)=>{
             return {key:id, value:list.code, label:list.startYear, firstTerm:list.firstTerm, secondTerm:list.secondTerm, thirdTerm:list.thirdTerm}
         })}    

 onChange={handleSelectSession} className={errors.session ? 'form-control form-control-danger' : ''} name="session" value={result.session} id='session'  />

 <span style={{color:'red'}}>{errors.session && errors.session}</span>
            </div>
            </section>
            
     


     <section className="col-md-6">
     <div className="form-group">
						<label>Student</label>
						<Select  options={
                           studentList&&studentList.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelectStudent} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={result.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
               </section> 

				</div>	


	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Result</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	

{allStudents&&allStudents.map((std, index)=>

<div key={index}>


{scores.length!==0?
<div className="card z-depth-0">
		
       {result.examtype.term==='MOCK EXAM'&&getResultHead(result.studentClass.classtype)!=='Pre-Nursery'?
       <div>

          
       <div className="card-block">


                       <div className="row" >

<table className="table  table-full-width">
       <tbody>
                <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        

                 
                        <h4 style={{textAlign:'center'}}><b>{schoolInfo.schoolName}</b></h4>
                        <h6 style={{textAlign:'center'}}><b>Motto: {schoolInfo.schoolMotto}</b></h6>
                        <div style={{textAlign:'center'}}>  {schoolInfo.address}  </div>
                        <div style={{textAlign:'center'}}> {schoolInfo.mobile}, {schoolInfo.altMobile}, {schoolInfo.email} </div>
                        
                        <div style={{textAlign:'center'}}>  Website: {schoolInfo.website}  </div>
                             </td>

 <td > <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  /></td>
                </tr>
            </tbody>
       </table>

</div> 

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b> {result.examtype.label} RESULT</b></u></h5>
        </div></div><br/>

<div className="row">
       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
       <tbody>
       <tr>
                <td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>         
                 <td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
                 <td >CLASS: <b> {result.studentClass.label} </b></td>
                </tr>

                <tr>
                    
                <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
                <td>TOTAL TIMES SCHOOL OPENED: <b> {atResult.totalOpen} </b></td>
                <td>TOTAL TIMES PRESENT: <b>{getAt(std.code).P} </b></td>
                <td >NO. IN CLASS: <b>{studentList.length}</b></td>
               
                </tr>

                <tr>
                    
                <td >MARKS OBTAINED: <b>{parseInt(getStudent(std.code).obtained).toFixed(2)} </b></td>
               <td >OUT OF: <b>{getStudent(std.code).obtainable}</b></td>
                <td >STUDENT'S AVERAGE: <b> {getStudent(std.code).average}  </b></td>
                <td >FINAL GRADE: <b> {getGrade(parseInt(getStudent(std.code).average), 'grade')+' ['+getGrade(parseInt(getStudent(std.code).average), 'remark')+']'}  </b></td>
                </tr>


              {/*   <tr>         
                <td >CLOSING DATE: <b>{getBritishDate(atResult.attendanceEnd)}</b></td>
                <td >RESUMPTION DATE: <b>{getBritishDate(atResult.nextTermBeginDay)}</b></td>
                </tr> */}


            </tbody>
       </table></div>
       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
        

            <thead>
                <tr>
                    <th >Subjects</th>
                   
                    
                    <th >Exam (100)</th>
                    <th >Total (100%)</th>
                    <th >Grade</th>
                    <th >Remark</th>
                </tr>
            </thead>
            <tbody>
                {getScores(std.code).map((st, idx)=> st.absent!=='Yes' && st.total>0?
                <tr key={idx}>
                
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>
                   
                    <td>{st.exam==='0'?'-':st.exam}</td>
                    <td>{st.total==='0'?'-':st.total}</td>
                    <td>{st.grade}</td>
                    <td>{st.remark}</td>
                
            </tr>
                :[])} 
            </tbody> 
        </table>
 </div>	 
       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
         <tbody>
             <tr>
             <td><b >KEY TO GRADINGS</b></td>
               
             </tr>
             <tr>
                 <td><b>{grademark.map((data, index)=><i key={index}>{getGradeScore(data.gradeCode) + ' =' +data.minimum + ' - ' + data.maximum+ ' [' + '  ' +getRemark(data.gradeMark) + '],  '}</i>)} </b></td>
                 
             </tr>
         </tbody>
     </table>

   
     {studentAssessment.length!==0?
 <div className="row">
 <div className="col-md-12 table-responsive">
<table className='table table-bordered '>
       <tbody>
                <tr>
                    <td ><table className="table">
                        <thead>
                        <tr>
                            <th>AFFECTIVE DOMAINS</th>
                            <th>RATING</th>
                        </tr></thead>
                        <tbody>

                            { getStudentAssessment(std.code).map((data, index)=> data.type.toUpperCase()==='AFFECTIVE DOMAINS'?
                            <tr key={index}>
                                <td>{data.assessmentName}</td>
                                <td>{data.grade}</td>
                            </tr>:[])}
                        </tbody>
                    </table>
                    </td>
                    <td ><table className="table">
                        <thead>
                        <tr>
                            <th>PSYCHOMOTOR SKILLS</th>
                            <th>RATING</th>
                        </tr></thead>
                        <tbody>
                        {getStudentAssessment(std.code).map((data, index)=> data.type.toUpperCase()==='PYSCHOMOTOR SKILLS'?
                            <tr key={index}>
                                <td>{data.assessmentName}</td>
                                <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{data.grade}</td>
                            </tr>:[])}
                        </tbody>
                    </table>
                    
                    
                    <table className="table">
                        <thead>
                        <tr>
                            <th colSpan={2}>KEY TO RATINGS</th>
                        </tr></thead>
                        <tbody>
                        {assesmentGrade&&assesmentGrade.map((data, index)=> 
                            <tr key={index}>
                                <td>{data.rateKey}</td>
                                <td>{data.assesmentGrade}</td>
                            </tr>)}
                        </tbody>
                    </table>

                    </td>
                </tr>
                
            </tbody>
       </table></div></div>:''}
<table className='table table-bordered'>
        <tbody>
            <tr>
                <td  style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>CLASS TEACHER’S COMMENT(S) </b></td>
                <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>
                
            </tr>
            <tr>
            <td  style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>CLASS TEACHER’SNAME/SIGNATURE </b></td>

            <td style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>
            <td style={{maxWidth:'5px' }}> {handleStaffSignature(getData(std.code).teacherName)}</td>
                
            </tr>
        </tbody>
    </table>
    {/* <table className='table table-bordered'>
        <tbody>
            <tr>
                <td style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} ><b>HEAD TEACHER’S COMMENT </b></td>

            <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>

            </tr>
            <tr>
          <td style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>HEAD TEACHER’S NAME/SIGNATURE </b></td>
                <td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofPrimary, 'staffName')}</td>


               <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofPrimary, 'signature')}`}   alt='' /></td>
                
            </tr>
        </tbody>
    </table> */}

    <table className='table table-bordered'>
        <tbody>
            <tr>
                <td style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} ><b>HEAD OF SCHOOL'S COMMENT </b></td>

            <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>

            </tr>
            <tr>
          <td style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>HEAD OF SCHOOL'S NAME/SIGNATURE </b></td>
                <td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofSchool, 'staffName')}</td>


               <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofSchool, 'signature')}`}   alt='' /></td>
                
            </tr>
        </tbody>
    </table>
{/* 


    {getResultHead(result.studentClass.classtype)==='Secondary'?
    <table className='table table-bordered'>
        <tbody>
            <tr>
                <td style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} ><b>PRINCIPAL’S COMMENT </b></td>

            <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>

            </tr>
            <tr>
          <td style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>PRINCIPAL’S NAME/SIGNATURE </b></td>
                <td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofSchool, 'staffName')}</td>


               <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofSchool, 'signature')}`}   alt='' /></td>
                
            </tr>
        </tbody>
    </table>:

<table className='table table-bordered'>
<tbody>
    <tr>
        <td style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} ><b>HEAD TEACHER’S COMMENT </b></td>

    <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>

    </tr>
    <tr>
  <td style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>HEAD TEACHER’S NAME/SIGNATURE </b></td>
        <td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofPrimary, 'staffName')}</td>


       <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofPrimary, 'signature')}`}   alt='' /></td>
        
    </tr>
</tbody>
</table>
} */}



    {/* 
    {settings.principalSign==='enabled'?
    <table className='table table-bordered'>
        <tbody>
            <tr>
                <td style={{maxWidth:'10px' }}><b>Principal </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(result.headofSchool, 'staffName')}</td>
                
                <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(result.headofSchool, 'signature')}`}   alt={getStaffField(result.headofSchool, 'staffName')} /></td>
            </tr>
        </tbody>
    </table>:''} */}
    
    <table className="table table-bordered  table-condensed ">
       <tbody>
                <tr>
                    <td >TERM STARTS: <b>{getBritishDate(atResult.attendanceStart)}</b></td>
                    
                    
                 <td >TERM ENDS: <b> {getBritishDate(atResult.attendanceEnd)} </b></td>
                    
                   
                 {getResultHead(result.studentClass.classtype)==='Secondary'?
                 <td >NEXT TERM BEGINS<br/> BOARDERS: <b>{getBritishDate(atResult.nextTermBegin)} </b><br/> DAY STUDENTS: <b>{getBritishDate(atResult.nextTermBeginDay)} </b></td>

                 :
<td >NEXT TERM BEGINS: <b>{getBritishDate(atResult.nextTermBeginDay)} </b></td>

}
                </tr>
                

            </tbody>
       </table> 
 </div>
 </div> 
 
 <div className="row no-print">
        <div className="col-md-12 pull-right">
        <form method='POST' action={ServerUrl+'/printout/mock_result.php?token='+userToken} target='_blank'>

        
        <input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 


                
<input type='hidden' name='studentAssessmentThird' value ={JSON.stringify(studentAssessmentThird, null, 2)} />
<input type='hidden' name='subCategory' value ={JSON.stringify(subCategory, null, 2)} />
<input type='hidden' name='onlyCategoryThird' value ={JSON.stringify(onlyCategoryThird, null, 2)} />
<input type='hidden' name='examTerm' value ={result.examtype.term} />
<input type='hidden' name='categoryGroup' value ={JSON.stringify(categoryGroup, null, 2)} />

                <input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
                <input type='hidden' name='studentAssessmentMusic' value ={JSON.stringify(studentAssessmentMusic, null, 2)} />
                
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                
                <input type='hidden' name='totalOpen' value ={atResult.totalOpen} />
                <input type='hidden' name='attendanceEnd' value ={getBritishDate(atResult.attendanceEnd)} />
                <input type='hidden' name='attendanceStart' value ={getBritishDate(atResult.attendanceStart)} />
                <input type='hidden' name='nextTermBegin' value ={getBritishDate(atResult.nextTermBegin)} />
                <input type='hidden' name='nextTermBeginDay' value ={getBritishDate(atResult.nextTermBeginDay)} />
                <input type='hidden' name='resultHeadP' value ={result.studentClass.classtype} />
                <input type='hidden' name='settings' value ={JSON.stringify(settings, null, 2)} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                
                <input type='hidden' name='resultHead' value ={getPosition(result.studentClass.classtype)} />
                <input type='hidden' name='assesmentGrade' value ={JSON.stringify(assesmentGrade, null, 2)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
                <input type='hidden' name='studentList' value ={JSON.stringify(allStudents, null, 2)} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />
                 


                <input type='hidden' name='jwtToken' value ={Token} />
              
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div></div>:

result.examtype.term!=='MOCK EXAM'&&getResultHead(result.studentClass.classtype)==='Nursery'?
       
    <div>    
    


{/* Third term report ends here */}

{result.studentClass.classtype==='Nursery' && result.examtype.term==='THIRD TERM' && studentAssessmentThird.length!==0?
<div className="card-block">
	
                      
    {schoolInfo&&schoolInfo.map((st, id)=>
<div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}, E:{st.email}  </div>
<div style={{textAlign:'center'}}>W: {st.website} Telephone: {st.mobile}, {st.altMobile} </div>

</td>
</tr>
</tbody>
</table>

</div> 
)}


<div className="row">
<div className="col-md-12">
<h5 style={{textAlign:'center'}}><u><b> {result.examtype.label} PROGRESS REPORT</b></u></h5>
</div></div><br/>

<div className="row">
       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
<tbody>

<tr>
<td rowSpan={4}>
<img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  />
</td>
 <td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>         
<td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
</tr>


<tr>
<td >STATE: <b> {std.stateName} </b></td>
<td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>    
<td >GENDER: <b> {std.gender}</b></td>
</tr>


<tr>
<td>NO. TIMES SCHOOL OPENED: <b> {atResult.totalOpen} </b></td>
<td>NO. TIMES PRESENT: <b>{getAt(std.code).P} </b></td>
<td>NO. TIMES ABSENT: <b>{getAt(std.code).A} </b></td>
</tr>


<tr>
<td >CLASS: <b> {result.studentClass.label} </b></td>
<td >CLOSING DATE: <b>{getBritishDate(atResult.attendanceEnd)}</b></td>
<td >RESUMPTION DATE: <b>{getBritishDate(atResult.nextTermBeginDay)}</b></td>
</tr>
 
</tbody>
</table></div>

	

       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
   <thead>
   <th className='text-center bg-dark'><h4>Cummulative Progress Report</h4> </th>
   </thead>
   </table>
</div>


<div className="col-md-12">
   <div className="box">
<p> 
<b>{std.studentName.toUpperCase()}</b> has been evaluated in the following Areas of learning: Personal, Social & Emotional Development, communication and language, Physical development, Expressive art and design, Literacy, Numeracy and Understanding The world. The grading scale is listed below  to ascertain his/her attainment of the  developmental milestone for each learning goal. Please feel free to schedule a conference with the class teacher to  further discuss this evaluation.

</p>
</div>
       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
   <thead>
   <th className='text-center bg-primary'><h4>Developmental  Grading Scale</h4> </th>
   </thead>
<tbody>
<tr>
   <td><i className='fa fa-star text-warning'></i>
   – <b>Emerging:</b> Demonstrates difficulty with skills; requires constant teacher assistance     
   </td>
   </tr>

   <tr>
   <td><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i>– <b>Meets Expected:</b> Demonstrates progress towards mastery; requires some teacher assistance
   </td>
   </tr>

    <tr>
       
    <td><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i>
    
     – <b>Exceed Expected:</b> Demonstrates mastery at this time; requires minimal teacher assistance. 
    </td>
    </tr> 

</tbody>
</table></div>

<div className="col-md-12 table-responsive">

{categoryGroup&&categoryGroup.map((gp, key)=>
     <table className='table table-bordered ' key={key}>
       
          <thead>
               <tr>
                   <th ><h5>{gp.mainGroup}</h5> </th>
                   <th >Level of Learning</th>
               </tr>
           </thead> 
          
           {onlyCategoryThird&&onlyCategoryThird.map((data, index)=>data.mainGroup===gp.mainGroup?
           <tbody key={index}>
           <tr >
                             <th colSpan={2} style={{backgroundColor:`${colors[index]}`, color:'white'}} ><h4>{data.mainCategory}</h4></th>
                         </tr>
                         {subCategory&&subCategory.map((item, idx)=>data.mainCategory===item.mainCategory?
                        <> <tr key={idx}>
                             <th colSpan={2} style={{backgroundColor:`${colors[index]}`, color:'white'}} ><h6>{item.subCategory}</h6></th>
                         </tr>

           {getStudentAssessmentThird(std.code).map((list, id)=> list.subCategory===item.subCategory?
                         
                        <tr key={id} style={{backgroundColor:`${bodyColor[index]}`}} >
                               <td style={{maxWidth:'600px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{list.assessmentName}</td>
               <td>{[...Array(Number(list.grade)?Number(list.grade):0).keys()].map((_, id)=><i key={id} className='fa fa-star text-warning'></i>)}</td>
                           </tr>:[])}

                           </>:[])}
                           </tbody>:[]
                           
                           )}
       </table>)}
</div>

</div>


<div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
<tbody>
<tr className='text-center'>
<td style={{color:'blue'}}><b >GRADING:</b> <br/> <b>{grademark.map((data, index)=><span key={index}>{data.maximum + ' - ' + data.minimum + " " +getGradeScore(data.gradeCode)+ ",  "}</span>)} </b></td>
</tr>
</tbody>
</table>
<table className="table table-striped table-bordered ">


<thead>
<tr>
 <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SPECIFIC AREAS OF LEARNING</th>
 <th colSpan={3}> (PERCENTAGE SCORE)</th>
 <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Termly Exam (60%)</th>
 <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Total (100%)</th>
 <th rowSpan={2}>Grade</th>
         

</tr>
<tr>
 <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>1<sup>st</sup> CA 10%</th>
 <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2<sup>nd</sup> CA 10%</th>
 <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>CLASS PRESENTATION (20%)</th>


</tr>

</thead>
<tbody>

{getScores(std.code).map((st, idx)=> st.absent!=='Yes' && st.total>0?<>
<tr key={idx}>

 <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>

 <td>{st.firstCA==='0'?'-':st.firstCA}</td>
 <td>{st.secondCA==='0'?'-':st.secondCA}</td>
 <td>{st.thirdA==='0'?'-':st.thirdA}</td>
 <td>{st.exam==='0'?'-':st.exam}</td>
 <td>{st.total==='0'?'-':st.total}</td>
 <td>{st.grade}</td>
</tr>
<tr>
    <td className='vertical-tds' style={{backgroundColor:`${colors[idx]}`, color:'white'}} ><b>Keynote</b></td>
    <td colSpan={7} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap', backgroundColor:`${bodyColor[idx]}` }} >{st.comment}</td>

</tr></>
:[])} 
</tbody> 
</table>
</div>



<div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
<tbody>
<tr>
<td  style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>Class Teacher's Comment </b></td>
<td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>

</tr>
<tr>
<td  style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>NAME/SIGNATURE </b></td>

<td style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}

{handleStaffSignature(getData(std.code).teacherName)}

</td>

</tr>

<tr>
<td style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} ><b>Head of School's Comment </b></td>

<td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>

</tr>
<tr>
<td style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>NAME/SIGNATURE </b></td>

<td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofSchool, 'staffName')}

<img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofSchool, 'signature')}`}   alt='' />

</td>

</tr>


</tbody>
</table>
</div>
</div>

<div className="row no-print">
<div className="col-md-12 pull-right">
<form method='POST' action={ServerUrl+'/printout/result_pre.php?token='+userToken} target='_blank'>

<input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
<input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 

<input type='hidden' name='studentAssessmentThird' value ={JSON.stringify(studentAssessmentThird, null, 2)} />
<input type='hidden' name='subCategory' value ={JSON.stringify(subCategory, null, 2)} />
<input type='hidden' name='onlyCategoryThird' value ={JSON.stringify(onlyCategoryThird, null, 2)} />
<input type='hidden' name='examTerm' value ={result.examtype.term} />
<input type='hidden' name='categoryGroup' value ={JSON.stringify(categoryGroup, null, 2)} />


<input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
<input type='hidden' name='studentAssessmentMusic' value ={JSON.stringify(studentAssessmentMusic, null, 2)} />
<input type='hidden' name='totalStudent' value ={studentList.length} />
<input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
<input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
<input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />

<input type='hidden' name='totalOpen' value ={atResult.totalOpen} />
<input type='hidden' name='examPoint' value ={getCAPoint(result.studentClass.classtype).exam} />
<input type='hidden' name='caPoint' value ={getCAPoint(result.studentClass.classtype).ca} />
<input type='hidden' name='attendanceEnd' value ={getBritishDate(atResult.attendanceEnd)} />
<input type='hidden' name='attendanceStart' value ={getBritishDate(atResult.attendanceStart)} />
<input type='hidden' name='nextTermBegin' value ={getBritishDate(atResult.nextTermBegin)} />
<input type='hidden' name='nextTermBeginDay' value ={getBritishDate(atResult.nextTermBeginDay)} />
<input type='hidden' name='resultHeadP' value ={getResultHead(result.studentClass.classtype)} />
<input type='hidden' name='settings' value ={JSON.stringify(settings, null, 2)} />
<input type='hidden' name='classID' value ={result.studentClass.classID} />
<input type='hidden' name='sectionID' value ={result.studentClass.value} />
<input type='hidden' name='examCode' value ={result.examtype.value} />
<input type='hidden' name='finalGrade' value ={result.studentClass.classtype} />
<input type='hidden' name='resultHead' value ={getPosition(result.studentClass.classtype)} />
<input type='hidden' name='assesmentGrade' value ={JSON.stringify(assesmentGrade, null, 2)} />
<input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
<input type='hidden' name='studentList' value ={JSON.stringify(allStudents, null, 2)} />
<input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />

<input type='hidden' name='jwtToken' value ={Token} />
<button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>

</form>
</div></div>

</div>:


    <div className="card-block">
	
                      
    {schoolInfo&&schoolInfo.map((st, id)=>
<div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}, E:{st.email}  </div>
<div style={{textAlign:'center'}}>W: {st.website} Telephone: {st.mobile}, {st.altMobile} </div>

</td>
</tr>
</tbody>
</table>

</div> 
)}


<div className="row">
<div className="col-md-12">
<h5 style={{textAlign:'center'}}><u><b> {result.examtype.label} PROGRESS REPORT</b></u></h5>
</div></div><br/>

<div className="row">
       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
<tbody>

<tr>
<td rowSpan={4}>
<img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  />
</td>
 <td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>         
<td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
</tr>


<tr>
<td >STATE: <b> {std.stateName} </b></td>
<td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>    
<td >GENDER: <b> {std.gender}</b></td>
</tr>


<tr>
<td>NO. TIMES SCHOOL OPENED: <b> {atResult.totalOpen} </b></td>
<td>NO. TIMES PRESENT: <b>{getAt(std.code).P} </b></td>
<td>NO. TIMES ABSENT: <b>{getAt(std.code).A} </b></td>
</tr>


<tr>
<td >CLASS: <b> {result.studentClass.label} </b></td>
<td >CLOSING DATE: <b>{getBritishDate(atResult.attendanceEnd)}</b></td>
<td >RESUMPTION DATE: <b>{getBritishDate(atResult.nextTermBeginDay)}</b></td>
</tr>
 
</tbody>
</table></div>

	

       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
   <thead>
   <th className='text-center bg-dark'><h4>Early Years Termly Progress Report</h4> </th>
   </thead>
   </table>
</div>


<div className="col-md-12">
   <div className="box">
<p> 
<b>{std.studentName.toUpperCase()}</b> has been evaluated in the following areas of learning: Personal, Social & Emotional Development, communication and language, physical, Expressive art and design, Literacy, Numeracy and understanding The world. The grading scale is listed below. You will find three boxes to compare <b>{std.studentName.toUpperCase()}</b> improvement from First  term to Third Term . Please feel free to schedule a conference with the class teacher to  further discuss this evaluation 

</p>
</div>
       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
   <thead>
   <th className='text-center bg-primary'><h4>Developmental  Grading Scale</h4> </th>
   </thead>
<tbody>
<tr>
   <td><i className='fa fa-star text-warning'></i>
   – <b>Not yet within:</b> Demonstrates difficulty with skills; requires constant teacher assistance     
   </td>
   </tr>
   <tr>

   
   <td><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i>– <b>Fully meets expection:</b> Demonstrates progress towards mastery; requires some teacher assistance</td>
   </tr>
    <tr>
       
   
    <td><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i> – <b>Exceeds Expection:</b> Demonstrates mastery at this time; requires minimal teacher assistance. </td>
    </tr> <tr>
       
      
   <td><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i>
   – <b>Outstanding:</b> Demonstrates mastery at this
   </td>
</tr>

</tbody>
</table></div>

<div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
       
          <thead>
               <tr>
                   <th > </th>
                   <th >1 <sup>st</sup> Term</th>
                   <th >2 <sup>nd</sup> Term</th>
                   <th >3 <sup>rd</sup> Term</th>
               </tr>
           </thead> 
          
           {onlyCategory&&onlyCategory.map((data, index)=>
           <tbody key={index}>
           <tr >
                             <th colSpan={4} style={{backgroundColor:`${colors[index]}`, color:'white'}} ><h4>{data.type}</h4></th>
                         </tr>
           {getStudentAssessment(std.code).map((list, id)=> list.type===data.type?
                         
                        <tr key={id} style={{backgroundColor:`${bodyColor[index]}`}} >
                               <td style={{maxWidth:'600px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{list.assessmentName}</td>
               <td>{[...Array(Number(list.first)?Number(list.first):0).keys()].map((_, id)=><i key={id} className='fa fa-star text-warning'></i>)}</td>
               <td>{[...Array(Number(list.second)?Number(list.second):0).keys()].map((_, id)=><i key={id}  className='fa fa-star text-warning'></i>)}</td>
               <td>{[...Array(Number(list.third)?Number(list.third):0).keys()].map((_, id)=><i key={id}  className='fa fa-star text-warning'></i>)}</td>
                           </tr>:[])}
                           </tbody>
                           
                           )}
       </table>
</div>

</div>


<div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
<tbody>
<tr className='text-center'>
<td style={{color:'blue'}}><b >GRADING:</b> <br/> <b>{grademark.map((data, index)=><span key={index}>{data.maximum + ' - ' + data.minimum + " " +getGradeScore(data.gradeCode)+ ",  "}</span>)} </b></td>
</tr>
</tbody>
</table>
<table className="table table-striped table-bordered ">


<thead>
<tr>
 <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SPECIFIC AREAS OF LEARNING</th>
 <th colSpan={3}> (PERCENTAGE SCORE)</th>
 <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Termly Exam (60%)</th>
 <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Total (100%)</th>
 <th rowSpan={2}>Grade</th>
         

</tr>
<tr>
 <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>1<sup>st</sup> CA 10%</th>
 <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2<sup>nd</sup> CA 10%</th>
 <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>CLASS PRESENTATION (20%)</th>


</tr>

</thead>
<tbody>

{getScores(std.code).map((st, idx)=> st.absent!=='Yes' && st.total>0?<>
<tr key={idx}>

 <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>

 <td>{st.firstCA==='0'?'-':st.firstCA}</td>
 <td>{st.secondCA==='0'?'-':st.secondCA}</td>
 <td>{st.thirdA==='0'?'-':st.thirdA}</td>
 <td>{st.exam==='0'?'-':st.exam}</td>
 <td>{st.total==='0'?'-':st.total}</td>
 <td>{st.grade}</td>
</tr>
<tr>
    <td className='vertical-tds' style={{backgroundColor:`${colors[idx]}`, color:'white'}} ><b>Keynote</b></td>
    <td colSpan={7} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap', backgroundColor:`${bodyColor[idx]}` }} >{st.comment}</td>

</tr></>
:[])} 
</tbody> 
</table>
</div>



<div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
<tbody>
<tr>
<td  style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>Class Teacher's Comment </b></td>
<td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>

</tr>
<tr>
<td  style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>NAME/SIGNATURE </b></td>

<td style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}

{handleStaffSignature(getData(std.code).teacherName)}

</td>

</tr>

<tr>
<td style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} ><b>Head of School's Comment </b></td>

<td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>

</tr>
<tr>
<td style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>NAME/SIGNATURE </b></td>

<td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofSchool, 'staffName')}

<img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofSchool, 'signature')}`}   alt='' />

</td>

</tr>


</tbody>
</table>
</div>
</div>

<div className="row no-print">
<div className="col-md-12 pull-right">
<form method='POST' action={ServerUrl+'/printout/result_pre.php?token='+userToken} target='_blank'>

<input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
<input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 


<input type='hidden' name='studentAssessmentThird' value ={JSON.stringify(studentAssessmentThird, null, 2)} />
<input type='hidden' name='subCategory' value ={JSON.stringify(subCategory, null, 2)} />
<input type='hidden' name='onlyCategoryThird' value ={JSON.stringify(onlyCategoryThird, null, 2)} />
<input type='hidden' name='examTerm' value ={result.examtype.term} />
<input type='hidden' name='categoryGroup' value ={JSON.stringify(categoryGroup, null, 2)} />

<input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
<input type='hidden' name='studentAssessmentMusic' value ={JSON.stringify(studentAssessmentMusic, null, 2)} />
<input type='hidden' name='totalStudent' value ={studentList.length} />
<input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
<input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
<input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />

<input type='hidden' name='totalOpen' value ={atResult.totalOpen} />
<input type='hidden' name='examPoint' value ={getCAPoint(result.studentClass.classtype).exam} />
<input type='hidden' name='caPoint' value ={getCAPoint(result.studentClass.classtype).ca} />
<input type='hidden' name='attendanceEnd' value ={getBritishDate(atResult.attendanceEnd)} />
<input type='hidden' name='attendanceStart' value ={getBritishDate(atResult.attendanceStart)} />
<input type='hidden' name='nextTermBegin' value ={getBritishDate(atResult.nextTermBegin)} />
<input type='hidden' name='nextTermBeginDay' value ={getBritishDate(atResult.nextTermBeginDay)} />
<input type='hidden' name='resultHeadP' value ={getResultHead(result.studentClass.classtype)} />
<input type='hidden' name='settings' value ={JSON.stringify(settings, null, 2)} />
<input type='hidden' name='classID' value ={result.studentClass.classID} />
<input type='hidden' name='sectionID' value ={result.studentClass.value} />
<input type='hidden' name='examCode' value ={result.examtype.value} />
<input type='hidden' name='finalGrade' value ={result.studentClass.classtype} />
<input type='hidden' name='resultHead' value ={getPosition(result.studentClass.classtype)} />
<input type='hidden' name='assesmentGrade' value ={JSON.stringify(assesmentGrade, null, 2)} />
<input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
<input type='hidden' name='studentList' value ={JSON.stringify(allStudents, null, 2)} />
<input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />

<input type='hidden' name='jwtToken' value ={Token} />
<button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>

</form>
</div></div>

</div>}


</div> 
: getResultHead(result.studentClass.classtype)==='Primary'?
    <div>    <div className="card-block">
	
                      
                       {schoolInfo&&schoolInfo.map((st, id)=>
                   <div className="row" key={id}>

<table className="table  table-full-width">
   <tbody>
   <tr>
   <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}, E:{st.email}  </div>
<div style={{textAlign:'center'}}>W: {st.website} Telephone: {st.mobile}, {st.altMobile} </div>

 </td>
            </tr>
        </tbody>
   </table>

</div> 
                   )}

                
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b> {result.examtype.label} REPORT</b></u></h5>
        </div></div><br/>

<div className="row">
       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
       <tbody>

        <tr>
            <td rowSpan={4}>

<img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  />
            </td>
                    <td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>         
                 <td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
                 <td >CLASS: <b> {result.studentClass.label} </b></td>
                 <td><b>Grading</b></td>
                </tr>
                <tr>
               

               
                <td >STATE: <b> {std.stateName} </b></td>
                <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>    
                <td >GENDER: <b> {std.gender}</b></td>
                <td >SESSION/TERM: <b>{result.examtype.label} </b></td>
                <td rowSpan={3}><b>{grademark.map((data, index)=><span key={index}>{data.maximum + ' - ' + data.minimum + " " +getGradeScore(data.gradeCode)}<br/></span>)} </b>
                    </td>
                </tr>


                <tr>
                <td>NO. TIMES SCHOOL OPENED: <b> {atResult.totalOpen} </b></td>
                <td>NO. TIMES PRESENT: <b>{getAt(std.code).P} </b></td>
                <td>NO. TIMES ABSENT: <b>{getAt(std.code).A} </b></td>
                <td >NO. IN CLASS: <b>{studentList.length}</b></td>
                </tr>

                <tr>
                <td >MARKS OBTAINED: <b>{parseInt(getStudent(std.code).obtained).toFixed(2)} </b></td>   
                <td >OUT OF: <b>{getStudent(std.code).obtainable}</b></td>           
                <td >CLOSING DATE: <b>{getBritishDate(atResult.attendanceEnd)}</b></td>
                <td >RESUMPTION DATE: <b>{getBritishDate(atResult.nextTermBeginDay)}</b></td>
                </tr>
                    
            </tbody>
       </table></div>


       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
         <tbody>
             <tr>
             <td style={{color:'blue'}}><b >Grading for this section is as follows:</b> <br/> <b>{grademark.map((data, index)=><i key={index}>{getGradeScore(data.gradeCode) + ' - '  +getRemark(data.gradeMark) + ',  '}</i>)} </b></td>
             </tr>
         </tbody>
     </table>
        <table className="table table-striped table-bordered ">
        

            <thead>
            <tr>
                    <th rowSpan={2}>Subject</th>
                    <th colSpan={4}>1<sup>st</sup> C.A Rubric Worksheets (20%)</th>
                    <th rowSpan={2} style={{maxWidth:'120px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2<sup>nd</sup> C.A First Formal Test (20%)</th>
                    <th  colSpan={2}>3<sup>rd</sup> C.A (20%)</th>
                    <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Termly Exam (40%)</th>
                    <th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Total (100%)</th>
                    <th rowSpan={2}>Grade</th>
                    <th rowSpan={2}>Remark</th> 
                            

                </tr>
                <tr>
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>1<sup>st</sup> 5mrks</th>
                    <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2<sup>nd</sup> 5mrks</th>
                    <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>3<sup>rd</sup> 5mrks</th>
                    <th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>4<sup>th</sup> 5mrks</th>

                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Subject Project (Individual 10%)</th>
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Subject Project (Group 10%)</th>
                 

                </tr>

            </thead>
            <tbody>
                
            {getScores(std.code).map((st, idx)=> st.absent!=='Yes' && st.total>0?
                <tr key={idx}>
                
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>

                    <td>{st.firstA==='0'?'-':st.firstA}</td>
                    <td>{st.firstB==='0'?'-':st.firstB}</td>
                    <td>{st.firstC==='0'?'-':st.firstC}</td>
                    <td>{st.firstD==='0'?'-':st.firstD}</td>
                    <td>{st.secondCA==='0'?'-':st.secondCA}</td>
                    <td>{st.thirdA==='0'?'-':st.thirdA}</td>
                    <td>{st.thirdB==='0'?'-':st.thirdB}</td>

                    <td>{st.exam==='0'?'-':st.exam}</td>
                    <td>{st.total==='0'?'-':st.total}</td>
                    <td>{st.grade}</td>
                    <td>{st.remark}</td>
                
            </tr>
                :[])} 
            </tbody> 
        </table>
 </div>	 
 <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
         <tbody>
             <tr>
             <td style={{color:'blue'}}><b >Grading for this section is as follows:</b> <br/> <b>{grademark.map((data, index)=><i key={index}>{getGradeScore(data.gradeCode) + ' - '  +getRemark(data.gradeMark) + ',  '}</i>)} </b></td>
             </tr>
         </tbody>
     </table>

     {onlyCategory&&onlyCategory.map((data, index)=><div key={index}><h4 className='text-center text-red' >{data.type}</h4>
     
     <table className="table table-striped table-bordered ">
        
        <thead> 
            <tr className='bg-primary'>
        {getStudentAssessment(std.code).map((list, id)=> list.type===data.type?
                        <th key={id} style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{list.assessmentName}</th>:[]
                          )}

                          </tr>

                          <tr >
        {getStudentAssessment(std.code).map((list, id)=> list.type===data.type?
                        <td key={id}>{list.grade}</td>:[]
                          )}

                          </tr>
         </thead> 

         </table>
     
     
     </div>)}

    
 <div className="col-md-12">
 
     
<table className='table  table-condensed'>
        <tbody>
            <tr>
                <td  style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>TEACHER’S COMMENT(S) </b></td>
                <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>
                
            </tr>
            <tr>
            <td  style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>NAME/SIGNATURE </b></td>

            <td style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}
            {handleStaffSignature(getData(std.code).teacherName)}
            
            </td>
                
            </tr>

            <tr>
                <td style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} ><b>HEAD OF SCHOOL'S COMMENT </b></td>

            <td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>

            </tr>
            <tr>
          <td style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>NAME/SIGNATURE </b></td>
               
                <td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofSchool, 'staffName')}
                
                <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofSchool, 'signature')}`}   alt='' />
                
                </td>
                
            </tr>

           
        </tbody>
    </table>
</div>
 </div>
 </div> 
 
 <div className="row no-print">
        <div className="col-md-12 pull-right">
       
 <form method='POST' action={ServerUrl+'/printout/view_result.php?token='+userToken} target='_blank'>
        <input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 


                
<input type='hidden' name='studentAssessmentThird' value ={JSON.stringify(studentAssessmentThird, null, 2)} />
<input type='hidden' name='subCategory' value ={JSON.stringify(subCategory, null, 2)} />
<input type='hidden' name='onlyCategoryThird' value ={JSON.stringify(onlyCategoryThird, null, 2)} />
<input type='hidden' name='examTerm' value ={result.examtype.term} />
<input type='hidden' name='categoryGroup' value ={JSON.stringify(categoryGroup, null, 2)} />

                <input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
                <input type='hidden' name='studentAssessmentMusic' value ={JSON.stringify(studentAssessmentMusic, null, 2)} />
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                
                <input type='hidden' name='totalOpen' value ={atResult.totalOpen} />
                <input type='hidden' name='examPoint' value ={getCAPoint(result.studentClass.classtype).exam} />
                <input type='hidden' name='caPoint' value ={getCAPoint(result.studentClass.classtype).ca} />
                <input type='hidden' name='attendanceEnd' value ={getBritishDate(atResult.attendanceEnd)} />
                <input type='hidden' name='attendanceStart' value ={getBritishDate(atResult.attendanceStart)} />
                <input type='hidden' name='nextTermBegin' value ={getBritishDate(atResult.nextTermBegin)} />
                <input type='hidden' name='nextTermBeginDay' value ={getBritishDate(atResult.nextTermBeginDay)} />
                <input type='hidden' name='resultHeadP' value ={getResultHead(result.studentClass.classtype)} />
                <input type='hidden' name='settings' value ={JSON.stringify(settings, null, 2)} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='finalGrade' value ={result.studentClass.classtype} />
                <input type='hidden' name='resultHead' value ={getPosition(result.studentClass.classtype)} />
                <input type='hidden' name='assesmentGrade' value ={JSON.stringify(assesmentGrade, null, 2)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
                <input type='hidden' name='studentList' value ={JSON.stringify(allStudents, null, 2)} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div></div>:

getResultHead(result.studentClass.classtype)==='Secondary'?
    <div>    <div className="card-block">
	
                      
                       {schoolInfo&&schoolInfo.map((st, id)=>
                   <div className="row" key={id}>

<table className="table  table-full-width">
   <tbody>
   <tr>
   <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}, E:{st.email}  </div>
<div style={{textAlign:'center'}}>W: {st.website} Telephone: {st.mobile}, {st.altMobile} </div>

 </td>
            </tr>
        </tbody>
   </table>

</div> 
                   )}

                
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b> {result.examtype.label} REPORT</b></u></h5>
        </div></div><br/>

<div className="row">
       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
       <tbody>

        <tr>
            <td rowSpan={4}>

<img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  />
            </td>
                    <td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>         
                 <td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
                 <td >CLASS: <b> {result.studentClass.label} </b></td>
                 <td><b>Grading</b></td>
                </tr>
                <tr>
               

               
                <td >STATE: <b> {std.stateName} </b></td>
                <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>    
                <td >GENDER: <b> {std.gender}</b></td>
                <td >SESSION/TERM: <b>{result.examtype.label} </b></td>
                <td rowSpan={3}><b>{grademark.map((data, index)=><span key={index}>{data.maximum + ' - ' + data.minimum + " " +getGradeScore(data.gradeCode)}<br/></span>)} </b>
                    </td>
                </tr>


                <tr>
                <td>NO. TIMES SCHOOL OPENED: <b> {atResult.totalOpen} </b></td>
                <td>NO. TIMES PRESENT: <b>{getAt(std.code).P} </b></td>
                <td>NO. TIMES ABSENT: <b>{getAt(std.code).A} </b></td>
                <td >NO. IN CLASS: <b>{studentList.length}</b></td>
                </tr>

                <tr>
                <td >MARKS OBTAINED: <b>{parseInt(getStudent(std.code).obtained).toFixed(2)} </b></td>   
                <td >OUT OF: <b>{getStudent(std.code).obtainable}</b></td>           
                <td >CLOSING DATE: <b>{getBritishDate(atResult.attendanceEnd)}</b></td>
                <td >RESUMPTION DATE: <b>{getBritishDate(atResult.nextTermBeginDay)}</b></td>
                </tr>
                    
            </tbody>
       </table></div>


       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
         <tbody>
             <tr>
             <td style={{color:'blue'}}><b >Grading for this section is as follows:</b> <br/> <b>{grademark.map((data, index)=><i key={index}>{getGradeScore(data.gradeCode) + ' - '  +getRemark(data.gradeMark) + ',  '}</i>)} </b></td>
             </tr>
         </tbody>
     </table>
        <table className="table table-striped table-bordered ">
        

            <thead>
            <tr>
                    <th rowSpan={2}>Subject</th>
                    <th colSpan={2}>1<sup>st</sup> C.A Class Work (20%)</th>

                    <th rowSpan={2} >2<sup>nd</sup> C.A(20%)</th>
                    <th  rowSpan={2}>Project(20%)</th>
                    <th rowSpan={2} > Exam (40%)</th>
                    <th rowSpan={2} >Total (100%)</th>
                    <th rowSpan={2}>Grade</th>
                    <th rowSpan={2}>Remark</th> 

                </tr>
                <tr>
                    <th >1<sup>st</sup> 10 mrks</th>
                    <th >2<sup>nd</sup> 10mrks</th>
                    
                 

                </tr>

            </thead>
            
                
            {getScores(std.code).map((st, idx)=> st.absent!=='Yes' && st.total>0?<tbody key={idx}>
                <tr >

                

                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>

                    <td>{st.firstA==='0'?'-':st.firstA}</td>
                    <td>{st.firstB==='0'?'-':st.firstB}</td>
                    <td>{st.firstC==='0'?'-':st.firstC}</td>
                    <td>{st.firstD==='0'?'-':st.firstD}</td>

                    <td>{st.exam==='0'?'-':st.exam}</td>
                    <td>{st.total==='0'?'-':st.total}</td>
                    <td>{st.grade}</td>
                    <td>{st.remark}</td>
                
            </tr>
             <tr>
                 
             <td>{handleStaffName(st.teacher)}</td>
                 <td colSpan='8' style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.comment}</td>
             </tr>

             </tbody>  :[])}
        </table>
 </div>	 
 <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
         <tbody>
             <tr>
             <td style={{color:'blue'}}><b >Grading for this section is as follows:</b> <br/> <b>{grademark.map((data, index)=><i key={index}>{getGradeScore(data.gradeCode) + ' - '  +getRemark(data.gradeMark) + ',  '}</i>)} </b></td>
             </tr>
         </tbody>
     </table>

     {onlyCategory&&onlyCategory.map((data, index)=><div><h4 className='text-center text-red' key={index}>{data.type}</h4>
     
     <table className="table table-striped table-bordered ">
        
        <thead> 
            <tr className='bg-primary'>
        {getStudentAssessment(std.code).map((list, id)=> list.type===data.type?
                        <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{list.assessmentName}</th>:[]
                          )}

                          </tr>

                          <tr >
        {getStudentAssessment(std.code).map((list, id)=> list.type===data.type?
                        <td >{list.grade}</td>:[]
                          )}

                          </tr>
         </thead> 

         </table>
     
     
     </div>)}

    
 <div className="col-md-12 table-responsive">
 
     
<table className='table  table-condensed'>
        <tbody>

      {/*   <tr>
                <td ><b>Student's Reflection </b></td>


                <td colSpan={2} >{getData(std.code).studentRemark} </td>
                
            </tr> */}


            <tr>
                <td  style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>Form Tutor's Comment </b></td>
                <td colSpan={2} >{getData(std.code).teacherRemark} </td>
                
            </tr>
            <tr>
            <td  style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>Name/Signature </b></td>

            <td >{handleStaffName(getData(std.code).teacherName)} </td>
                
            </tr>

            <tr>
                <td  ><b>Principal's Comment </b></td>

            <td  style={{ overflowWrap:'break-word', whiteSpace:'pre-wrap'}}>{getData(std.code).headRemark} </td>

            </tr>
            <tr>
          <td style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>Name/Signature </b></td>
               
                <td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofPrimary, 'staffName')}
                
                <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofPrimary, 'signature')}`}   alt='' />
                
                </td>
                
            </tr>


   <tr>
   <td style={{maxWidth:'10px' }}><b>Head Of School </b></td>
   <td >{settings&& getStaffField(settings.headofSchool, 'staffName')}
    <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofSchool, 'signature')}`}   alt='' /></td>
</tr>
           
        </tbody>
    </table>
</div>
 </div>
 </div> 
 
 <div className="row no-print">
        <div className="col-md-12 pull-right">
       
 <form method='POST' action={ServerUrl+'/printout/view_result.php?token='+userToken} target='_blank'>
        <input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 


                
<input type='hidden' name='studentAssessmentThird' value ={JSON.stringify(studentAssessmentThird, null, 2)} />
<input type='hidden' name='subCategory' value ={JSON.stringify(subCategory, null, 2)} />
<input type='hidden' name='onlyCategoryThird' value ={JSON.stringify(onlyCategoryThird, null, 2)} />
<input type='hidden' name='examTerm' value ={result.examtype.term} />
<input type='hidden' name='categoryGroup' value ={JSON.stringify(categoryGroup, null, 2)} />

                <input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
                <input type='hidden' name='studentAssessmentMusic' value ={JSON.stringify(studentAssessmentMusic, null, 2)} />
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                
                <input type='hidden' name='totalOpen' value ={atResult.totalOpen} />
                <input type='hidden' name='examPoint' value ={getCAPoint(result.studentClass.classtype).exam} />
                <input type='hidden' name='caPoint' value ={getCAPoint(result.studentClass.classtype).ca} />
                <input type='hidden' name='attendanceEnd' value ={getBritishDate(atResult.attendanceEnd)} />
                <input type='hidden' name='attendanceStart' value ={getBritishDate(atResult.attendanceStart)} />
                <input type='hidden' name='nextTermBegin' value ={getBritishDate(atResult.nextTermBegin)} />
                <input type='hidden' name='nextTermBeginDay' value ={getBritishDate(atResult.nextTermBeginDay)} />
                <input type='hidden' name='resultHeadP' value ={getResultHead(result.studentClass.classtype)} />
                <input type='hidden' name='settings' value ={JSON.stringify(settings, null, 2)} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='finalGrade' value ={result.studentClass.classtype} />
                <input type='hidden' name='resultHead' value ={getPosition(result.studentClass.classtype)} />
                <input type='hidden' name='assesmentGrade' value ={JSON.stringify(assesmentGrade, null, 2)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
                <input type='hidden' name='studentList' value ={JSON.stringify(allStudents, null, 2)} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div></div>
:
 
 <div>    
    
 <div className="card-block">
 
                   
 {schoolInfo&&schoolInfo.map((st, id)=>
<div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}, E:{st.email}  </div>
<div style={{textAlign:'center'}}>W: {st.website} Telephone: {st.mobile}, {st.altMobile} </div>

</td>
</tr>
</tbody>
</table>

</div> 
)}


<div className="row">
<div className="col-md-12">
<h5 style={{textAlign:'center'}}><u><b> {result.examtype.label} PROGRESS REPORT</b></u></h5>
</div></div><br/>

<div className="row">
       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
<tbody>

<tr>
<td rowSpan={4}>

<img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  />
</td>
<td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>         
<td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
</tr>


<tr>
<td >STATE: <b> {std.stateName} </b></td>
<td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>    
<td >GENDER: <b> {std.gender}</b></td>
</tr>


<tr>
<td>NO. TIMES SCHOOL OPENED: <b> {atResult.totalOpen} </b></td>
<td>NO. TIMES PRESENT: <b>{getAt(std.code).P} </b></td>
<td>NO. TIMES ABSENT: <b>{getAt(std.code).A} </b></td>
</tr>

<tr>
<td >CLASS: <b> {result.studentClass.label} </b></td>             
<td >CLOSING DATE: <b>{getBritishDate(atResult.attendanceEnd)}</b></td>
<td >RESUMPTION DATE: <b>{getBritishDate(atResult.nextTermBeginDay)}</b></td>
</tr>

</tbody>
</table></div>
 



       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
   <thead>
   <th className='text-center bg-dark'><h4>Early Years Termly Progress Report</h4> </th>
   </thead>
   </table>
</div>

<div className="col-md-12">
   <div className="box">
<p> 
<b>{std.studentName.toUpperCase()}</b> has been evaluated in the following areas of learning: Personal, Social & Emotional Development, communication and language, physical development, Expressive art and design, Literacy, Numeracy and understanding The world. The grading scale is listed below. You will find three boxes to compare <b>{std.studentName.toUpperCase()}</b> improvement from First  term to Third Term . Please feel free to schedule a conference with the class teacher to  further discuss this evaluation 

</p>
</div>

<div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
   <thead>
   <th className='text-center bg-primary'><h4>Developmental  Grading Scale</h4> </th>
   </thead>
<tbody>
<tr>
   <td><i className='fa fa-star text-warning'></i>
   – <b>Not yet within:</b> Demonstrates difficulty with skills; requires constant teacher assistance     
   </td>
   </tr>
   <tr>

   
   <td><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i>– <b>Fully meets expection:</b> Demonstrates progress towards mastery; requires some teacher assistance</td>
   </tr>
    <tr>
       
   
    <td><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i> – <b>Exceeds Expection:</b> Demonstrates mastery at this time; requires minimal teacher assistance. </td>
    </tr> <tr>
       
      
   <td><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i><i className='fa fa-star text-warning'></i>
   – <b>Outstanding:</b> Demonstrates mastery at this
   </td>
</tr>

</tbody>
</table></div>

<div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
       
          <thead>
               <tr>
                   <th > </th>
                   <th >1 <sup>st</sup> Term</th>
                   <th >2 <sup>nd</sup> Term</th>
                   <th >3 <sup>rd</sup> Term</th>
               </tr>
           </thead> 
          
           {onlyCategory&&onlyCategory.map((data, index)=>
           <tbody key={index}>
           <tr >
                <th colSpan={4} style={{backgroundColor:`${colors[index]}`, color:'white'}} ><h4>{data.type}</h4></th>
             </tr>
           {getStudentAssessment(std.code).map((list, id)=> list.type===data.type?
                         
                        <tr key={id} style={{backgroundColor:`${bodyColor[index]}`}} >
                               <td style={{maxWidth:'600px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{list.assessmentName}</td>
               <td>{[...Array(Number(list.first)?Number(list.first):0).keys()].map((_, id)=><i key={id} className='fa fa-star text-warning'></i>)}</td>
               <td>{[...Array(Number(list.second)?Number(list.second):0).keys()].map((_, id)=><i key={id}  className='fa fa-star text-warning'></i>)}</td>
               <td>{[...Array(Number(list.third)?Number(list.third):0).keys()].map((_, id)=><i key={id}  className='fa fa-star text-warning'></i>)}</td>
                           </tr>:[])}
                           </tbody>
                           
                           )}
       </table>
</div>

</div>


       <div className="col-md-12">
    {/*  <table className='table table-bordered table-responsive'>
<tbody>
<tr className='text-center'>
<td style={{color:'blue'}}><b >GRADING:</b> <br/> <b>{grademark.map((data, index)=><span key={index}>{data.maximum + ' - ' + data.minimum + " " +getGradeScore(data.gradeCode)+ ",  "}</span>)} </b></td>
</tr>
</tbody>
</table> */}
<table className="table table-striped table-bordered">


<thead>
<tr>
<th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SPECIFIC AREAS OF LEARNING</th>
<th > REMARKS</th>
{/* <th colSpan={4}> (PERCENTAGE SCORE)</th>
<th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Exam (40%)</th>
<th rowSpan={2} style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Total (100%)</th>
<th rowSpan={2}>Grade</th> */}
      

</tr>
{/* <tr>
<th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>PROJECT 20%</th>
<th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>1<sup>st</sup> CA 10%</th>
<th style={{maxWidth:'80px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>2<sup>nd</sup> CA 10%</th>
<th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>CLASS PRESENTATION (20%)</th>


</tr> */}

</thead>
<tbody>

{getScores(std.code).map((st, idx)=> st.absent!=='Yes'?<>
<tr key={idx}>

<td >{st.subjectName.toUpperCase()}</td>
{/* <td>{st.thirdA==='0'?'-':st.thirdA}</td>
<td>{st.firstCA==='0'?'-':st.firstCA}</td>
<td>{st.secondCA==='0'?'-':st.secondCA}</td>
<td>{st.thirdB==='0'?'-':st.thirdB}</td>
<td>{st.exam==='0'?'-':st.exam}</td>
<td>{st.total==='0'?'-':st.total}</td>
<td>{st.grade}</td> */}
 <td  style={{  overflowWrap:'break-word', whiteSpace:'pre-wrap', backgroundColor:`${bodyColor[idx]}` }} >{st.comment}</td>
</tr>
{/* <tr>
 <td className='vertical-td' style={{backgroundColor:`${colors[idx]}`, color:'white'}} ><b>Keynote</b></td>
 <td colSpan={7} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap', backgroundColor:`${bodyColor[idx]}` }} >{st.comment}</td>

</tr> */}
</>
:[])} 
</tbody> 
</table>
</div>	 

<div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
<tbody>
<tr>
<td  style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>Class Teacher's Comment </b></td>
<td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>

</tr>
<tr>
<td  style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>NAME/SIGNATURE </b></td>

<td style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}

{handleStaffSignature(getData(std.code).teacherName)}

</td>

</tr>

<tr>
<td style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} ><b>Head of School's Comment </b></td>

<td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>

</tr>
<tr>
<td style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>NAME/SIGNATURE </b></td>

<td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofSchool, 'staffName')}

<img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofSchool, 'signature')}`}   alt='' />

</td>

</tr>


</tbody>
</table>


</div>
</div>

<div className="row no-print">
<div className="col-md-12 pull-right">
<form method='POST' action={ServerUrl+'/printout/result_pre.php?token='+userToken} target='_blank'>

<input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
<input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 



<input type='hidden' name='studentAssessmentThird' value ={JSON.stringify(studentAssessmentThird, null, 2)} />
<input type='hidden' name='subCategory' value ={JSON.stringify(subCategory, null, 2)} />
<input type='hidden' name='onlyCategoryThird' value ={JSON.stringify(onlyCategoryThird, null, 2)} />
<input type='hidden' name='examTerm' value ={result.examtype.term} />
<input type='hidden' name='categoryGroup' value ={JSON.stringify(categoryGroup, null, 2)} />

<input type='hidden' name='studentAssessmentMusic' value ={JSON.stringify(studentAssessmentMusic, null, 2)} />
<input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
<input type='hidden' name='totalStudent' value ={studentList.length} />
<input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
<input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
<input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />

<input type='hidden' name='totalOpen' value ={atResult.totalOpen} />
<input type='hidden' name='examPoint' value ={getCAPoint(result.studentClass.classtype).exam} />
<input type='hidden' name='caPoint' value ={getCAPoint(result.studentClass.classtype).ca} />
<input type='hidden' name='attendanceEnd' value ={getBritishDate(atResult.attendanceEnd)} />
<input type='hidden' name='attendanceStart' value ={getBritishDate(atResult.attendanceStart)} />
<input type='hidden' name='nextTermBegin' value ={getBritishDate(atResult.nextTermBegin)} />
<input type='hidden' name='nextTermBeginDay' value ={getBritishDate(atResult.nextTermBeginDay)} />
<input type='hidden' name='resultHeadP' value ={getResultHead(result.studentClass.classtype)} />

<input type='hidden' name='settings' value ={JSON.stringify(settings, null, 2)} />
<input type='hidden' name='classID' value ={result.studentClass.classID} />
<input type='hidden' name='sectionID' value ={result.studentClass.value} />
<input type='hidden' name='examCode' value ={result.examtype.value} />
<input type='hidden' name='finalGrade' value ={result.studentClass.classtype} />
<input type='hidden' name='resultHead' value ={getPosition(result.studentClass.classtype)} />
<input type='hidden' name='assesmentGrade' value ={JSON.stringify(assesmentGrade, null, 2)} />
<input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
<input type='hidden' name='studentList' value ={JSON.stringify(allStudents, null, 2)} />
<input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />

<input type='hidden' name='jwtToken' value ={Token} />
<button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>

</form>
</div></div>

</div>


</div> 
 
 }

 
 




{disabledResult&&disabledResult.includes(std.code)? 
<div style={{ 

height:'100%',
backgroundColor:'#E1E1E1',
width:'100%',
alignItems:'center',
justifyContent:'center',
flexDirection:'column',
display:'flex',
flex:1,
position:'absolute',
padding:'20px',
opacity:0.98


 }}>


<h3 style={{ color:'blue',  }}> Result not available at the momemt, please contact your child's class teacher</h3>

</div>
:''} 

        </div>:''}











{/* music result here  */}


{getResultHead(result.studentClass.classtype)==='Primary'?
<div className="card z-depth-0">  
    
    <div className="card-block">
	
                      
    {schoolInfo&&schoolInfo.map((st, id)=>
<div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}, E:{st.email}  </div>
<div style={{textAlign:'center'}}>W: {st.website} Telephone: {st.mobile}, {st.altMobile} </div>

</td>
</tr>
</tbody>
</table>

</div> 
)}


<div className="row">
<div className="col-md-12">
<h5 style={{textAlign:'center'}}><u><b> {result.examtype.label} BAND PROGRESS REPORT</b></u></h5>
</div></div><br/>

<div className="row">
       <div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
<tbody>

<tr>
<td rowSpan={4}>
<img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  />
</td>
 <td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>         
<td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
</tr>


<tr>
<td >STATE: <b> {std.stateName} </b></td>
<td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>    
<td >GENDER: <b> {std.gender}</b></td>
</tr>

<tr>
<td >CLASS: <b> {result.studentClass.label} </b></td>
<td >CLOSING DATE: <b>{getBritishDate(atResult.attendanceEnd)}</b></td>
<td >RESUMPTION DATE: <b>{getBritishDate(atResult.nextTermBeginDay)}</b></td>
</tr>
 
</tbody>
</table></div>

	

<div className="col-md-12">
   <div className="box">
<p> The following rubrics for string players will give a more detailed reading of some of the basic foundational skills we have worked on this trimester and will continue
to be added to and improved upon over the next years. Please see the comment for more individual notes.
</p>
</div>
     

<div className="col-md-12 table-responsive">
     <table className='table table-bordered '>
       
          <thead>
               <tr>
                   <th style={{maxWidth:'400px', fontWeight:'700',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }} > </th>
                   <th style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>  4<br/>  <b>ABOVE EXPECTATION</b><br/> <i> No significant errors</i></th>
                   <th style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>  3 <br/>  <b>SECURE</b> <br/> <i>Some minor errors</i></th>
                   <th style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>  2 <br/><b>DEVELOPING</b></th>
                   <th style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>  1 <br/><b>BEGINNING</b> <br/> <i> Includes useful tips for all graders</i></th>

                   <th >1   <sup>st</sup><br/> Term</th>
                   <th >2   <sup>nd</sup><br/> Term</th>
                   <th >3   <sup>rd</sup><br/> Term</th>
               </tr>
           </thead> 

           <tbody>
           {getStudentAssessmentMusic(std.code).map((list, id)=> list.first!==''|| list.second!=='' ||list.third!==''?
                         
                        <tr key={id}  >
                               <td style={{maxWidth:'400px', fontWeight:'700',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{list.assessmentName}</td>

                               
                               <td style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{list.above_expectation}</td>
                               <td style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{list.secure}</td>
                               <td style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{list.developing}</td>
                               <td style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{list.beginning}</td>


                               <td><b>{list.first}</b></td>
                               <td><b>{list.second}</b></td>
                               <td><b>{list.third}</b></td>
                           </tr>:[])}
                           </tbody>
       </table>
</div>

</div>



</div>


</div>


</div> :''}





        </div>)}



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(PrintClassResult) 