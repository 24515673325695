import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import { LoadEffect, TableLoader } from '../component/loader'
import {Alerts, WarningModal} from '../component/notify'
import {ServerUrl, DatabaseName, Token, config} from '../component/include'
import axios from 'axios'

import { useDispatch, useSelector } from 'react-redux'
import Error403 from './error403'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import {useHistory} from 'react-router'
 const Restore =()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false
    }); 

	 let history = useHistory();
    
   const menuLink  = useSelector(state => state.rootReducer.menuLink);
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [puzzle, setPuzzle]= useState({
        num1:0,
       num2:0,
       answer:0,
       userAnswer:''
})
const [database, setDatabase] = useState([]);
const [sqlFile, setSqlFile]=useState(null)
const [backup, setBackup]=useState({
   list:[],
   filePath:''
})
const [errors, setErrors] = useState({});


const fetchBackup =()=>{
   
   var sql ="select * from tbl_backup order by ID DESC" ;
            
        const fd = new FormData();
        fd.append("jwt", Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>setBackup({...backup, list:result.data})
        ) 
}

 const  handleFile = function(fieldName){
   return function(newValue){                
  readURL(newValue, fieldName);   
  }
  
 } 
 
 const readURL = (input, fileId)=>{    

   let displayMessage = '';
     var fileName =  document.getElementById(input.target.name).value;
     
   var idxDot = fileName.lastIndexOf(".") + 1;
   var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
   if (extFile==="sql" || extFile==="SQL"){
       //TO DO    
       
        var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
   if (FileSize > 5) {
       displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 5 Mb';
      document.getElementById(input.target.name).value = '';
      setSqlFile('')
   } else {
       if (input.target.files && input.target.files[0]) { //Check if input has files.
         setSqlFile(input.target.files[0]) 
         setErrors({...errors, sql:''}) 
       }
   }
   }else{
       displayMessage = 'Only Sql file is allowed with maximum of 2 Mb'
      document.getElementById(input.target.name).value = '';
      setSqlFile('')
   }   
   
   if(displayMessage.length > 0){
       Alerts('Error!', 'danger', displayMessage)
     }
} 

const getPuzzle=()=>{
   var num1 = Math.floor(Math.random() * 9)
       var num2 = Math.floor(Math.random() * 9)
       var answer = num1+num2
       setPuzzle({...puzzle, num1:num1, num2:num2, answer:answer, userAnswer : ''})
}

const handleSelect = (option, action)=>{
  setBackup({...backup, filePath:option});
  setErrors({...errors, filePath:''})
    }


    const handleDeleteWithImage =(ID, filePath)=>{
		let close =   document.getElementById('btnWarningDialog-'+ID)
         close.click();
	
		setNotice({...notice,  isLoading: true}) 

		const fd = new FormData();
    fd.append("jwt", Token);
		fd.append('ID', 'ID')
		fd.append('data', ID)
		fd.append('imageLink', 'database/'+filePath)
		  
	let url = ServerUrl+'/deleteSingleWithImage_controller/tbl_backup'
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
              })
              fetchBackup()
		  }) 
		
}


const UploadDatabase=(event)=>{
   event.preventDefault()
   if(!sqlFile){
     var   sql="Please choose sql file to upload"
       setErrors({...errors, sql:sql})
       Alerts('Error!', 'danger', sql)

   }else{
   setNotice({...notice, 
       isLoading: true}) 
       const fd = new FormData();
       fd.append("jwt", Token);
       fd.append('sqlFile', sqlFile)
       fd.append("uploaddatabase", 'backup-on-'+new Date().toISOString().slice(0,10)+' '+new Date().toLocaleTimeString())
   
           let url = ServerUrl+'/save_controller/tbl_upload_database'
           axios.post(url, fd, config)
       .then(response =>{        
       if (response.data.type ==='success'){       
               Alerts('Success!', 'success', response.data.message)
           }else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
             }       
           })
           .catch((error)=>{
               Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
               setNotice({...notice, 
                   isLoading: false
               }) 
               setSqlFile('')
               fetchBackup()
            document.getElementById('sql').value=''
           })     
 }
}

const RestoreDatabase=()=>{
if(!backup.filePath){
   var   sql="Please select data to restore"
       setErrors({...errors, filePath:sql})
       Alerts('Error!', 'danger', sql)
}else{
       setNotice({...notice, 
           isLoading: true}) 

           const fd = new FormData();
           fd.append("jwt", Token);
           fd.append('filename', backup.filePath)
           fd.append("restoredatabase", 'backup-on-'+new Date().toISOString().slice(0,10)+' '+new Date().toLocaleTimeString())
       
               let url = ServerUrl+'/save_controller/tbl_restore_database'
               axios.post(url, fd, config)
           .then(response =>{        
           if (response.data.type ==='success'){       
                   Alerts('Success!', 'success', response.data.message)
               }else{
                   Alerts('Error!', 'danger', JSON.stringify(response.data))
                 }       
               })
               .catch((error)=>{
                   Alerts('Error!', 'danger', error.message)
               }).finally(()=>{
               setNotice({...notice, 
                    isLoading: false})
               window.open('/', '_self')
           })   
     }
}

const ResetDabase=()=>{
if(!puzzle.userAnswer){
var   userAnswer="Please provide answer to the question"
       setErrors({...errors, userAnswer:userAnswer})
       Alerts('Error!', 'danger', userAnswer)
}else if(Number(puzzle.userAnswer) !== Number(puzzle.answer)){
Alerts('Error!', 'danger', 'You have provided  wrong answer')
}else{

let close =   document.getElementById('btnInfoDialog')
         close.click();
setNotice({...notice, 
   isLoading: true}) 

   const fd = new FormData();
   fd.append("jwt", Token);
   fd.append('filename', 'kayus_db.sql')
   fd.append("resetdatabase", 'backup-on')

       let url = ServerUrl+'/save_controller/tbl_reset_database'
       axios.post(url, fd, config)
   .then(response =>{        
   if (response.data.type ==='success'){       
           Alerts('Success!', 'success', response.data.message)
       }else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
         }       
       })
       .catch((error)=>{
           Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
       setNotice({...notice, 
            isLoading: false})
       window.open('/', '_self')
   })   
}  
}


const handleChange = event =>{		
const {name, value} = event.target;		
setPuzzle({...puzzle, [name] : value });
setErrors({...errors, [name]:''});
}


useEffect(()=>{   
  
  if(menuLink.length!==0){
    setIsAuthenticated(menuLink.includes(location.pathname))
  }
  
},[menuLink]);

useEffect(()=>{
  
fetchBackup()
getPuzzle()
},[]);


        return (<>
    
          <div  className="main-body">
          {isAuthenticated?   <div className="page-wrapper">
                        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
       <PageHeader title="Database"
	   				menu="Restore"
					   />


{/* The Page Body start here */}
<div className="page-body">
						
<div className="modal fade" id="infoDialog" tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content background-info">
             
          <div className="modal-header">
                  <h5 className="modal-title"><i className="fa fa-star"></i> Are you sure!</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
              <div className="modal-body">
                  
                <p>By Reset Database you will lose your all your website material including text, images, videos, and all your business content please make double sure before you proceed</p>

             <div className="row">
                <div className="col-sm-3"><h5>{puzzle.num1 + ' + '+ puzzle.num2 + '  = '}</h5></div>
            <div className="col-sm-4"><input type="number" name="userAnswer" value={puzzle.userAnswer}  onChange={(e)=>handleChange(e)} className={errors.userAnswer  ? 'form-control formerror' : 'form-control'} />
            
            </div>
            </div> 
              </div>
              <div className="modal-footer">
                  <button type="button"  id='btnInfoDialog' className="btn btn-primary shadow-primary m-1" data-dismiss="modal">No</button>
                  <button type="button" onClick={ResetDabase} className="btn btn-outline-warning waves-effect">Yes I Am</button>
              </div>
          </div>
      </div>
  </div>		




	<div className="row  ">
	<div className="col-sm-12">
								
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Restore Database</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       
      
        <div className="row">
        <div className="col-lg-12">
		  <div id="contentHere" >
    
          <div className="row mt-4">
        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
          <div className="card  shadow-primary">
            <div className="card-body p-4">

         
         <div className="card card-body text-white bg-primary shadow-primary">
        <p> <i className="fa fa-fw fa-info-circle"></i> 
           Make sure your database is backed up frequently. Click on Create backup to manually backup your database. The backups are stored in the [ /database/ ] folder located on root of your script files, the SQL database file can be downloaded from the below.</p>
			</div>
              
            <div className="card card-body text-white bg-secondary shadow-secondary">
            <div className="row">
            <div className="col-sm-4"><h5>Most Recent Backups</h5></div>
            <div className="col-sm-3"><input type="file" name="sql" id="sql"  onChange={handleFile('sql')} className={errors.sql  ? 'form-control formerror' : 'form-control'} accept=".sql" />
            
            </div>
            <div className="col-sm-5"><button type="button" onClick={UploadDatabase}  className="btn btn-primary btn-sm"> Upload</button>&nbsp;

            <a href="#!" data-toggle="modal" data-target='#infoDialog' title="reset Database" id="openModal" className="btn btn-warning btn-sm"> <i className="fa fa-refresh"></i> Reset</a>&nbsp;
            <a href="/general/database/backup"  className="btn btn-success btn-sm"><i className="fa fa-download"></i> Create Backup</a>

            </div>

            </div>

			</div>
            

      <div className="row mt-4">
        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
          <div className="card  shadow-primary">
            <div className="card-body p-4">
 
                 <div className="tab-content">
                 <div id="bookings" >
            <div className="table-responsive">
                    {notice.isDataFetching ? <TableLoader />:
                    
                    <table className="table">
                        <thead><tr>
                            <th>TITLE</th>
                            <th>DOWNLOAD</th>
                            <th>DELETE</th></tr>
                        </thead>
                        <tbody>
                            {backup.list &&backup.list.map((data, id)=>
                            <tr key={id}>
                                <td>{data.title}</td>
                                <td><a href={ServerUrl+'/downloadFile_controller.php?filepath='+data.location+'&token='+Token} className="btn btn-secondary"  download rel="noopener noreferrer" ><i className="fa fa-download"></i> DOWNLOAD</a></td>
                                <td>
                             <a href="#!" data-toggle="modal" className="btn btn-danger"  data-target={`#warningdialog-`+data.ID} title="Delete"><i className="fa fa-trash"></i>Delete </a>
    <WarningModal handleAction={()=>handleDeleteWithImage(data.ID, data.location)} mID={data.ID} />       
                                    </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    
                    }

            </div>

                 </div>

                   </div>   

            </div>
          </div>
        </div>
      </div>  
      </div></div></div></div>
      <div className="card card-body bg-secondary  shadow-secondary">
            <div className="row">
            <div className="col-sm-3"><h5 className="text-white">Restore Database</h5></div>

            <div className="col-sm-5"> <Select  options={
                            backup.list.map((ls, idx)=> {
                                return {key:idx, value: ls.location, label: ls.title}
                              })} 
 onChange={handleSelect}    name="backup" className={errors.filePath ? 'form-control formerror' : ''} value={backup.filePath}     />

            </div>
            <div className="col-sm-4">
            <a href="#!" onClick={RestoreDatabase}  className="btn btn-success"><i className="fa fa-download"></i> Restore Backup</a>

            </div>

            </div>

			</div>

          </div>
        </div>
      </div>
							</div>

							</div>
							</div>
	                   
                            </div>

		

                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 

 );
        
}

export default React.memo(Restore) 