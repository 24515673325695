import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../../component/notify'
import PageHeader from '../../component/pageheader'
import {config, Currency, FormatNumber, API_URL, selectStyles} from '../../component/include'
import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import TextEditor from '../../api/editor'
import Error403 from '../../settings/error403'
const ExtraCurricular =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false
        
    }); 

      const [contentEditor, setContentEditor] = useState('');
    const [exams, setExams]=useState([])
  const [isAuthenticated, setIsAuthenticated] = useState(true)
 
const initial ={
    code:'',
    title:'',
    term:{value:'', label:'', session:''},
    is_update:false,
    amount:'',
    status:'Active',
    studentClass:[],
    }
    const [fees, setFees]=useState(initial)

    const [allFees, setAllFees]= useState([])
   

    const handleEditorChange = (content, editor) => {
		setContentEditor(content);
	  }
    
    const fetchExams =()=>{
       
        let url = API_URL+'/exam/result/session/all';
        axios.get(url,  config).then(result=>{

            if(result.data.length!==0){

                try{

                setExams(result.data.data)

            }catch(e){
                    
            }
            }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }


        const fetchFees =()=>{
            setNotice({...notice, isDataFetching: true})  
       
            let url = API_URL+'/fees/display_extra';
            axios.get(url,  config).then(result=>{
    
                if(result.data.length!==0){
                    setAllFees(result.data.data)
                }
                
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, isDataFetching: false})  
            })
            }

const handleReset =()=>{
    setFees(initial)
setContentEditor('')
}
    

        

    const handleChange=(event)=>{
        let {name, value}=event.target

        if(name==='amount'){
            value = value.replace(/[^0-9]/g, '');
        }
        setFees({...fees, [name]:value});
        setErrors({...errors, [name]:''})
    }
    
    const handleSelect = (option, action)=>{
        setFees({...fees, [action.name]: option});
        setErrors({...errors, [action.name]:''})            
        
    };

    const handleSubmit = event =>{
        event.preventDefault();   
        
        
        let errors = {};   
    if(!fees.title){
        errors.title ='This field is required';
    } 
    if(fees.studentClass.length===0){
        errors.studentClass ='Please select class';
    }   


    if(!fees.term.value){
        errors.term ='Please select term';
    } 
    if(!fees.amount){
        errors.amount ='This field is required';
    } 
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

       submit()
    }


    }  




    const  submit=()=>{  
              
    setNotice({...notice,   isLoading: true})   

        var options = fees.studentClass
    var value = [];
    for (var i = 0, l = (options || []).length; i < l; i++) {
      value.push({classID:options[i].classID, sectionID:options[i].value});
    }


    const fd = new FormData(); 
    fd.append('code', fees.code);
    fd.append('title', fees.title);
    fd.append('amount', fees.amount); 
    fd.append('term', fees.term.value); 
    fd.append('session_id', fees.term.session);
    fd.append('status', fees.status); 
    fd.append("schoolClass", JSON.stringify(value, null, 2))
    fd.append('description', contentEditor);

              let url = API_URL+'/fees/create_extra'         
              axios.post(url, fd, config)
              .then(response =>{
                  if(response.data.status ===200){
                    Alerts('Saved!', 'success', response.data.message)
                } else{
                    setErrors({...errors, errorMessage: response.data.message})
            }  
            })
            .catch((error)=>{
                setErrors({...errors, errorMessage: error.message})
     
            }).finally(()=>{
                fetchFees()
                setNotice({...notice,  isLoading: false})  
        setFees(initial)

    })
     }    


     
     const handleDelete =(code)=>{
        let close =   document.getElementById('btnWarningDialog-'+code)
        close.click();
       
        setNotice({...notice, 
            isLoading: true}) 

    let url = API_URL+'/fees/extra/destroy/'+code;
          axios.get(url, config)
          .then(response =>{
           if(response.data.type ==='success'){
              Alerts('Saved!', 'default', response.data.message)
                  } else{
              Alerts('Error!', 'danger', JSON.stringify(response.data))
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              fetchFees()
              setNotice({...notice, 
                  isLoading: false
              })
          }) 
}

   

    const tableHeader = [
              
        
        {dataField: 'term_name', text: 'Term', editable:false},

        {dataField: 'title', text: 'Title'},
      {dataField: 'sections', text: 'Classes', editable:false, formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell!=='[]'?JSON.parse(cell).map(item=>item.sectionID+', '):''}</span>}, 

        {dataField: 'amount', text: 'Amount', validator: (newValue, row, column) => {
            if (!Number(newValue)) {
              return {
                valid: false,
                message: 'Fee  required a valid Number'
              };
            }
            return true;
          }, formatter:(cell)=>Currency+FormatNumber(cell) },
          {dataField: 'status', text: 'Status',  sort: true, editor: {
            type: Type.SELECT, options:[
                        {value:'Active', label:'Active'},
                        {value:'Inactive', label:'Inactive'}
            ] }, formatter: (cell, row)=>row.status==='Active'?<span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>},
              
          {dataField: 'id', text: 'Action',  formatter: (cell, row)=><div>
            
		
            <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${row.code}`}  className="btn btn-danger btn-sm"    title="Delete"><i className="icofont icofont-delete-alt"></i></a>&nbsp;

		
<button type='button' onClick={()=>handleModify(row)} className="btn btn-warning btn-sm"   > <i className="fa fa-edit"></i>  Modify </button>
		


          <WarningModal  message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> </div>, editable: false}
     ];

    

     const TableRecord=(props)=>{

        const expandRow = {
            
            renderer: row => (
               
                <div>
                    <p className='text-success text-bold' dangerouslySetInnerHTML={{__html:row.description}}></p>
             
            </div>),
            showExpandColumn: true,
            expandByColumnOnly: true,
            expandColumnPosition: 'left',
            onlyOneExpanding: true,

            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                  return <i className="f-20 fa fa-minus-square text-success"></i>
                }
                return <i className="f-20 fa fa-plus-square text-success"></i>
              },
              expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                  return <i className="f-20 fa fa-minus-square text-success"></i>
                }
                return <i className="f-20 fa fa-plus-square text-success"></i>
              }
            
          } 

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );

        const handleUpdate=(column, newValue, code)=>{       
            const fd = new FormData(); 
                fd.append('newValue', newValue);
                fd.append('column', column.dataField);
                fd.append('code', code);
              
               let url = API_URL+'/fees/extra_curricular/update'
              axios.post(url, fd, config)
              //.then(result => console.log(result.data))
              .then()
              .catch((error)=>console.log(error)) 
              
            } 
         const { SearchBar } = Search;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='id' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                 <div className="btn-group dropdown-split-success pull-right">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'50px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     expandRow={expandRow }
                                     cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                                         
                              beforeSaveCell: (oldValue, newValue, row, column) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.code);
                                
                               return '';
                              }
                              
                            }
                                      }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      

const handleModify =(row)=>{

        const getTerm =(code, field) =>{
        let rs =  exams.filter(item=>item.code===code)
            return rs.length!==0?rs[0][field]:''
        } 


        let options = JSON.parse(row.sections)
        
        var value = [];
    for (var i = 0, l = (options || []).length; i < l; i++) {
      value.push(
        {value:options[i].sectionID, label:options[i].sectionID});
    }

   
        setFees({
                code:row.code,
                title:row.title,
                term:{value:row.term_code, label:getTerm(row.term_code, 'term'), session:getTerm(row.term_code, 'session')},
                is_update:true,
                amount:row.amount,
                status:row.status,
                studentClass:value,
                
        })
        setContentEditor(row.description)
        window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})

    }

useEffect(()=>{
    fetchExams()
    fetchFees()
},[]);



        return (  <>
        
        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
   {isAuthenticated?  <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Extra Curricular">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Finance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Extra Curricular</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Setup  New Fees</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <form method='post' onSubmit={handleSubmit}> 
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
     	
      <div className="form-group">
<label className='text-bold text-primary'>Item title <span className='text-danger'>*</span></label>
<input type="text" required  className={errors.title ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} name="title"  onChange={handleChange} value={fees.title} />
<span className='text-danger'>{errors.title}</span>
   </div>




      <div className="form-group">
         <label className='text-bold text-primary'> Applicable Term<span className='text-danger'>*</span></label>
         <Select  options={exams.map((list, id)=>{
             return {key:id, value:list.code, label:list.term, session:list.session}
         })} 
         getOptionLabel={option =>option.session + ' '+  option.label}               
 onChange={handleSelect} className={errors.term ? 'form-control form-control-danger' : ''} name="term" value={fees.term} styles={selectStyles}  />
 <span className='text-danger'>{errors.term }</span>
            </div>
   
            <div className="form-group">
         <label className='text-bold text-primary'> Status</label>
         <div className="form-radio">
                <div className="radio radio-inline"><label title='Active'>
						<input type="radio" checked={fees.status ==="Active"} onChange={handleChange} value="Active" name="status" />
										<i className="helper"></i><i className="fa fa-unlock fa-2x"></i></label></div>

                                        <div className="radio radio-inline"><label title='Inactive'>
						<input type="radio" checked={fees.status ==="Inactive"} onChange={handleChange} value="Inactive" name="status" />
										<i className="helper"></i><i className="fa fa-lock fa-2x"></i></label></div>

								</div>
            </div>

 
</section>





<section className="col-md-6">
<div className="form-group">
						<label className='text-bold text-primary'> Applicable Classes <span className='text-danger'>*</span></label>
                        <Select isMulti options={[
                            {value:'Creche', label:'Creche'},
                            {value:'Pre-Nursery', label:'Pre-Nursery'},
                            {value:'Nursery', label:'Nursery'},
                            {value:'Primary', label:'Primary'},
                            {value:'Junior Secondary School', label:'Junior Secondary School'},
                            {value:'Senior Secondary School', label:'Senior Secondary School'},
                        ]
							 
                        } 
onChange={handleSelect} className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" styles={selectStyles} value={fees.studentClass}  /> <span className='text-danger'>{errors.studentClass }</span>			
											</div>

           

   <div className="form-group">
<label >Amount <span className='text-danger'>*</span></label>
<input type="number" pattern='[0-9]' min={0} step={1} className='form-control form-control-lg' required  name="amount" onChange={handleChange} value={fees.amount} />
   </div>

</section>



<div className="col-md-12">

<label  className='text-primary text-bold'>Description of Activity</label>
                       
                        <TextEditor
					height={200}
					
					value={contentEditor}
					onEditorChange={handleEditorChange}        
				  /> 
                    
</div>
</div>

 </div> </div> 
							
</div>

</div>   

     
<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" onClick={handleReset} className="btn btn-inverse "><i className="fa fa-refresh"></i> Clear</button>&nbsp;&nbsp;
	<button type="submit"    className={fees.is_update?'btn btn-success':'btn btn-primary '}><i className="fa fa-save"></i> {fees.is_update?'Update Fees':'Create Fees'} </button>
								</footer>		</section>		
							</div>
                            </form>
     </div>  </div>
							
                            </div>
                            
                            </div>

                            <div className="row" >

                            <div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Fees</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12">
        <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={allFees}   columns={tableHeader}  />} 
</div>
	</div>
    </div></div>
</div>
</div>
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ExtraCurricular) 