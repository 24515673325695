import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, Currency, config, schoolName} from '../component/include'
import { FormatNumber, longDate, getClassName} from '../component/globalFunction'

const PrintVoucher =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [voucher, setVoucher] = useState({
        paymentTo:'',
        paymentFrom:''

    })
    const [result, setResult]=useState([])
    const [total, setTotal]=useState([])
    const [schoolClass, setSchoolClass]=useState([]);
    const [schoolInfo, setSchoolInfo]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
   
    

    const  handleFetchClass =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
        axios.post(url, fd, config)
        .then(result => setSchoolClass(result.data))
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
            
    }



    const fetchSchoolInfo =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

    }


    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(voucher));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(!values.paymentFrom){
            errors.paymentFrom ='Please select date from';
        } 
        if(!values.paymentTo){
            errors.paymentTo ='Please select date to';
        }    
        return errors;
        }

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        fetchResult()
        }
        },[errors])


const handleChange = event =>{
    const {name, value} = event.target
    setVoucher({...voucher, [name] : value });
   setErrors({...errors, [name]:''})
   setResult('')
}

const fetchResult =()=>{
    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT s.admissionNumber,  s.studentName, CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, p.amount, p.classID, p.sectionID, p.datePaid, p.paymentMode, p.code, p.receiptNo  from tbl_fees_payment_history p, tbl_students s, tbl_fees_charge f  where s.code = p.studentCode and f.code = p.feesCharge and p.datePaid between  '"+voucher.paymentFrom+"' and ' "+voucher.paymentTo+"' order by p.datePaid ASC"
    
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0){
setResult(result.data)
var amount = 0;
        result.data.map(dat=>amount+=Number(dat.amount))
        setTotal(amount)
    }else{
        Alerts('Info!', 'info', 'There are no record found')
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
}



useEffect(()=>{
    fetchSchoolInfo()
    handleFetchClass()
},[]);

        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Fees Payment Report">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Fees Payment Report</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Fees Payment</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">
        
                      

      <section className="col-md-4">
									

        <div className="form-group">
                    <label>From </label>
                    <input type='date' name="paymentFrom" className={errors.paymentFrom?'form-control form-control-danger':'form-control'} onChange={handleChange} value={voucher.paymentFrom} />
<span style={{color:'red'}}>{errors.paymentFrom && errors.paymentFrom}</span>
                                        </div>
                    </section>
            <section className="col-md-4">
									

        <div className="form-group">
                    <label>To </label>
                    <input type='date' name="paymentTo" className={errors.paymentTo?'form-control form-control-danger':'form-control'}  onChange={handleChange} value={voucher.paymentTo} />
                    <span style={{color:'red'}}>{errors.paymentTo && errors.paymentTo}</span>                 </div>
                 </section>

                <section className="col-md-4">
	<br/>
	<button type="button"  onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Payment</button>
    </section>			
    </div>	</div>
                            </div>
							</div></div>
</div>

</div>
	


{result.length!==0?
<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
               <div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
</td>
        </tr>
    </tbody>
</table>

</div> 
               )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Fee's Collection</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Date: <b>{longDate(voucher.paymentFrom)+' - '+longDate(voucher.paymentTo)}</b></td>
                </tr>
            </tbody>
       </table>
        <table className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>SN</th>
                <th>Date</th>
                <th>Receipt No</th>
                <th>Fees</th>
                <th>Student</th>
                <th>Class</th>
                <th>Mode</th>
                <th>Amount ({Currency})</th>

                </tr>
            </thead>
            <tbody>{result.length!==0?result.map((row, idx)=> 
                <tr key={idx}>
                    <td>{idx+1}</td>
                    <td>{longDate(row.datePaid)}</td>
                    <td>{row.receiptNo}</td>
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.fees}</td>
                <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.admissionNumber} - {row.studentName} </td>

                    <td>{getClassName(row.classID, row.sectionID, schoolClass)}</td>
                    <td>{row.paymentMode}</td>
                    <td>{FormatNumber(row.amount)}</td>

                </tr>):[]}
            </tbody> 
            <tfoot>
                <tr>
                    <td className="text-right" colSpan="7"><b>Total</b> </td>
                    <td><b>{FormatNumber(total)}</b></td>
                </tr>
            </tfoot>
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">

        <form method='POST' action={ServerUrl+'/printout/payment_summary_datewise.php?token='+Token} target='_blank'>
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='total' value ={FormatNumber(total)} />
                <input type='hidden' name='dateRange' value ={longDate(voucher.paymentFrom)+' - '+longDate(voucher.paymentTo)} />

        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>

        </div></div>

 </div>
        </div>:''}
   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(PrintVoucher) 