import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../../component/loader'
import axios from 'axios'
import { Alerts} from '../../component/notify'
import PageHeader from '../../component/pageheader'
import Select from 'react-select'
import {ServerUrl, ImagesUrl, Token, Currency, config, schoolName, API_URL} from '../../component/include'
import { longDate, FormatNumber, formatGroupLabel, getClassName } from '../../component/globalFunction'
import { userToken } from '../../component/authentication'

import { useDispatch, useSelector } from 'react-redux'

const ExtraReport=()=>{
	const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [allFamily, setAllFamily]= useState([])
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);

    const [studentList, setStudentList] = useState([]);
    const [errors, setErrors] = useState({});
    const [payment, setPayment] =  useState([]);
const [term, setTerm] = useState([]);
const [title, setTitle] = useState([]);
    const [studentFee, setStudentFee]=useState({
        studentClass:{value:'', label:''},
        term:{value:'', label:''},
        title:{value:'', label:'', term:''},
        admissionNumber:'',
        family:{value:'', label:''},
        payment_mode:{value:'', label:''},
        reference:'',
        date_paid:'',
        student:{value:'', label:'', studentName:''}
    });
    

    const fetchExtras =()=>{
        setNotice({...notice, isLoading: true})  
        let url = API_URL+'/fee/exras/extra_report_data';
        axios.get(url,  config).then(result=>{
            if(result.data.length!==0){
                setTitle(result.data.title)
                setTerm(result.data.term)
                setStudentList(result.data.student)
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false})  
        })
        }


   

const fetchResult =()=>{
	setNotice({...notice, isLoading: true}) 
	

    const fd = new FormData();
    fd.append('admissionNumber', studentFee.admissionNumber);
    fd.append('reference', studentFee.reference);
    fd.append('date_paid', studentFee.date_paid);

    fd.append('term', studentFee.term.value);
    fd.append('title', studentFee.title.value);
    fd.append('family', studentFee.family.value);
    fd.append('sectionID', studentFee.studentClass.value);
    fd.append('payment_mode', studentFee.payment_mode.value);
    fd.append('student', studentFee.student.value);

let url = API_URL+'/fees/extras/report'

axios.post(url, fd, config).then(result=>{
if(result.data.payment.length!==0){
    setPayment(result.data.payment)
    structure.current.scrollIntoView({ behavior: "smooth" });
}else{
    setPayment([])
    Alerts('Info!', 'info', 'There are no payment record found')
}


})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}

const handleChange = event =>{
	 let {name, value} = event.target
  setStudentFee({...studentFee, [name]:value})
}

const handleSelect = (option, action)=>{
	setStudentFee({...studentFee, [action.name]: option});
    setErrors({...errors, [action.name]:''})
 }




  const handleReset =()=>{
setStudentFee({
    studentClass:{value:'', label:''},
    term:{value:'', label:''},
    title:{value:'', label:'', term:''},
    admissionNumber:'',
    family:{value:'', label:''},
    payment_mode:{value:'', label:''},
    reference:'',
    date_paid:'',
    student:{value:'', label:'', studentName:''}
  })
  setPayment([])
  }
   



const fetchFamily =()=>{
    const fd = new FormData();
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetch_controller/tbl_family'
    axios.post(url, fd, config).then(result=>setAllFamily(result.data))
}


useEffect(()=>{ 
    fetchExtras()
    fetchFamily()
},[]);

   return ( <>
			
			{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Extra-Curricular Payment History">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                            <li className="breadcrumb-item"><a href="#!">Extra-Curricular</a> </li>
                   
                    <li className="breadcrumb-item"><a href="#!">Payment</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Payment History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">
        <section className="col-md-4">
    
        <div className="form-group">
        
        <label className='text-bold'>Search By Term </label>
        <Select  options={term&&term.map((list, idx)=> {
                                       return {key:idx, value: list.term_code, label:list.term_name }
                                     })
                                } 
                                     
        onChange={handleSelect}  name="term" value={studentFee.term}  />  
                    
                    </div>

                    <div className="form-group">
        

        <label className='text-bold'> Search By Activity</label>
        <Select  options={title&&title.map((list, idx)=> {
                                       return {key:idx, value: list.xtra_code, label:list.title, term:list.term_name }
                                     })
                                }        
                                getOptionLabel={option =>`${option.term}  ${option.label}`} 
        onChange={handleSelect}  name="title" value={studentFee.title}  />  
                     
                    </div>

                    <div className="form-group">
         <label className='text-bold'> Search By Admission Number </label>

         <input type="text"  className='form-control' onChange={handleChange} name="admissionNumber" value={studentFee.admissionNumber} />

		
            </div>

			</section>


           <section className="col-md-4">
                    <div className="form-group">
         <label className='text-bold'> Search By Student Class</label>
            <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} name="studentClass" value={studentFee.studentClass}  /> 

            </div>

            <div className="form-group">
         <label className='text-bold '> Search By Family</label>
         <Select  options={
                            allFamily&&allFamily.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.familyName} 
							 })
							 
                        } 
onChange={handleSelect}   name="family" value={studentFee.family}  />

            </div>


            <div className="form-group">
         <label className='text-bold '> Search By Payment Method </label>
         <Select  options={[
                    { value: 'CASH', label: 'CASH' },
					{ value: 'BANK', label: 'BANK'},
					{ value: 'CHEQUE', label: 'CHEQUE'},
					{ value: 'OTHERS', label: 'OTHERS'},
                ]} 
			onChange={handleSelect}  name="payment_mode" value={studentFee.payment_mode}  />

            </div>

                            </section>



            <div className="col-md-4">
            <div className="form-group">
         <label className='text-bold'> Search By Receipt No </label>

       
         <input type="text"  className='form-control' onChange={handleChange} name="reference" value={studentFee.reference} />
            </div>


            <div className="form-group">
         <label className='text-bold'> Search By Date of Payment </label>

         <input type="date"  className='form-control' onChange={handleChange} name="date_paid" value={studentFee.date_paid} />

            </div>


            <div className="form-group">
						<label className='text-bold'>Select Student <span style={{color:'red'}}>*</span></label>
						<Select  options={
                            studentList&&studentList.map((list, idx)=> {
                               return {key:idx, value: list.code, fatherName:list.fatherName, mobile:list.father_mobile, label: list.admissionNumber, studentName:list.studentName, fatherEmail:list.fatherEmail }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName }

onChange={handleSelect}  name="student" value={studentFee.student}  /> 
											</div>
              </div>


									</div>
										<hr/>
                <div className="row">
                <section className="col-md-12 pull-right">
<button type="button"  onClick={handleReset} className="btn btn-danger "><i className="fa fa-refresh"></i> Reset</button> &nbsp;


<button type="button"  onClick={fetchResult} className="btn btn-inverse "><i className="fa fa-search"></i> Search Record</button>
</section>	                </div>
							</div>
							</div>

							</div>
                </div>





  {payment.length!==0?
<div className="card z-depth-0">
		<div className="card-block">
        {schoolInfo&&schoolInfo.map((st, id)=>
               <div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
</td>
        </tr>
    </tbody>
</table>

</div> 
               )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Extra-Curricular Payment </b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">
<tr>
                <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
        <table className="table table-striped table-bordered  table-full-width">
    
            <thead>
                <tr>
                    
                <th>Class</th>
                <th>Student</th>
                <th> Activity</th>
                <th> Payment Mode</th>
                <th> Amount ({Currency})</th>
                <th> Date Paid</th>
                </tr>
            </thead>
            <tbody >
					{payment.length!==0?payment.map((dat, id)=>{
					return	<tr key={id}>
                        
					<td>{getClassName(dat.classID, dat.sectionID, schoolClass)}</td>
					<td>{dat.admissionNumber+' - '+ dat.studentName}</td>
					<td>{dat.term_name+' '+dat.title}</td>
                    <td>{dat.payment_mode}</td>
					<td>{FormatNumber(Number(dat.amount))}</td>
                    <td>{dat.date_paid}</td>
						</tr>
					}):[]}
				</tbody>
                <tfoot>
                <tr>
                    <td className="text-right" colSpan={4}><b>Total</b> </td>
                    <td colSpan={2}><b >{FormatNumber(payment.reduce((acc, item) => Number(acc) + Number(item.amount), 0))}</b></td>
                </tr>
                </tfoot>
        </table>
 </div>	 </div> 
 <div ref={structure} />
 <div className="row">
        <div className="col-md-12">


        <form method='POST' action={ServerUrl+'/printout/payment_extra_report.php?token='+userToken} target='_blank'>
                <input type='hidden' name='payment' value ={JSON.stringify(payment, null, 2)} />
                <input type='hidden' name='total' value ={payment.reduce((acc, item) => Number(acc) + Number(item.amount), 0)} />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>

        </div></div>

 </div>
        </div>:''}

          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</> 
 );
        
}

export default React.memo(ExtraReport) 