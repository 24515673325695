import React, {useState, useEffect, useCallback} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {API_URL, ServerUrl, Token, config} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getClassArm} from '../component/globalFunction'
import Error403 from '../settings/error403'
import { useSelector } from 'react-redux'

 const ResultEntryExtras =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    const [isAuthenticated, setIsAuthenticated] = useState(true);
  

    const [assesmentGrade, setAssesmentGrade]=useState([])
    const [result, setResult] = useState({
        studentClass:[],
        student:{value:'', label:'', studentName:''},
        examtype:[],
        classAssessment:[],
        isMusic:false,
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'},
    })

    const steps = [{title: 'Assessment Setup'}, {title: 'Score Entry'}]
   
    const [examtype, setExamtype]=useState([])
    const [assessment, setAssessment]=useState([])
    const [category, setCategory]=useState([])
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [classAssessment, setClassAssessment]= useState([]) 
    const [studentList, setStudentList]=useState([])
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [students, setStudents]=useState([])
    const [musicTemplate, setMusicTemplate]=useState([])

const [activeStep, setActiveStep] = useState(1);

const handleOnClickStepper = (step) => {
        setActiveStep(step); 
    }

    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep])

  
   
    


    const fetchExamType =()=>{
        let url = API_URL+'/exam/assessment_result/all';
        axios.get(url,  config).then(result=>{
 
            if(result.data.assesstype.length!==0){
                let ans = result.data.assesstype.filter(item=>item.publish ==='No')
                setExamtype(ans)
        
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }



        const fetchAssementGrade =()=>{
       
            let url = API_URL+'/assessment/settings/index';
            axios.get(url,  config).then(result=>{
                if(result.data.length!==0){
    
                    try{
                    let categor = result.data.data[0].category
                    let assessmen = result.data.data[0].assessment
                    let assesmentGrad = result.data.data[0].assessmentGrade
    
        setCategory(JSON.parse(categor))
        setAssessment(JSON.parse(assessmen).sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1))
        setAssesmentGrade(JSON.parse(assesmentGrad).sort((a, b) =>(a.rateKey > b.rateKey) ? 1 : -1))
    
    
            }catch(e){
                                
            }
                    }
            
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
            }



            const fetchMusicTemplate =()=>{
                const fd = new FormData();
                fd.append('jwt', Token);
                let url = ServerUrl + '/fetch_controller/tbl_music_template';
                axios.post(url, fd, config)
                .then(result =>setMusicTemplate(result.data))
            }

const fetchMainCategory =()=>{
    var sql ="SELECT  assessment from tbl_cummulative_standard_settings where ID="+1;
const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
if(result.data.length!==0){
    try{
var assessmen = result.data[0].assessment
setAssessment(JSON.parse(assessmen).sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1))

    }catch(e){

    }
}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
}



const getAssessmentName =(code, field)=>{
   
    const result = assessment.filter(item=>item.code===code)
    const answer = result.length!==0?result[0][field]:''
    return String(answer)
}


const getAssessmentMusic =(code, field)=>{
    const msk = musicTemplate.filter(item=>item.code===code)
    const answer = msk.length!==0?msk[0][field]:''
    return String(answer)
}


const fetchClassAssessment =(type, section)=>{
setNotice({...notice, isLoading: true}) 
var sql ="SELECT  assessmentCode  from tbl_assessment_result where examCode ='"+type+"' and sectionID ='"+section+"' group by assessmentCode" ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
if(result.data.length!==0){

    var option = result.data
    var response = []
    for (var i = 0; i < option.length; i++){
        response.push(
            {
                code:option[i].assessmentCode,
                categoryCode:getAssessmentName(option[i].assessmentCode, 'categoryCode'),
                assessmentName:getAssessmentName(option[i].assessmentCode, 'assesmentName')

            })
    }
    setClassAssessment(response)
}else{
    setClassAssessment([])
}

})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
setNotice({...notice, isLoading: false})
})
}




const fetchStudentList =()=>{
    let errors = {};   
   
    if(result.studentClass.length===0){
        errors.studentClass ='Please select class';
    }   
    if(result.examtype.length===0){
        errors.examtype ='Please select exam type';
    } 
 
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){
setNotice({...notice, isLoading: true}) 


var sql ="SELECT s.admissionNumber, s.registerNumber, s.studentName, s.code as studentCode, a.grade, a.code, a.assessmentCode from tbl_students s,  tbl_assessment_result a where s.code = a.studentCode  and s.admissionStatus ='Studying' and a.examCode ='"+result.examtype.value+"' and a.sectionID ='"+result.studentClass.value+"' order by s.studentName"


if(result.studentClass.value==='01wu4ylt9' && result.examtype.term==='THIRD TERM'){
    fetchMainCategory();

    sql ="SELECT s.admissionNumber, s.registerNumber, s.studentName, s.code as studentCode, a.grade, a.code, a.assessmentCode from tbl_students s,  tbl_cummulative_assessment_result a where s.code = a.studentCode  and s.admissionStatus ='Studying' and a.examCode ='"+result.examtype.value+"' and a.sectionID ='"+result.studentClass.value+"' order by s.studentName"
    }


if(result.isMusic){
    sql ="SELECT s.admissionNumber, s.registerNumber, s.studentName, s.code as studentCode, a.grade, a.code, a.assessmentCode from tbl_students s,  tbl_music_result a where s.code = a.studentCode  and s.admissionStatus ='Studying' and a.examCode ='"+result.examtype.value+"' and a.sectionID ='"+result.studentClass.value+"' order by s.studentName"
}




const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    if(response.data.length!==0 && Array.isArray(response.data)){
        const list = response.data
        const onlyStudent =	list.map(e=>e['studentCode'])
        .map((e,i,final)=>final.indexOf(e)===i&&i)
        .filter(e=>list[e])
        .map(e=>list[e])

        
setStudents(onlyStudent)
setStudentList(response.data)
setActiveStep(2)
    }
    else{
        setStudentList([]) 
    }

})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
setNotice({...notice, isLoading: false})

})
    }
}

  
const handleSelectExam = (option, action)=>{
    setResult({...result, [action.name]: option, studentClass:[] });
   setErrors({...errors, [action.name]:''})
   setStudentList([])
  
}


const handleSelectStudent = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
}

const handleSelect = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})



}




const handleChangeScore =(event, index)=>{
   

    const otherStudent = studentList.filter(item=>item.studentCode!==result.student.value)
    const oneStudent = studentList.filter(item=>item.studentCode===result.student.value)

    const student =JSON.parse(JSON.stringify(oneStudent))
    const {name, value} = event.target
    student[index][name] = value; 
    setStudentList(otherStudent.concat(student)); 
       } 



 
const handleSubmit = event =>{
    event.preventDefault();    
    
    let errors = {};   
    if(studentList.length===0){
        errors.studentClass ='There are no student in the selected class';
    } 
    if(result.studentClass.length===0){
        errors.studentClass ='Please select class';
    }   
    if(result.examtype.length===0){
        errors.examtype ='Please select exam type';
    } 
    if(result.student.value===''){
        errors.student ='Please select student';
    } 
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

       submit()
    }

}




    function submit(){
    
setNotice({...notice,  isLoading: true})    

    const fd = new FormData(); 
    fd.append('jwt', Token);
   fd.append('assessment', JSON.stringify(studentList, null, 2));


    let url = ServerUrl+'/update_controller/tbl_assessment_result';


   //if nursery then update cummulative result here
  if(result.studentClass.value==='01wu4ylt9' && result.examtype.term==='THIRD TERM'){
        url = ServerUrl+'/update_controller/tbl_cummulative_assessment_result';
       }

          if(result.isMusic){
           url = ServerUrl+'/update_controller/tbl_music_result';
          }


        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='info'){
                Alerts('Updated!', 'default', response.data.message)
                    } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                
                  setNotice({...notice,  isLoading: false })
                  
                setIsSubmitting(false);
                  setActiveStep(1)
              })   
  }
    







    const handleReset=()=>{
        setResult({ 
            studentClass:[],
            examtype:[],
            student:{value:'', label:'', studentName:''},
            orderBy:{value:'order by s.studentName ASC', label:'order by Name'}
            })
    setStudents([])
            setStudentList([])
    }
    useEffect(()=>{
        fetchExamType()
        fetchAssementGrade() 
        fetchMusicTemplate()
    },[]);
  
        return (
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }

        <PageHeader title="Assessment Entries">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Assessment  Result </a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Assessment Result Entry</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '1px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="examType">
		  
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

      
   <div className="card-block">
      
  <div className="row">
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Exam Type <span style={{color:'red'}}>*</span></label><a href="/exam/result_setup" className="pull-right" >Add more exam</a>
         <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam, term:list.term }
                             })
                        } 
                       
onChange={handleSelectExam} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

            </div>
     
     </section>
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label><a href="/setup/school_classes" className="pull-right" >Add more classes</a>
        <Select  options={
                              schoolClass.map((list, idx)=> {
                                return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                    return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                                }) }
                              })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
     
     </section>

     <section className='col-md-6'>
     <div className="form-group">
 <div className="checkbox-color checkbox-primary">
                     <input id='isMusic' name="isMusic" value={result.isMusic} type="checkbox" onChange={()=>setResult({...result, isMusic:!result.isMusic})} checked={result.isMusic}  /> 
                     <label htmlFor='isMusic'>Music Assessment</label>
                     </div>
   </div>
     </section>
     </div>

     <div  className="row"> 
     
     <section className="col-md-12"> 
     <footer className="pull-right">
	<button type="button" onClick={handleReset} className="btn btn-inverse "><i className="fa fa-refresh"></i> Reset</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={fetchStudentList} className="btn btn-primary "><i className="fa fa-graduation-cap"></i> Display Student</button>
    </footer>						
     </section>

     </div> 
    </div>
                               
       </div>	     
       
       	</div>
       
       </div>	
			
       </div>
    
</div> :
<div className="row setup-content" id="extras">
		    
    {!result.isMusic?   <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">

   <div className="row">
   <section className="col-md-12">
     <div className="form-group">
						<label>Select Student <span style={{color:'red'}}>*</span></label>
						<Select  options={
                            students&&students.map((list, idx)=> {
                               return {key:idx, value: list.studentCode, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelectStudent} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={result.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
                                            </section> 



   <div className="table-responsive">
       
        <table className="table">
            <thead>
                <tr>
                <th>SN</th> 
                    <th style={{maxWidth:'600px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>Assessment Name</th>
                    <th>Grade</th>   
                </tr>
            </thead>
            <tbody>

                {studentList.length!==0?studentList.filter(item=>item.studentCode===result.student.value).map((st, idx)=> 
                <tr key={idx}>
<td>{idx+1}</td>
                    <td style={{maxWidth:'600px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getAssessmentName(st.assessmentCode, 'assesmentName')} </td>
                    <td>


                  
                        <select name="grade" value={st.grade}  className="form-control" onChange={(e)=>handleChangeScore(e, idx)}   >

                        <option  value=''>Select</option>

                        {assesmentGrade&&assesmentGrade.filter(item=>item.group===getClassArm(result.studentClass.classtype)).map((item,index)=>
                        <option key={index} value={item.rateKey} dangerouslySetInnerHTML={{__html:item.rateKey+' - '+item.assesmentGrade}}></option>)}
                        </select>

                        <textarea name='grade' placeholder='Enter Comment if Applicable' value={st.grade} className="form-control" onChange={(e)=>handleChangeScore(e, idx)} rows={3}>{st.grade}
    
    </textarea>
                    </td>
                </tr>):[]}
            </tbody> 
        </table>
 </div>	 
 
 
 
 
 </div>   
<div style={{color:'red'}}>{errors.classAssessment && errors.classAssessment}</div>
<div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
<div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
       </div>			
       </div>       
       </div>:



       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">

   <div className="row">
   <section className="col-md-12">
     <div className="form-group">
						<label>Select Student <span style={{color:'red'}}>*</span></label>
						<Select  options={
                            students&&students.map((list, idx)=> {
                               return {key:idx, value: list.studentCode, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelectStudent} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={result.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
                                            </section> 



   <div className="table-responsive">
       
        <table className="table">
            <thead>
                <tr>
                <th>SN</th> 
                    <th>Assessment Name</th>
                    <th>Grade</th>   
                </tr>
            </thead>
            <tbody>
                {studentList.length!==0?studentList.filter(item=>item.studentCode===result.student.value).map((st, idx)=> 
                <tr key={idx}>
<td>{idx+1}</td>
                    <td >{getAssessmentMusic(st.assessmentCode, 'assessment_name')} </td>
                    <td>


                  
                        <select name="grade" value={st.grade}  className="form-control" onChange={(e)=>handleChangeScore(e, idx)}   >

                        <option  value=''>Select</option>
                        <option  value='4'>4</option>
                        <option  value='3'>3</option>
                        <option  value='2'>2</option>
                        <option  value='1'>1</option>
                        </select>
                    </td>
                </tr>):[]}
            </tbody> 
        </table>
 </div>	 
 
 
 
 
 </div>   
<div style={{color:'red'}}>{errors.classAssessment && errors.classAssessment}</div>
<div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
<div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
       </div>			
       </div>       
       </div>}
      {/*  music result endhere  */}

</div>}

<button onClick={activeStep === steps.length ? handleSubmit: handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Update Result </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }

                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>



{/* 

                <div className="row  ">
								<div className="col-sm-6">
	<div className="card z-depth-0">
		<div className="card-block">
		<fieldset>
			<div className="row">
									
			<section className="col-md-12 ">
			<div className="well well-sm alert alert-success border-success background-success ">
					<h4 className="alert-heading ">Download Student File</h4>
					
<p><span >The first line in downloaded csv file should remain as it is. Please do not change the order of columns.</span><br/>
<span >The correct column order is (<b>
    Result Code, Admission Number, Student Name, Grade </b>)</span> <span >&amp; you must follow this.</span></p>
					<hr className="simple" /><div className="text-align-left">
						
                    <form method='POST' action={ServerUrl+'/csvSample_controller/assesment'} target='_blank'>
        <input type='hidden' name='classAssessment' value ={result.classAssessment.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='className' value ={result.examtype.label +' '+ result.studentClass.label+' - '+result.classAssessment.label} />
                <input type='hidden' name='orderBy' value ={result.orderBy.value} />
                
                <input type='hidden' name='jwt' value ={Token} />
               
        <button type="submit" className="display-none"  id="exportExcel"> </button>
       
     <button type="button" onClick={DownloadStudentList} className="btn btn-sm btn-inverse"><i className="fa fa-download"></i> Download File</button>
        </form>
        
					</div>
				</div>					
				</section>
										</div>
										
				</fieldset>
				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-6">
	<div className="card z-depth-0">
	
		<div className="card-block">


		<div className="alert alert-success border-success">
						<fieldset className="well well-sm">
                        <h4 className="alert-heading ">Upload file with .csv extension</h4>
							<section>
                            <div className="form-group">
							
                    <input id="studentCsv"  onChange={handleFile('studentCsv')} className="form-control" name="studentCsv" type="file" accept=".csv" /> 
						<div className="note" style={{color:'red'}}>
			{csvFile.fileName}
		</div>	
										</div>          
                                        
							</section>
						</fieldset></div>
        <footer>
            <button type="button" id="submit" onClick={handleSubmitFile} className="btn  btn-primary pull-right"><i className="fa fa-save"></i> Upload Student Result</button>
        </footer>

	</div>

</div>

</div></div> */}


                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</>  );
        
}

export default React.memo(ResultEntryExtras) 