import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getClassName, longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const FamilyList =()=> {
    const structure = useRef(null)
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
   
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [allResult, setAllResult]=useState([])
    const [allFamily, setAllFamily]= useState([])
    const [result, setResult] = useState({
        studentClass:[],
        family:[],
        orderBy:{value:" and admissionStatus = 'Studying' ", label:'Order by Studying'}
    })
  

    const [termination, setTermination]=useState([])

const handleSelect = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
  
}

const getStudent = (code, table) =>{   
        let answer = [];

    const rs = table.filter(list=>list.family_name===code);
for (var i = 0; i < rs.length; i++){
    answer.push(
        {
            studentName:rs[i].studentName,
            termination:rs[i].termination,
            termination_date:rs[i].termination_date,
            admissionNumber:rs[i].admissionNumber,
            classID:rs[i].classID,
            sectionID:rs[i].sectionID,
            admissionStatus:rs[i].admissionStatus,
        })

    }
   
     return  JSON.stringify(answer, null, 2);
   }


const fetchResult =()=>{
        setNotice({...notice, isLoading: true}) 

        
var sql ="SELECT f.code, f.familyName, f.studentList, f.username, s.admissionStatus, s.admissionNumber, s.gender, s.registerNumber, s.studentName, s.termination, s.termination_date, s.termination_remark, s.sectionID, s.classID, s.email, s.fatherName, s.father_mobile, s.fatherEmail, s.mother_mobile, s.motherEmail, s.altMobile, s.mobile, s.currentAddress, s.family_name FROM tbl_students s, tbl_family f  where f.code = s.family_name and family_name <> '' "



if(result.studentClass.length!==0 ){
    sql = sql + '  and s.sectionID = "'+result.studentClass.value+'" '
}

if(result.family.length!==0 ){
    sql = sql + '  and s.family_name = "'+result.family.value+'" '
}


sql = sql + result.orderBy.value 


const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    if(response.data.length!==0){

//Get all the unique family
let list = response.data;
 
let unique_family = list.map(e=>e['code'])
.map((e,i,final)=>final.indexOf(e)===i&&i)
.filter(e=>list[e])
.map(e=>list[e])



var res = []
    for (var i = 0; i < unique_family.length; i++){
        res.push(
            {
                ...unique_family[i],
                student:getStudent(unique_family[i].code, list),

            })
    }

setAllResult(res)
structure.current.scrollIntoView({ behavior: "smooth" });

    }else{
        setAllResult([])
        Alerts('Info!', 'info', 'There are no student found for the selected family or class')
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
}) 
    
}



  const fetchOccupation =()=>{
    var sql ="SELECT  terminationReason from  tbl_student_master where ID="+1;
  const fd = new FormData();
  fd.append("sql", sql);
  fd.append('jwt', Token)
  let url = ServerUrl+'/fetchBySql_controller';
  axios.post(url, fd, config).then(result=>{
  if(result.data.length!==0){
  setTermination(JSON.parse(result.data[0].terminationReason))
  }
  })
  .catch((error)=>{
  Alerts('Error!', 'danger', error.message)
  })
  }
  
const fetchFamily =()=>{
    const fd = new FormData();
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetch_controller/tbl_family'
    axios.post(url, fd, config).then(result=>setAllFamily(result.data))
}

const getTermination =(code)=>{

    const ans = termination.filter(item=>item.code===code)
    const final = ans.length!==0?ans[0].terminationName:'Transfered '
  return final
  
  }


useEffect(()=>{
    fetchFamily();
    fetchOccupation()
},[]);

        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Family Summary">
        <li className="breadcrumb-item">
            
            <a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><Link to="/students/view_students"> Students</Link></li> 
                    <li className="breadcrumb-item"><a href="#!">Family Summary</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Family Summary</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">

                       

     <section className="col-md-4">
           <div className="form-group">
         <label> Student Class</label>
         <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  name="studentClass" value={result.studentClass} className={errors.studentClass ? 'form-control form-control-danger' : ''} /> 

<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
            </div>
     
     </section>     
     <section className="col-md-4">
           <div className="form-group">
         <label> Family Name</label>
         <Select  options={
                            allFamily&&allFamily.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.familyName} 
							 })
							 
                        } 
onChange={handleSelect}   name="family" value={result.family}  /> 

            </div>
     
     </section>  

     <section className="col-md-4">
           <div className="form-group">
         <label> Order</label>
         <Select  
         
         options={[
            { value: "and s.admissionStatus = 'Studying' ", label: 'Order by Studying' },
            { value: "and s.admissionStatus = 'Graduated' ", label: 'Order by Graduated' },
            { value: "and s.admissionStatus = 'Terminated' ", label: 'Order by Terminated' },
        ]} 
onChange={handleSelect}  name="orderBy" value={result.orderBy}  /> 

            </div>
     
     </section>

				</div>	

	<footer className="pull-right">
	
	<button type="button"  onClick={fetchResult} className="btn btn-inverse "><i className="fa fa-search"></i> Search</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	



{allResult.length!==0?

<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                   <div className="row" key={id}>

<table className="table  table-full-width">
   <tbody>
   <tr>
   <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}, E:{st.email}  </div>
<div style={{textAlign:'center'}}>W: {st.website} Telephone: {st.mobile}, {st.altMobile} </div>

 </td>
            </tr>
        </tbody>
   </table>

</div> 
                   )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>FAMILY SUMMARY </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>SN</th>
                    <th>Family Name</th>
                    <th>Current Address</th>
                    <th>Telephone</th>
                    <th>Email</th>
                    <th>Username</th>
                     <th>Student </th>
                </tr>
            </thead>
            <tbody>
                
                {allResult&&allResult.map((row, idx)=>
         
            <tr key={idx} style={{backgroundColor:`${row.admissionStatus!=='Studying'?'#fe5d70':''}`, color:`${row.admissionStatus!=='Studying'?'white':''}`}}>
                    <td>{idx+1}</td>

                    <td>{row.familyName}</td>
                    

                    <td style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.currentAddress}</td>
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.father_mobile+' '+row.mother_mobile}</td>
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.fatherEmail+' '+row.motherEmail}</td>
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.username}</td>
                    
                <td style={{maxWidth:'450px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{ 
                JSON.parse(row.student).map((data, idx)=><div key={idx}>
<b>{idx+1}. </b>
<span>{data.admissionNumber+ " - "+data.studentName}<br/>
{data.termination==='Yes'?<span className="pcoded-badge label label-danger"> {getTermination(data.termination_reason)} on {longDate(data.termination_date)} {data.termination_remark}</span>:''}
</span>

<b> Class -  {getClassName(data.classID, data.sectionID, schoolClass)}</b><br/><br/>
                </div>)
                } 
              
                </td>
                
                </tr>)}
            </tbody> 
        </table>
 </div>	 </div> 

 <div ref={structure} className="row no-print">
        <div className="col-md-12">
       

       
        <form method='POST' action={ServerUrl+'/printout/family_summary.php?token='+userToken} target='_blank' className='pull-right' >
        <input type='hidden' name='classID' value ={result.studentClass.value} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='allResult' value ={JSON.stringify(allResult, null, 2)} />
               
                <button type="submit" className="btn btn-inverse  " > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(FamilyList) 